import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OfflinePinOutlinedIcon from '@material-ui/icons/OfflinePinOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({

    root: {
        width: 100,
        height: 100,
        backgroundColor: green
    },
}));

export default function ThankYouPage(props) {


    const classes = useStyles();
    const history = useHistory();
    // const {message} = props;
    const [timeLeft, setTimeLeft] = useState(3);
 
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(
                calculateTimeLeft()
            );
        }, 1000);
        return () => clearTimeout(timer);
    }, [timeLeft]);

    function calculateTimeLeft() {
        if (timeLeft === 0) {
            history.push('/student/portal/dashboard',{examination:props.location.state.examination});
        }
        return timeLeft - 1;
    }

    return (
        <React.Fragment>
            <Grid container direction="column" alignItems="center">
                <OfflinePinOutlinedIcon color="secondary" style={{ visibility: props.location.state.status ? 'visible' : 'hidden' }}
                    className={classes.root} fontSize="large" />
                <HighlightOffIcon style={{ color: 'red', visibility: props.location.state.status ? 'hidden' : 'visible' }}
                    className={classes.root} fontSize="large" />
                <div><h2 style={{ padding: "0 5%"}} >{props.location.state.message}</h2>
                </div>
                <div style={{ padding: "0 10%" }}>Redirecting to Dashboard in {timeLeft}sec. , If Redirection does not happen <Link
                    component="button"
                    variant="body2"
                    color="secondary"
                    onClick={() => {
                        history.push('/student/portal/dashboard');
                    }}
                >
                    Click Here
                        </Link>
                </div>
            </Grid>
        </React.Fragment>
    );
}