import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFA500",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, students,percentage) {
  return { name,students,percentage };
}


const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  const {isDashboardLoading ,  dashBoardList } = props;
  const rows = [
    createData('No Of Leads (SIP + Non SIP)', isDashboardLoading ?dashBoardList.noOfLeads:0),
    createData('No Of Non SIP Kids', isDashboardLoading ?dashBoardList.nonSipStudents:0),
    createData('No Of Round 1 Attended (Non SIP)', isDashboardLoading ?dashBoardList.noOfNonSipKidsAttendedRound1:0, isDashboardLoading?dashBoardList.noOfNonSipKidsAttendedRound1Percentage+'%':0 ),
    createData('No Of Students Attended Workshop (Non SIP)', isDashboardLoading ?dashBoardList.noOfNonSipKidsAttendedWorkshop:0,isDashboardLoading?dashBoardList.noOfNonSipKidsAttendedWorkshopPercentage+'%':0),
    createData('No Of Admissions', isDashboardLoading ?dashBoardList.actualAdmission:0,isDashboardLoading?dashBoardList.actualAdmissionPercentage+'%':0),
    createData('Expected Admission Goal', isDashboardLoading ?dashBoardList.goal:0 ),  
];
  
return (
<div style={{marginTop:'15%',padding:'6px'}}>
<hr/>
<h2 style={{ textAlign:'center' }}>Statistics</h2>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" style={{border:'2px solid orange'}}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{fontSize:'17px'}}>Statistics</StyledTableCell>
            <StyledTableCell align="left" style={{fontSize:'15px'}} >No Of Students</StyledTableCell>
            <StyledTableCell align="left" style={{fontSize:'14px'}}>Percentage</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" >
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.students}</StyledTableCell>
              <StyledTableCell align="left">{row.percentage}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</div>
  );
}
