import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Snackbar, CircularProgress } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    table: {
        minWidth: 'auto',
    },
    dialogBoxHeight: {
        '& .MuiDialog-paperFullWidth': {
            height: 'auto',
        }
    }
});

export default function LCLApprovalList(props) {
    const { onClose, open, selectedLCLFromTable ,roleId} = props;
    const classes = useStyles();
    const handleClose = () => {
        onClose();
    }
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

    const handleEntry = () => {
        setIsButtonDisabled(false)
    }

    const onSubmit = async () => {
        
        setIsButtonDisabled(true);
        if(roleId ===1){
            for (let LCLObject = 0; LCLObject < selectedLCLFromTable.length; LCLObject++) {
                selectedLCLFromTable[LCLObject].approved = true;
                selectedLCLFromTable[LCLObject].lastUpdatedBy = JSON.parse(localStorage.getItem("sip-user")).userName
            }
            axios.post(BASE_URL + "/usersApproval", selectedLCLFromTable, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setIsButtonDisabled(false);
                onClose(true);
            })
                .catch(function (error) {
                    setIsButtonDisabled(false);
                    setState({ ...state, openAlert: true, message: 'Error occurred while approving the LCL!', severity: "error" });
                });


        }else{
        for (let LCLObject = 0; LCLObject < selectedLCLFromTable.length; LCLObject++) {
            selectedLCLFromTable[LCLObject].approved = true;
            selectedLCLFromTable[LCLObject].lastUpdatedBy = JSON.parse(localStorage.getItem("sip-user")).userName
        }
        axios.post(BASE_URL + "/branchesApproval", selectedLCLFromTable, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
            }
        }).then((response) => {
            setIsButtonDisabled(false);
            onClose(true);
        })
            .catch(function (error) {
                setIsButtonDisabled(false);
                setState({ ...state, openAlert: true, message: 'Error occurred while approving the LCL!', severity: "error" });
            });
    }
}
    const [maxWidth] = React.useState('md');

    return (
        {...roleId === 1 ? 
        <Dialog fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} open={open}
            onClose={handleClose} aria-labelledby="form-dialog-title" onEntering={handleEntry}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleAlertClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleAlertClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <DialogTitle id="form-dialog-title">Select users List</DialogTitle>
            <DialogContent style={{ padding: '0 5%' }} dividers='paper' >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>AH/SH/RM Name</TableCell>
                                <TableCell >loginId</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedLCLFromTable.map((LCL) => (
                                <TableRow key={LCL.userId}>
                                    <TableCell component="th" scope="LCL">
                                        {LCL.userName}
                                    </TableCell>
                                    <TableCell >{LCL.loginId}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            
            <DialogActions>

                <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                    endIcon={<CancelOutlinedIcon />}>
                    Cancel
                        </Button>
                <Button disabled={isButtonDisabled} variant="outlined" size={"small"} color="primary"
                    onClick={onSubmit} endIcon={<SaveOutlinedIcon />} >
                    Submit
                        </Button>
                {isButtonDisabled && <CircularProgress size={24} />}


            </DialogActions>

        </Dialog>
        : 
        <Dialog fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} open={open}
        onClose={handleClose} aria-labelledby="form-dialog-title" onEntering={handleEntry}>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openAlert}
            onClose={handleAlertClose}
            key={vertical + horizontal}
            autoHideDuration={6000}
        >
            <Alert onClose={handleAlertClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>

        <DialogTitle id="form-dialog-title">Select LCL's List</DialogTitle>
        <DialogContent style={{ padding: '0 5%' }} dividers='paper' >
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>LCL-Name</TableCell>
                            <TableCell >LCL-Code</TableCell>
                            <TableCell >No Of Participants</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedLCLFromTable.map((LCL) => (
                            <TableRow key={LCL.branchId}>
                                <TableCell component="th" scope="LCL">
                                    {LCL.name}
                                </TableCell>
                                <TableCell >{LCL.code}</TableCell>
                                <TableCell >{LCL.noOfParticipants}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        
        <DialogActions>

            <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                endIcon={<CancelOutlinedIcon />}>
                Cancel
                    </Button>
            <Button disabled={isButtonDisabled} variant="outlined" size={"small"} color="primary"
                onClick={onSubmit} endIcon={<SaveOutlinedIcon />} >
                Submit
                    </Button>
            {isButtonDisabled && <CircularProgress size={24} />}


        </DialogActions>

    </Dialog>}
    );

}