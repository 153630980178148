import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Fab,
  Box,
  Paper
} from "@material-ui/core";
import sipLogo from "./images/sip-academy-logo1.png";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    // backgroundColor: "#02a550",
    // padding: "5% 10% 5% 10%",
    color: "white",
    fontSize: 14
  },
  iconButton: {
    backgroundColor: "#02a550",
    width: 35,
    height: 10,
    border: "2px solid white"
  },
  buttonMargin: {
    margin: "0 10px 0 10px"
  },
  buttonHover: {
    "&:hover": {
      color: "grey"
    }
  },
  sipFooterPaper: {
    width: "100%",
    height: "35ch",
    padding: "1% 1% 1% 1%",
    backgroundColor: "#f8a41e",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  sipFooterMobilePaper: {
    width: "100%",
    height: "77vh",
    padding: "5% 10% 5% 10%",
    backgroundColor: "#f8a41e"
  }
}));

export default function SIPFooter(props) {
  const { smallScreen } = props;
  const classes = useStyles();
  const [alignment, setAlignment] = useState("flex-end");
  const copyRightInfo = "2024 SIP Academy India Pvt. Ltd. All Rights Reserved";
  const emailId = "sipinfo@sipacademyindia.com";
  const telePhoneNo = "044 2814 8595/4335";
  const navigateAbove = "Back to Top";
  const implementedBy = "Powered by EaziBiz Technologies.";

  useEffect(() => {
    document.title = "SIP Academy";
    setAlignment(smallScreen ? "flex-start" : "flex-end");
  }, [smallScreen, alignment]);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container>
          <Paper
            className={
              smallScreen
                ? classes.sipFooterMobilePaper
                : classes.sipFooterPaper
            }
          >
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={6}
            >
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                md={6}
                sm={12}
                spacing={2}
              >
                <div>
                  <img src={sipLogo} alt="Logo" />
                </div>
              </Grid>
              <Grid
                container
                justify={alignment}
                alignItems="center"
                item
                xs={12}
                md={6}
                lg={6}
                sm={12}
                spacing={2}
              >
                <Box display={{ xs: "none", md: "block" }}>
                  <Grid
                    item
                    container
                    justify={alignment}
                    xs={12}
                    md={12}
                    lg={12}
                    sm={12}
                  >
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            <Fab
                              aria-label="add"
                              className={classes.iconButton}
                              style={{
                                color: "white",
                                backgroundColor: "#E62127"
                              }}
                            >
                              <ExpandLessIcon
                                style={{ color: "white" }}
                                fontSize="large"
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                }}
                              />
                            </Fab>
                          </td>
                        </tr>
                        <tr>
                          <td valign="baseline">
                            <Typography variant="subtitle2">
                              {navigateAbove}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={6}
              style={{ marginTop: smallScreen ? "-60px" : "-30px" }}
            >
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                md={6}
                lg={6}
                sm={12}
                spacing={2}
              >
                {" "}
                <div>
                  <Typography variant="body1">Email: {emailId}</Typography>
                </div>
              </Grid>
              <Grid
                item
                container
                justify={alignment}
                alignItems="center"
                item
                xs={12}
                md={6}
                lg={6}
                sm={12}
                spacing={2}
              >
                <Grid
                  item
                  container
                  justify={alignment}
                  alignItems="center"
                  xs={12}
                  md={12}
                  lg={12}
                  sm={12}
                  spacing={1}
                >
                  <Typography variant="subtitle2">Follow Us</Typography>
                </Grid>
                <Grid
                  item
                  container
                  justify={alignment}
                  alignItems="center"
                  xs={12}
                  md={12}
                  lg={12}
                  sm={12}
                  spacing={1}
                >
                  <div style={{ marginLeft: "-10px" }}>
                    <FacebookIcon
                      fontSize="large"
                      className={classes.buttonHover}
                      style={{ margin: "0 10px 0 10px" }}
                      onClick={() => {
                        window.open(
                          " https://www.facebook.com/SIPabacusIndia/",
                          "_blank"
                        );
                      }}
                    />
                    <InstagramIcon
                      fontSize="large"
                      className={classes.buttonHover}
                      style={{ margin: "0 10px 0 10px" }}
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/sipabacusindia/",
                          "_blank"
                        );
                      }}
                    />
                    <LinkedInIcon
                      fontSize="large"
                      className={classes.buttonHover}
                      style={{ margin: "0 10px 0 10px" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/company/sip-academy-india-pvt--ltd--chennai",
                          "_blank"
                        );
                      }}
                    />
                    <YouTubeIcon
                      fontSize="large"
                      className={classes.buttonHover}
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        window.open(
                          "https://www.youtube.com/channel/UCGqEkeIiM3yr72YUNjuCx9g",
                          "_blank"
                        );
                      }}
                    />
                    <TwitterIcon
                      fontSize="large"
                      className={classes.buttonHover}
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        window.open(
                          "https://twitter.com/sipacademyind/",
                          "_blank"
                        );
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justify="center"
              alignItems="center"
              spacing={6}
            >
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                md={6}
                lg={6}
                sm={12}
                spacing={2}
              >
                {" "}
                <div>
                  <Typography variant="subtitle2">
                    <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />{" "}
                    {copyRightInfo}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                container
                justify={alignment}
                alignItems="center"
                item
                xs={12}
                md={6}
                lg={6}
                sm={12}
                spacing={2}
              >
                <div>
                  <Typography
                    variant="subtitle2"
                    style={{ paddingRight: "5px" }}
                  >
                    {implementedBy}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </React.Fragment>
  );
}
