import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PropTypes from 'prop-types';
import _ from "lodash/fp";
import axios from 'axios';
import ResetOutlinedIcon from '@material-ui/icons/LoopOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paper_padding: {
        padding: theme.spacing(2)
    },
    error: {
        color: "red",
    },
}));

function PasswordRest(props) {
    const classes = useStyles();

    const { onClose, open, studentAccountId, studentName, transactionObj } = props;
    const passwordResetPageConstsAndLabelsAndMessagesAndStatus = {
        pageConstants: {
            dialogTitle: `Reset Password`,
        },
        labels: {
            saveButtonLabel: `Submit`,
            cancelButtonLabel: `Cancel`,
            childNameLabel: 'Child Name',
            passwordLabel: `Password`,
            loginIdLabel: `Login Id`,
            resetButtonLabel: `Reset`
        },
        messages: {
            studentNameNeedToBeAppearedOnTitle: `for ${studentName}`,
            helperTextForDataEnter: 'Please Enter ',
            note: `To reset the password to default universal password shown above, click on Submit 
            or enter a new password and click on Submit`
        },
        name: {
            password: 'password',
            defaultUniversalPasswordKey: `DEFAULT UNIVERSAL PASSWORD`
        },
        length: {
            passwordMaxLength: 8,
            passwordMinLength: 7
        }
    };
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = React.useState(false);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const { register, handleSubmit, errors, reset, control } = useForm({
        mode: "all",
    });
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const orgId = JSON.parse(localStorage.getItem("sip-user")).organization.organizationId;
    const [defaultUniversalPassword, setDefaultUniversalPassword] = useState('');
    const [resetPasswordToDefaultUniversalPassword, setResetPasswordToDefaultUniversalPassword] = useState(false); 
    useEffect(() => {
        axios.get(BASE_URL + `/SIPCustomApi/defaultUniversalPassword`, 
            {
                params: {
                    orgId: orgId,
                    key: "DEFAULT UNIVERSAL PASSWORD"
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setDefaultUniversalPassword(response.data);
            })
            .catch(function (error) {
                setIsUpdateButtonDisabled(false);
                setState({ ...state, openAlert: true, message: 'Failed to get default password from server!', severity: "error" });
            });
    }, [resetPasswordToDefaultUniversalPassword])
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;

    const handleClose = () => {
        setResetPasswordToDefaultUniversalPassword(!resetPasswordToDefaultUniversalPassword);
        onClose(false);
    }

    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

    const onSubmit = data => {
        // data["active"] = transactionObj.active;
        // data["createdBy"] = transactionObj.createdBy;
        // data["createdDt"] = transactionObj.createdDt;
        // data["lastLogin"] = transactionObj.lastLogin !== null ? transactionObj.lastLogin : "";
         data["lastUpdatedBy"] = JSON.parse(localStorage.getItem("sip-user")).userName;
        // data["lastUpdatedDt"] = transactionObj.lastUpdatedDt;
        data["password"] = data.password;
        // data["loginId"] = transactionObj.loginId;
        data["studentAccountId"] = transactionObj.studentAccountId;
        // data["student"] = { studentId: transactionObj.student.studentId };
        setIsUpdateButtonDisabled(true);
        axios.post(BASE_URL + "/SIPCustomApi/resetOrUpdatePassword", data,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setIsUpdateButtonDisabled(false);
                onClose(true);
            })
            .catch(function (error) {
                setIsUpdateButtonDisabled(false);
                setState({ ...state, openAlert: true, message: 'Failed to update the password!', severity: "error" });
            });
    }

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    return (
        <Dialog fullWidth="true" onClose={handleClose} aria-labelledby="activate-test"
            open={open} disableBackdropClick={true}>
            <DialogTitle className={classes.dialogTitleContainer}>
                {passwordResetPageConstsAndLabelsAndMessagesAndStatus.pageConstants.dialogTitle}
            </DialogTitle>
            <DialogContent dividers className={classes.formControl} style={{ marginTop: "-2%" }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openAlert}
                    onClose={handleAlertClose}
                    key={vertical + horizontal}
                    autoHideDuration={6000}
                >
                    <Alert onClose={handleAlertClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography variant="h6">
                                                {passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.childNameLabel}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="h6">
                                                : {studentName}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="h6">
                                                {passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.loginIdLabel}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="h6">
                                                : {transactionObj.loginId}
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label={passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.passwordLabel + " *"}
                                    variant="outlined"
                                    name={passwordResetPageConstsAndLabelsAndMessagesAndStatus.name.password}
                                    size="small"
                                    autoFocus
                                    inputRef={register({
                                        required: true,
                                        maxLength: `${passwordResetPageConstsAndLabelsAndMessagesAndStatus.length.passwordMaxLength}`,
                                        pattern: /^[A-Z0-9]+$/,
                                    })}
                                    fullWidth
                                    defaultValue={defaultUniversalPassword}
                                    autoComplete="false"
                                ></TextField>
                                <div className={classes.error}>
                                    {_.get(`${passwordResetPageConstsAndLabelsAndMessagesAndStatus.name.password}.type`, errors) === "required" && (
                                        <FormHelperText className={classes.error}>{passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.passwordLabel} is required</FormHelperText>
                                    )}
                                    {_.get(`${passwordResetPageConstsAndLabelsAndMessagesAndStatus.name.password}.type`, errors) === "maxLength" && (
                                        <FormHelperText className={classes.error}>
                                            {passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.passwordLabel} cannot exceed 8 characters
                                        </FormHelperText>
                                    )}
                                    {_.get(`${passwordResetPageConstsAndLabelsAndMessagesAndStatus.name.password}.type`, errors) === "minLength" && (
                                        <FormHelperText className={classes.error}>
                                            {passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.passwordLabel} cannot be less than 8 characters
                                        </FormHelperText>
                                    )}
                                    {_.get(`${passwordResetPageConstsAndLabelsAndMessagesAndStatus.name.password}.type`, errors) === "pattern" && (
                                        <FormHelperText className={classes.error}>
                                            {passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.passwordLabel} should contain only capital letters and number
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" style={{ textAlign: "justify" }}>
                                (Note: {passwordResetPageConstsAndLabelsAndMessagesAndStatus.messages.note})
                            </Typography>
                        </Grid>

                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
                <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}
                    endIcon={<CancelOutlinedIcon />}>{passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.cancelButtonLabel}</Button>
                    <Button variant="outlined" size={"small"}
                    endIcon={<ResetOutlinedIcon />} onClick={() => {
                        reset();
                    }}>{passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.resetButtonLabel}</Button>
                <Button variant="outlined" size={"small"} color="primary" disabled={isSubmitButtonDisabled || isUpdateButtonDisabled}
                    onClick={handleSubmit(onSubmit)} endIcon={<SaveOutlinedIcon />}>{passwordResetPageConstsAndLabelsAndMessagesAndStatus.labels.saveButtonLabel}</Button>
                    {isUpdateButtonDisabled && <CircularProgress size={24} />}
            </DialogActions>
        </Dialog >
    );
}

PasswordRest.prototype = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    studentAccountId: PropTypes.number.isRequired
}

export default PasswordRest;