import React from 'react';
import {
    Button,
    Grid,
    Typography,
    CircularProgress,
    Tooltip
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import AddIcon from '@material-ui/icons/AddRounded';
import axios from 'axios';
import EditOutLinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutLinedIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import CreateOrEditClass from './CreateOrEditDialog';
import ConfirmationDialog from '../../../Common/Confirmation';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
class ClassList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openConfirmationDialog: false,
            pageNo: 0,
            recordsPerPage: 10,
            sortKey: "lastUpdatedDt",
            sortDir: "desc",
            count: 0,
            transactionObj: {},
            message: '',
            data: [["Loading Data..."]],
            columns: [
                {
                    name: "name",
                    label: "Name",
                    options: {}
                },
                {
                    name: "organization.name",
                    label: "Organization",
                    options: {
                        filter: false,
                        display: false
                    }
                },
                {
                    name: "branch.name",
                    label: "Branch",
                    options: {
                        filter: true,
                        display: false
                    }
                },
                {
                    name: "lastUpdatedDt",
                    label: "Last Updated Date",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "lastUpdatedBy",
                    label: "Last Updated By",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "createdDt",
                    label: "Created Date",
                    options: {
                        filter: false,
                        display: false
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        display: false
                    }
                },
                {
                    name: "action",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: false,
                        empty: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Grid container justify="flex-start">
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton color="primary" onClick={() => {
                                            this.setState({
                                                open: true,
                                                classObj: {
                                                    mode: "edit",
                                                    class: this.state.data[dataIndex]
                                                }
                                            })
                                        }}>
                                            <EditOutLinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete" aria-label="delete">
                                        <IconButton color="secondary" onClick={() => {
                                            let itemName = this.state.data[dataIndex].name;
                                            this.setState({
                                                openConfirmationDialog: true,
                                                transactionObj: this.state.data[dataIndex],
                                                message: `Are You sure?, You want to Delete "${itemName}" ?`
                                            })
                                        }}>
                                            <DeleteOutLinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                            );
                        }
                    }
                }
            ],
            isLoading: false,
            body: {
                name: "",
                branch: {
                    name: ""
                }
            },
            classObj: {
                mode: "create",
                class: {}
            },
            classIdSelectedToDelete: 0

        };

        this.getData = this.getData.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.sort = this.sort.bind(this);
        this.createClass = this.createClass.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
    }

    componentDidMount() {
        this.getData(this.state.pageNo, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage);
    }

    handleFilterSubmit =(applyFilters)=> {
        let filterList = applyFilters();
        let selectedNameFilterValue = "";
        let selectedBranchNameFilterValue = "";
        filterList[0].forEach(element => {
            selectedNameFilterValue = element;
        });
        filterList[1].forEach(element => {
            selectedBranchNameFilterValue = element;
        });

        this.setState({
            body: {
                name: selectedNameFilterValue,
                branch: {
                    name: (selectedBranchNameFilterValue !== "" ? selectedBranchNameFilterValue : "")
                }
            }
        }, () => this.getData(this.state.page, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage));

    }

    async getData(pageNo, sortKey, sortDir, recordsPerPage) {
        this.setState({ isLoading: true });
        await axios.get(BASE_URL + "/classes",
            {
                params: {
                    body: JSON.stringify(this.state.body),
                    pageNo: pageNo !== undefined ? pageNo : "",
                    sortKey: sortKey !== undefined ? sortKey : "",
                    sortDir: sortDir !== undefined ? sortDir : "",
                    recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(`admin:admin`)
                }
            }).then((response) => {
                this.setState({ data: response.data.content, count: response.data.totalElements });
            })
            .catch(function (error) {
            });
        this.setState({ isLoading: false });

    }

    changePage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
        });
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
        this.getData(page, "", "", rowsPerPage);
    }

    sort = (page, sortOrder, rowsPerPage) => {
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
        this.setState({
            pageNo: page,
            sortKey: sortOrder.name,
            sortDir: sortOrder.direction,
            body: {
                name: (searchText !== null ? searchText : ""),
                branch: {
                    name: ""
                }
            }
        }, () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage));
    }

    createClass() {
        this.setState({
            open: true,
            classObj: {
                mode: "create",
                class: {}
            }
        });
    }

    closeDialog() {
        this.setState({ open: false });
        this.componentDidMount();
    }

    closeConfirmationDialog(confirmed) {
        this.setState({ openConfirmationDialog: false });
        if (confirmed) {
            axios.delete(BASE_URL + "/classes/" + this.state.transactionObj.classId,
                {
                    headers: {
                        authorization: 'Basic ' + window.btoa(`admin:admin`)
                    }
                }).then((Response) => {
                    this.componentDidMount();
                });
        }

    }

    render() {

        const { count,  rowsPerPage, sortOrder } = this.state;

        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: true,
            count: count,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [1, 2, 5, 10, 20],
            sortOrder: sortOrder,
            confirmFilters: true,
            selectableRows: "none",
            rowHover: true,
            caseSensitive: true,
            enableNestedDataAccess: '.',
            customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                return (
                    <div style={{ marginTop: '40px' }}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Button color="primary" variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Filter</Button>
                            </Grid>
                        </Grid>
                    </div>
                );
            },

            // callback that gets executed when filters are confirmed
            onFilterConfirm: (filterList) => {
            },

            onFilterDialogOpen: () => {
            },
            onFilterDialogClose: () => {
            },
            searchPlaceholder: 'Search by Name...',
            onTableChange: (action, tableState) => {

                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'sort':
                        this.sort(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changeRecordPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'search':
                        this.handleSearch(tableState.searchText, tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    default:
                        console.log('action not handled.');
                }
            }
        };

        return (
            <div>
                <Grid container spacing={1}>
                    <Grid container item xs={12} justify="flex-end">
                        <Button variant="contained" color="primary" size="small"
                            onClick={this.createClass}
                            endIcon={<AddIcon />}>
                            Create
                        </Button>
                        <CreateOrEditClass onClose={this.closeDialog} classObject={this.state.classObj} open={this.state.open} />
                        <ConfirmationDialog onClose={this.closeConfirmationDialog} message={this.state.message} open={this.state.openConfirmationDialog}></ConfirmationDialog>
                    </Grid>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={<Typography variant="h6">
                                Class
                        {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                            </Typography>
                            }
                            data={this.state.data}
                            columns={this.state.columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ClassList;
