import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
  AppBar
} from "@material-ui/core";
import homePage from "./images/BG_Page_01.svg";
import SIPFooter from "./SIPFooter";
import Registration from "../Registration";

import mobileBG from "./images/Mobile_BG_main.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    wrap: "nowrap",
    height:"100%",
    width:"100%",
    overflowX:"hidden",
    flexWrap:"wrap"
    
  },
  topGrid: {
    color: "white",
  },
 
  paper: {
    textAlign: "center",
    width: "120%",
    maxHeight: "auto",
    backgroundImage: `url(${homePage})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
  },
 
  content: {
    fontWeight: "bold",
    color: "white",
    fontSize: theme.spacing(3),
    justify: "right",
    textAlign: "center",
    backgroundImage: `url(${homePage})`,

  },
 
  mobilePaper: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${mobileBG})`,
    overflow:"hidden",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none",
  },

  horizontalLine1: {
    width: "70%",
    borderBottom: "2px solid white",
    textAlign: "center",
    marginLeft: "16%",
    height:theme.spacing(3)
  },
  horizontalLine1SmallScreen: {
    width: "100%",
    borderBottom: "2px solid white",
    textAlign: "center",
    height:theme.spacing(2),
  },
  horizontalLine2: {
    backgroundColor: "#F05A28",
    padding: "0 5px",
    fontWeight: "bold",
    color: "white",
    fontSize:theme.spacing(4)
  },
  workShop: {
    backgroundColor: "#F6921E",
    borderRadius: "25px",
    padding: "5px 6px",
    textAlign: "center",
    color: "white",
    width: "10%",
  },
  text: {
    color: "white",
  },
  line:{
    height:"1px",
    borderWidth:"0px",
    color:"white",
    backgroundColor:"white",
    width:"70%",
    alignItems:"center",
    textAlign:"center"
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    display: "inline-block",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    display:"inline-block",
    fontSize:theme.spacing(2),
    padding:theme.spacing(1)
  },
  imageStyle:{
    position:"relative",
    left:"0px",
    bottom:"0px"
  }
}));

const StudentRegistrationFormNewPage = () => {
  const classes = useStyles();
  let theme = useTheme();
  const gmail = "sipinfo@sipacademyindia.com";
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [width, setWidth]   = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);





  

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row" fixed
        // spacing={2}
        justify="center"
        alignItems="center"
      >
        <Paper className={smallScreen ? classes.mobilePaper : classes.paper} >
          {/* <Hidden smDown> */}
            <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
             
            </AppBar>
          {/* </Hidden> */}
        
        </Paper>
      </Grid>
 
      <div id="registration">
        <Registration></Registration>
      </div>
      <SIPFooter smallScreen={smallScreen} /> 
    </div>
  );
};
export default StudentRegistrationFormNewPage;
