import React, { useState } from "react";

const TimerComponent = (props) => {
  const { setStopTimer, allotedTime, setSyncTimer, examinationType, timeType,
    syncTimer, agcTimeLoad, totalTimeSync, setTotalTimeSync } = props;

  let [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  let difference = +allotedTime - +new Date();

  const [timerLoop, setTimerLoop] = useState(true);
  React.useLayoutEffect(() => {
    // var syncValue =0


    // if (timeType === "totalTime") {
    //   setTotalTimeSync(true)
    // }

    if ((examinationType === 'prodigy' && timeType === "moduleTime" ) || (examinationType === 'agc' && agcTimeLoad)) {
      setSyncTimer(true);
      // setTimerLoop(false);

    }
    if (syncTimer && timeType === "moduleTime") {

      const timer = setTimeout(() => {
        setTimeLeft(calculateDiff(timeLeft, difference));
      }, 1000);
      return () => clearTimeout(timer);
    }

    if (timeType !== "moduleTime" && examinationType === 'prodigy') {

      if (timerLoop) {
        const timer = setTimeout(() => {
          setTimeLeft(calculateDiff(timeLeft, difference));
        }, 1000);
        return () => clearTimeout(timeLeft);

      }

      //  return '11 Minutes';
    }

    if (syncTimer && timeType === "totalTime" && examinationType === 'agc') {

      const timer = setTimeout(() => {
        setTimeLeft(calculateDiff(timeLeft, difference));
      }, 1000);
      return () => clearTimeout(timer);
    }

  }, [timeLeft, syncTimer, agcTimeLoad, timeType, timeType, setTotalTimeSync, totalTimeSync]);

  function calculateDiff(time, difference) {
    
    let differences = difference + (difference) % 60;
    
    if (allotedTime !== undefined && allotedTime !== null) {

      if (differences > 0) {
        time = {
          hours: Math.floor((differences / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((differences / 1000 / 60) % 60),
          seconds: Math.round(((differences / 1000) % 60))
          //  === 0 ? Math.ceil(((difference / 1000) % 60)) + .1 :Math.ceil(((difference / 1000) % 60)),
        };
      } else {
        time = {
          hours: 0,
          minutes: 0,
          seconds: 0
        };
      }

      if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        setStopTimer(true);
      }
      setTimerLoop(false);
      return time;
    }
  }
  return (
    <React.Fragment>
      <span>
        {timeLeft !== undefined ? timeLeft.hours : 0} : {timeLeft !== undefined ? timeLeft.minutes : 0}: {timeLeft !== undefined ? timeLeft.seconds : 0}
      </span>
    </React.Fragment>

  );

};
export default TimerComponent;
