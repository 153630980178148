import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimerComponent from "./TimerComponent";
import TimerIcon from '@material-ui/icons/Timer';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ProgressBar from "./ProgressBar";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "10px",
    padding: theme.spacing(1),
    textAlign: "center",
    color: "white",
    backgroundColor: "#f57c00",
    borderCollapse: "collapse",
    fontSize: 15,
    fontWeight: "bold",
    borderRadius: 15,
    justifyContent: "screenRight",
    width: "100%"
  },
  commonBackgroundColor: {
    backgroundColor: theme.palette.primary[theme.palette.type === 'dark' ? 200 : 700],
    textAlign: "center"
  },

}));

const DurationComponent = (props) => {

  const { examObject, answeredQuestion, setStopTimer, totalNoOfQuestions, allotedTime, examinationType, setSyncTimer, syncTimer
    , agcTimeLoad, totalTimeSync, setTotalTimeSync } = props;
  const classes = useStyles();
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const progressBarColor = "#2962ff";
  let totalNumberOfQue = totalNoOfQuestions;

  return (
    <div className={classes.root}>
      <Grid container fullWidth>
        <Paper elevation={8} className={classes.paper}>
          {examinationType === 'agc' ?
            <Grid item container spacing={1}>

              <Grid item xs={4} sm={4} md={4}>

                <Paper elevation={8} className={classes.commonBackgroundColor} spacing={1}>
                  <Grid container>
                    <Grid item container xs={12} md={12} sm={12} justify="center" alignContent="center">
                      <TimerIcon fontSize="large" />
                    </Grid>
                    <Grid item container xs={12} md={12} sm={12} direction="column" justify="center" alignContent="center">
                      <div> {examinationType === 'agc' ? 'Time Left' : 'Total Time Alloted'} : <br /><TimerComponent allotedTime={allotedTime} setStopTimer={setStopTimer} setSyncTimer={setSyncTimer}
                        examinationType={examinationType} timeType={"totalTime"} syncTimer={syncTimer} agcTimeLoad={agcTimeLoad} totalTimeSync={totalTimeSync}
                        setTotalTimeSync={setTotalTimeSync}></TimerComponent></div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container justify="flex-end">
                    <Grid item container xs={12} md={12} sm={12} justify="center" alignContent="center">
                      <AssessmentOutlinedIcon fontSize="large" />
                    </Grid>
                    <Grid item container xs={12} md={12} sm={12} direction="column" justify="center" alignContent="center">
                      <div style={{ fontSize: smallScreen ? "14px" : "16px", marginTop: smallScreen ? "1%" : "4%" }}> {examObject.exam.name} </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container>
                    <Grid item container xs={12} md={12} sm={12} justify="center" alignContent="center">
                      <PlaylistAddCheckIcon fontSize="large" />
                    </Grid>
                    <Grid item container xs={12} md={12} sm={12} direction="column" justify="center" alignContent="center">
                      <div>Answered : <br /> {answeredQuestion}/{totalNoOfQuestions}</div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid> :
            <Grid item container spacing={1}>
              <Grid item xs={6} sm={6} md={6}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container justify="flex-end">
                    <Grid item container xs={12} md={12} sm={12} justify="center" alignContent="center">
                      <AssessmentOutlinedIcon fontSize="large" />
                    </Grid>
                    <Grid item container xs={12} md={12} sm={12} direction="column" justify="center" alignContent="center">
                      <div style={{ fontSize: smallScreen ? "14px" : "16px", marginTop: smallScreen ? "1%" : "2%" }}> {examObject.exam.name} </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container>
                    <Grid item container xs={12} md={12} sm={12} justify="center" alignContent="center">
                      <PlaylistAddCheckIcon fontSize="large" />
                    </Grid>
                    <Grid item container xs={12} md={12} sm={12} direction="column" justify="center" alignContent="center">
                      <div>Answered : <br /> 
                       {answeredQuestion}/{totalNoOfQuestions}</div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>}
          <ProgressBar completed={(answeredQuestion / totalNumberOfQue) * 100} bgcolor={progressBarColor} />
        </Paper>
      </Grid>
    </div>
  );
};
export default DurationComponent;