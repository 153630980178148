import React from 'react';
import { CssBaseline } from '@material-ui/core';
import AppHeader from '../AppHeader/AppHeader';
import AppFooter from '../AppFooter';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import AppMenu from '../AppMenu';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseDesktop: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
    },
    drawerCloseMobile: {
        width: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),

    },
    footerContainer: {
        position: 'relative',
    },
}));


function Portal({ match, children }) {

    const classes = useStyles();
    const domRef = React.useRef();

    const [open, setOpen] = React.useState(true);

    const handleDrawerToggle = () => {
        setOpen(!open)
    };

    return (
        <div className={classes.root} ref={domRef.current}>
            <CssBaseline />
            <AppHeader appTitle={"SIP Exam Portal"} handleDrawer={handleDrawerToggle} drawerState={open} />
            {/* Desktop sidebar */}
            <Drawer
                className={classes.drawer}
                variant="permanent"
                open={open}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerCloseDesktop]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerCloseDesktop]: !open,
                    }),
                }}>
                <Toolbar />
                <div className={classes.drawerContainer} >
                    <AppMenu />
                </div>
            </Drawer>
            <main className={classes.content}>
                <Toolbar />
                {children}
                <div className={classes.footerContainer}>
                    <AppFooter />
                </div>
            </main>
        </div>
    );
}


export default Portal;