import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, ListItemText, Divider, Collapse, MenuList, makeStyles } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import IconDashboard from '@material-ui/icons/Dashboard';
import IconPeople from '@material-ui/icons/People';
import IconBarChart from '@material-ui/icons/BarChart';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    menuItemIcon: {
        color: theme.palette.secondary.light,
        minWidth:'35px',

    },
    nested: {
      paddingLeft: theme.spacing(4),

    },
    
}));

const AppMenuItem = ({name,Icon, link, items, isChild,parentMenu}) => {

    const classes = useStyles();
    const location = useLocation();
    const isExpandable = items && items.length > 0;
    const [open, setOpen] = React.useState(false);
    const [examination,setExamination] =React.useState('');

    const adminLogin='/admin/portal';

    const handleClick = () => {
        setOpen(!open)
    }

    const activeRoute = (routeName) => {
        return location.pathname === routeName ? true : false;
    }
     
    if(Icon === 'PeopleAltIcon'){
        Icon=PeopleAltIcon;
    }else if(Icon === 'IconDashboard'){
        Icon=IconDashboard;
    }else if(Icon==='IconBarChart'){
        Icon=IconBarChart;
    }else if(Icon === 'CastForEducationIcon'){
        Icon = CastForEducationIcon;
    }else if(Icon === 'ScheduleIcon'){
        Icon= ScheduleIcon;
    }else if(Icon === 'IconPeople'){
        Icon =IconPeople;
   }


    React.useEffect(() => {
      //To open the dashboard by default
      if (name === "AGC") {
        setOpen(true);
      } else if (name === "Prodigy") {
      }
    }, [name]);

  
    const MenuItemRoot = (

        <MenuItem button 
                    onClick={handleClick} 
                    component={isExpandable ? undefined : Link} 
                    to={isExpandable ? undefined : adminLogin+"/"+parentMenu+link}
                    selected={activeRoute(adminLogin+"/"+parentMenu+link)}
                    className={isChild ? classes.nested : undefined}
                    >
            {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    <Icon />
                </ListItemIcon>
            ) }
            <ListItemText primary={name} />
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
        </MenuItem>    

    );

    const MenuItemChildren = isExpandable ? (

        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <MenuList component="div" disablePadding>
                {items.map((item, index) => (
                    <AppMenuItem {...item} key={index} isChild={true}/>
                ))}
            </MenuList>
        </Collapse>

    ) : null;

    return (
        <Fragment>
            {MenuItemRoot}
            {MenuItemChildren}
        </Fragment>
    );
}

AppMenuItem.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    items: PropTypes.array,   
    link: PropTypes.string, 
    parentMenu:PropTypes.string
};

export default AppMenuItem;