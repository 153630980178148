import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Grid, makeStyles, CssBaseline, FormHelperText, useTheme, useMediaQuery } from '@material-ui/core';
import { useEffect } from 'react';
import _ from "lodash/fp";
import axios from 'axios';
import { red } from '@material-ui/core/colors';
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {

        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "inline-block",

    },
    gridItem: {
        paddingBottom: theme.spacing(.5),
        width: '261px',
        color: 'darkslategrey',
        

    },
    error: {
        color: "red",
        fontSize: "11px"
    },
    formControl: {

        '& .MuiFormLabel-root': {
            color: 'darkslategrey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'darkslategrey',
            },
        },
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            color: 'darkslategrey',
        }
    },
    mobileNumber: {

        '& .MuiFormLabel-root': {
            color: 'darkslategrey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'darkslategrey',
            },
        },
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            color: 'darkslategrey',
        },
        '& input::-webkit-inner-spin-button': {
            display: 'none',
             margin: 0
          }
    },
    invalid: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
        },
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            display: 'none'

        },
        '& input::-webkit-inner-spin-button': {
            display: 'none',
             margin: 0
          }

    },
    asterisk: {
        color: 'red'
    }

}));

function ParentInfo({ displayText, requestText, setStudentRegistrationValidation, studentRegistration, setStudentRegistration, setLoginId,
    state, setState }) {

    const classes = useStyles();
    let theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { register, watch, errors, getValues } = useForm({
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues: {
            studentId: studentRegistration.studentId,
            parentName: studentRegistration.parentName,
            mobileNumber1: studentRegistration.mobileNumber1,
            mobileNumber2: studentRegistration.mobileNumber2,
            whatsAppNumber: studentRegistration.whatsAppNumber,
            emailId: studentRegistration.emailId,
            branch: {
                branchId: 1,
            }
        },
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const studentId = 'studentId';
    const parentName = 'parentName';
    const mobileNumber1 = 'mobileNumber1';
    const mobileNumber2 = 'mobileNumber2';
    const whatsAppNumber = 'whatsAppNumber';
    const emailId = 'emailId';
    const mobileNumberHelperText='Please enter your mobile number  <br> <strong> Please double check and ensure Mobile number is right as we will send Exam Login ID & Password to this number </strong>'

    const [alert, setAlert] = React.useState(false)

    const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)

    const autoSave = () => {
        if (getValues(mobileNumber1)) {
            studentRegistration.mobileNumber1 = getValues(mobileNumber1);
        }

        if (getValues(emailId)) {
            studentRegistration.emailId = getValues(emailId).replace(/\s+/g, '');
        }

        if ((studentRegistration.mobileNumber1.length === 10 && watchAutoSave.parentName.length > 0)) {
            const BASE_URL = `${process.env.REACT_APP_API_URL}`;

            //GTM virtual page for analytics
            TagManager.dataLayer({
                dataLayer: {
                    event: 'PageView',
                    pagePath: '/registration/parentInfo',
                    pageTitle: 'ParentInfo',
                }
            });

            studentRegistration.studentId = `${studentRegistration.studentId !== 0 ? studentRegistration.studentId : 0}`
            if(localStorage.getItem('studentId') && localStorage.getItem('studentId')>0){

                studentRegistration.studentId = localStorage.getItem('studentId');
            }
            axios.post(BASE_URL + "/students", studentRegistration)
                .then(function (response) {
                    if (response.data.studentId === 0) {
                        setAlert(true);
                        setState({ ...state, open: true, message: `${response.data.childName} is already registered with this mobile number or email id.`, severity: "error" });
                    }else if (response.data.studentId===-1){
                        setAlert(true);
                        setState({ ...state, open: true, message: `Two or more students are already registered with this mobile no ${getValues('mobileNumber1')}.`, severity: "error" });
                    
                    }else if (response.data.studentId > 0) {
                        setStudentRegistration({ ...studentRegistration, [studentId]: response.data.studentId });
                        setState({ open: false });
                        setAlert(false);
                        localStorage.setItem('studentId',response.data.studentId);

                    }
                })
                .catch(function (error) {
                });
        }

    }

    const fieldsLabels = {
        parentName: <span>Name of Parent <span style={{ color: "red" }} >*</span></span>,
        mobileNumber1: <span>Mobile No. <span style={{ color: "red" }} >*</span></span>,
        mobileNumber2: <span>Alternate Mobile No.</span>,
        whatsAppNumber: <span>WhatsApp No. <span style={{ color: "red" }} >*</span></span>,
        emailId: <span>Email <span style={{ color: "red" }} >*</span></span>,
    }

    const watchAutoSave = watch();

    useEffect(() => {
        if(state.open){
            setAlert(true);
        }else{
            setAlert(false);
        }
        if ((!errors.parentName && getValues(parentName)) && (!errors.mobileNumber1 && getValues(mobileNumber1))
            && (!errors.whatsAppNumber && studentRegistration.whatsAppNumber) && (!errors.emailId && getValues(emailId)) && !alert) {
            setStudentRegistrationValidation(true);
        } else {
            setStudentRegistrationValidation(false)
        }

    }, [errors.parentName, errors.mobileNumber1, errors.whatsAppNumber,
    errors.emailId, getValues, studentRegistration, watchAutoSave.parentName.length,
    watchAutoSave.mobileNumber1.length, setStudentRegistrationValidation, setLoginId,
        setStudentRegistration,setAlert,alert]);
    
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" alignItems="stretch" height="100%">

                <Grid item xs={12} justify="center" alignItems="stretch" height="100%">
                    <span style={{ color: 'darkslategrey', fontSize: '90%', paddingLeft: smallScreen ? '18%' : '0%', paddingRight: smallScreen ? '10%' : '0%' }}>  {displayText}</span>
                    <div style={{
                        paddingLeft: smallScreen ? '5%' : '0%', paddingRight: smallScreen ? '5%' : '0%',
                        fontSize: smallScreen ? '90%' : ''
                    }}>
                        <span style={{ color: 'darkslategrey' }}>{requestText}</span>
                    </div>
                </Grid>

                <form  >
                    <Grid item xs={12} className={classes.gridItem} >


                        <TextField
                        disabled={false}
                        //variant="filled"
                            className={(errors.parentName && classes.invalid) || classes.formControl}
                            size="small"
                            margin="dense"
                            autoComplete='off'
                            variant="outlined"
                            id="standard-helperText"
                            label={fieldsLabels.parentName}
                            name="parentName"
                            onChange={(() => {
                                setStudentRegistration({
                                    ...studentRegistration, [parentName]: getValues(parentName).toLowerCase().replace(/[^A-Za-z0-9.,;'"&()#-]/g, ' ').split(' ')
                                    .reduce((result, word) => result +' '+ capitalize(word.toLowerCase()))
                                    .replace(/^./, function(str){ return str.toUpperCase();  })
            
                            })} 
                            )}
                            defaultValue=""
                            helperText={'Please enter your name'}
                            inputRef={register({
                                required: true,
                                maxLength: 100,
                                pattern:/^[A-Z0-9.,;'"&()#-\s]{0,}$/i
                            })}
                        />
                        {_.get("parentName.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Name is required</FormHelperText>
                        )}
                        {_.get("parentName.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>Name cannot exceed 100 characters</FormHelperText>
                        )}
                        {_.get("parentName.type", errors) === "pattern" && (
                            <FormHelperText className={classes.error}>Please enter valid name</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem} style={{whiteSpace: "pre-wrap"}}>
                        <TextField
                         disabled={false}
                        // variant="filled"
                            className={(errors.mobileNumber1 && classes.invalid) || classes.mobileNumber}
                            size="small"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            id="mobileNumber1"
                            autoComplete='off'
                            label={fieldsLabels.mobileNumber1}
                            name="mobileNumber1"
                            onChange={autoSave}
                            defaultValue=""
                            helperText={`Please enter your mobile number,\nPlease double check and ensure Mobile number is right as we will send Exam Login ID & Password to this number`}
                            inputRef={register({
                                required: true,
                                maxLength: 10,
                                minLength: 10,
                                pattern: /^[0-9\b]+$/
                            })}
                        />

                        {_.get("mobileNumber1.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Mobile No is required</FormHelperText>
                        )}
                        {_.get("mobileNumber1.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>Mobile No cannot exceed 10 numbers</FormHelperText>
                        )}
                        {_.get("mobileNumber1.type", errors) === "minLength" && (
                            <FormHelperText className={classes.error}>Mobile No cannot be less than 10 numbers</FormHelperText>
                        )}
                        {_.get("mobileNumber1.type", errors) === "pattern" && (
                            <FormHelperText className={classes.error}>Please enter valid mobile number</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <TextField
                         disabled={false}
                        // variant="filled"
                            className={classes.mobileNumber}
                            size="small"
                            type="number"
                            margin="dense"
                            variant="outlined"
                            id="mobileNumber2"
                            autoComplete='off'
                            label={fieldsLabels.mobileNumber2}
                            name="mobileNumber2"
                            onChange={(() => {
                                setStudentRegistration({ ...studentRegistration, [mobileNumber2]: getValues(mobileNumber2) });
                            })}
                            defaultValue=""
                            inputRef={register({
                                required: false,
                                minLength: 10,
                                maxLength: 10,
                            })}
                            helperText={`Please enter your alternate mobile number`}
                        />
                        {_.get("mobileNumber2.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>Mobile Number cannot exceed 10 numbers</FormHelperText>
                        )}
                        {_.get("mobileNumber2.type", errors) === "minLength" && (
                            <FormHelperText className={classes.error}>Mobile Number cannot be less than 10 numbers</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem}>
                        <TextField
                         disabled={false}
                        // variant="filled"
                            className={(errors.whatsAppNumber && classes.invalid) || classes.mobileNumber}
                            size="small"
                            type="number"
                            margin="dense"
                            variant="outlined"
                            id="whatsAppNumber"
                            autoComplete='off'
                            label={fieldsLabels.whatsAppNumber}
                            name="whatsAppNumber"
                            defaultValue=""
                            onChange={(() => {
                                setStudentRegistration({ ...studentRegistration, [whatsAppNumber]: getValues(whatsAppNumber) });
                            })}
                            helperText={`Please enter your whatsApp number`}
                            inputRef={register({
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                pattern: /^[0-9\b]+$/,
                            })}

                        />
                        {_.get("whatsAppNumber.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>WhatsApp number is required</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>WhatsApp Number cannot exceed 10 numbers</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "minLength" && (
                            <FormHelperText className={classes.error}>WhatsApp Number cannot be less than 10 numbers</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "pattern" && (
                            <FormHelperText className={classes.error}>Please enter valid WhatsApp number</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem} style={{whiteSpace: "pre-wrap"}}>
                        <TextField
                             disabled={false}
                             //variant="filled"
                            className={(errors.emailId && classes.invalid) || classes.formControl}
                            size="small"
                            margin="dense"
                            variant="outlined"
                            autoComplete='off'
                            id="emailId"
                            label={fieldsLabels.emailId}
                            name="emailId"
                            defaultValue=""
                            onChange={(() => {
                                 if (/^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z\s]{2,}$/i.test(getValues(emailId))) { 
                                    autoSave()
                                }
                            })}

                            helperText={`Please enter your email Id, \nPlease ensure right Email id is given as Exam related critical information will be emailed here.`}
                            inputRef={register({
                                required: true,
                                maxLength: 100,
                                pattern: /^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z\s]{2,}$/i
                            })}

                        />
                        {_.get("emailId.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Email Id is required</FormHelperText>
                        )}
                        {_.get("emailId.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>Email Id  cannot exceed 100 characters</FormHelperText>
                        )}
                        {_.get("emailId.type", errors) === "pattern" && (
                            <FormHelperText className={classes.error}>Please enter valid email id</FormHelperText>
                        )}
                    </Grid>

                </form>
            </Grid>
        </div>
    );
}

export default ParentInfo;