import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button, InputLabel, FormControl, FormHelperText, Grid} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select'
import _ from "lodash/fp";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    error: {
        color: "red",
        fontSize: "14px"
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    titleAlignment: {
        marginBottom: "1px",
        // marginTop: "-18px",
        color: "gray"
    },
    actionButtons: {
        marginRight: theme.spacing(3),
    },
    checkboxSize: {
        width: "18px",
        height: "18px"
    },
    textHeadingAlignment: {
        // textAlignLast: "center",
        margin: "auto",
        marginLeft: "44%"
    },
    textFieldAlignment: {
        margin: theme.spacing(1),
        textAlignLast: "center",
    },
    dropDownAlignment: {
        //textAlignLast: "center",
        marginTop: "-18px",
    },
    // root: {
    //     '& > *': {
    //       margin: theme.spacing(1),
    //      // width: '50ch',
    //       textFieldAlignment: "center",
    //     },
    //   },
    root: {
        margin: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        // '& fieldset': {
        //     textFieldAlignment: "center",
        //     // borderColor: 'red',
        //     // borderWidth: 2,
        // },
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function CreateOrEditClass(props) {


    const [state, setState] = React.useState({
        columns: [
            { title: 'Name', field: 'name' },
            { title: 'Start Date', field: 'startDate' },
            { title: 'End Date', field: 'endDate' },
            {
                title: 'Branch',
                field: 'branch',
                lookup: { 34: 'Bangalore', 63: 'Coimbatore' },
            },
        ],

        data: [
            { name: 'BATCH 1', startDate: '19-08-2020', endDate: '19-08-2020', branch: 63 },
            {
                name: 'BATCH 2',
                startDate: '19-08-2020',
                endDate: '19-08-2020',
                branch: 34,
            },
        ],

    });

    const classes = useStyles();
    const { onClose, examObject, open } = props;
    const [title, setTitle] = useState("Create");
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [resetButtonStatus, setRestButtonStatus] = useState(false);
    const [nameFieldLength, setNameFieldLength] = useState(0);
    const [descriptionFieldLength, setDescriptionFieldLength] = useState(0);
    const { control, register, handleSubmit, errors, watch, reset, setValue } = useForm({
        defaultValues: {
            examId: `${examObject.exam.examId !== undefined ? examObject.exam.examId : 0}`,
            name: "",
            organization: `${examObject.exam.organization !== undefined ? examObject.exam.organization : ""}`,
            branch: `${examObject.exam.branch !== undefined ? examObject.exam.branch : ""}`,
            active: `${examObject.exam.active ? examObject.exam.active : false}`,

        }
    });
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const onSubmit = data => {
        data["examId"] = `${examObject.exam.examId !== undefined ? examObject.exam.examId : 0}`;
        data["lastUpdatedDt"] = `${examObject.exam.lastUpdatedDt !== undefined ? examObject.exam.lastUpdatedDt : ""}`;
        data["lastUpdatedBy"] = `${examObject.exam.lastUpdatedBy !== undefined ? examObject.exam.lastUpdatedBy : ""}`;
        data["createdDt"] = `${examObject.exam.createdDt !== undefined ? examObject.exam.createdDt : ""}`;
        data["createdBy"] = `${examObject.exam.createdBy !== undefined ? examObject.exam.createdBy : ""}`;
        axios.post(BASE_URL + "/exam", data,
            {
                headers: {
                    authorization: 'Basic ' + window.btoa('admin' + ":" + 'admin'),
                    
                }
            }).then((Response) => {
                handleClose();
            })
            .catch(function (error) {
            });
    }

    useEffect(() => {
        onNameChange();
        if (examObject.mode === 'create') {
            setTitle("Create");
            setButtonLabel("Save");
        } else {
            setTitle("Edit");
            reset({
                examId: `${examObject.exam.examId !== undefined ? examObject.exam.examId : 0}`,
                name: `${examObject.exam.name !== undefined ? examObject.exam.name : ""}`,
                organization: {
                    id: examObject.exam.organization.organizationId,
                    label: examObject.exam.organization.name,
                    organizationId: examObject.exam.organization.organizationId
                },
                branch: {
                    id: examObject.exam.branch.branchId,
                    label: examObject.exam.branch.name,
                    branchId: examObject.exam.branch.branchId
                },
                active: examObject.exam.active ? true : false
            });
            setNameFieldLength(examObject.exam.name.length);
            setButtonLabel("Update");
            setRestButtonStatus(true);
        }
    }, [examObject]);

    const handleClose = () => {
        reset({
            name: "",
            organization: "",
            branch: ""
        });
        setRestButtonStatus(false);
        onNameChange();
        onClose();
    }

    const onOrganizationChanged = () => {

    }

    const onNameChange = () => {
        var nameFieldValue = watch("name");
        setNameFieldLength(nameFieldValue.length);
    }

    const onDescriptionChange = () => {
        var descriptionFieldValue = watch("description");
        setDescriptionFieldLength(descriptionFieldValue.length);
    }

    const orgOptions = [
        { value: 1, organizationId: 1, label: 'Vitalmed IT Solutions' },
        { value: 2, organizationId: 2, label: 'Vitalmed 1 IT Solutions' },
        { value: 3, organizationId: 3, label: 'Vitalmed 2 IT Solutions' },
        { value: 4, organizationId: 4, label: 'Vitalmed 3 IT Solutions' }
    ];

    const branchOptions = [
        { value: 1, branchId: 1, label: 'CoimbatureBranch' },
        { value: 2, branchId: 2, label: 'Bangalore Branch' },
        { value: 3, branchId: 3, label: 'Coimbatore1 Branch' },
        { value: 4, branchId: 4, label: 'Bangalore1 Branch' }
    ];
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <div>
            <Grid container >
                <Dialog fullScreen={fullScreen}
                    //  fullWidth={true} maxWidth={"xl"}
                    onClose={handleClose} aria-labelledby="create-or-delete-dialog"
                    open={open} disableBackdropClick={true} TransitionComponent={Transition}>



                    <DialogActions className={classes.actionButtons}>
                        <DialogTitle className={classes.textHeadingAlignment}>
                            {title} Exam
            </DialogTitle>
                        <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}>Cancel</Button>
                        <Button variant="outlined" size={"small"} disabled={resetButtonStatus}
                            onClick={() => {
                                reset({
                                    name: "",
                                    organization: "",
                                    branch: ""
                                }
                                );
                            }}>Reset</Button>
                        <Button variant="outlined" size={"small"} color="primary" onClick={handleSubmit(onSubmit)}>{buttonLabel}</Button>
                    </DialogActions>


                    <DialogContent className={classes.root} >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} item xs={12} >
                                <Grid item xs={6}>
                                    <InputLabel>Name *</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onNameChange}
                                        // className={classes.textHeadingAlignment}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}

                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>Name is required</p>
                                        )}
                                        {_.get("name.type", errors) === "maxLength" && (
                                            <p>Name cannot exceed 100 characters</p>
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={6}>
                                    <InputLabel>Description *</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onDescriptionChange}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>Description is required</p>
                                        )}
                                        {_.get("name.type", errors) === "maxLength" && (
                                            <p>Description cannot exceed 100 characters</p>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} item xs={12} >
                                <Grid item xs={4}>
                                    <InputLabel>Start Date *</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onDescriptionChange}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>Start Date is required</p>
                                        )}
                                        {/* {_.get("name.type", errors) === "maxLength" && (
                                    <p>Name cannot exceed 100 characters</p>
                                )} */}
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <InputLabel>End Date *</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onDescriptionChange}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>End Date is required</p>
                                        )}
                                        {/* {_.get("name.type", errors) === "maxLength" && (
                                    <p>Name cannot exceed 100 characters</p>
                                )} */}
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <InputLabel>Qualifying Marks*</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onDescriptionChange}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>Qualifying Marks is required</p>
                                        )}
                                        {/* {_.get("name.type", errors) === "maxLength" && (
                                    <p>Name cannot exceed 100 characters</p>
                                )} */}
                                    </div>
                                </Grid>
                            </Grid>
                            {/* <FormControl fullWidth >
                                <p className={classes.titleAlignment}>Organization *</p>
                                <Controller
                                    name="organization"
                                    as={Select}
                                    control={control}
                                    options={orgOptions}
                                    defaultValue=""
                                    rules={{ required: true }}
                                />
                                {_.get("organization.type", errors) === "required" && (
                                    <p className={classes.error}>Organization is required</p>
                                )}
                            </FormControl> */}
                            <Grid container spacing={2} item xs={12} >




                                <Grid item xs={4}>
                                    <FormControl fullWidth className={classes.dropDownAlignment}>
                                        <p className={classes.titleAlignment}>Class *</p>
                                        <Controller
                                            name="class"
                                            as={Select}
                                            control={control}
                                            options={branchOptions}
                                            rules={{ required: true }}
                                            defaultValue=""
                                        />
                                        {_.get("branch.type", errors) === "required" && (
                                            <p className={classes.error}>Class is required</p>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} className={classes.dropDownAlignment}>
                                    <FormControl fullWidth>
                                        <p className={classes.titleAlignment}>Section *</p>
                                        <Controller
                                            name="section"
                                            as={Select}
                                            control={control}
                                            options={branchOptions}
                                            rules={{ required: true }}
                                            defaultValue=""
                                        />
                                        {_.get("branch.type", errors) === "required" && (
                                            <p className={classes.error}>Section is required</p>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} className={classes.dropDownAlignment}>
                                    <FormControl fullWidth>
                                        <p className={classes.titleAlignment}>Subject *</p>
                                        <Controller
                                            name="branch"
                                            as={Select}
                                            control={control}
                                            options={branchOptions}
                                            rules={{ required: true }}
                                            defaultValue=""
                                        />
                                        {_.get("branch.type", errors) === "required" && (
                                            <p className={classes.error}>Subject is required</p>
                                        )}
                                    </FormControl>
                                </Grid>

                            </Grid>

                            {/* <Grid container spacing={2} item xs={12} >
                           

                          

                        </Grid> */}

                            <Grid container spacing={2} item xs={12} >
                                <Grid item xs={4}>
                                    <InputLabel>Duration *</InputLabel>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        name="name"
                                        size="small"
                                        onChange={onDescriptionChange}
                                        inputRef={register({
                                            required: true,
                                            maxLength: 100,
                                        })}
                                        fullWidth
                                        defaultValue=""
                                        autoComplete="false"
                                    ></TextField>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormHelperText>Max 100 Chars</FormHelperText>
                                        </Grid>
                                        <Grid item xs={6} justify="flex-end">
                                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <div className={classes.error}>
                                        {_.get("name.type", errors) === "required" && (
                                            <p>Duration is required</p>
                                        )}
                                        {/* {_.get("name.type", errors) === "maxLength" && (
                                    <p>Name cannot exceed 100 characters</p>
                                )} */}
                                    </div>
                                </Grid>

                                <Grid item xs={8} className={classes.dropDownAlignment}>
                                    <FormControl fullWidth>
                                        <p className={classes.titleAlignment}>Branch *</p>
                                        <Controller
                                            name="branch"
                                            as={Select}
                                            control={control}
                                            options={branchOptions}
                                            rules={{ required: true }}
                                            defaultValue=""
                                        />
                                        {_.get("branch.type", errors) === "required" && (
                                            <p className={classes.error}>Branch is required</p>
                                        )}
                                    </FormControl>
                                </Grid>

                            </Grid>



                            <FormControl>
                                <Grid container justify="flex-start" >
                                    <input className={classes.checkboxSize}
                                        ref={register}
                                        name="active"
                                        type="Checkbox"
                                    /> Active
                    </Grid>
                            </FormControl>


                        </form>
                    </DialogContent>


                    {/* <DialogActions >
                        <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}>Add Batch</Button>
                    </DialogActions> */}


                    <MaterialTable
                        title="Batch"
                        columns={state.columns}
                        data={state.data}
                        icons={tableIcons}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.push(newData);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            setState((prevState) => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                            });
                                        }
                                    }, 600);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        setState((prevState) => {
                                            const data = [...prevState.data];
                                            data.splice(data.indexOf(oldData), 1);
                                            return { ...prevState, data };
                                        });
                                    }, 600);
                                }),
                        }}
                    />

                </Dialog >
            </Grid>


        </div>

    );
}

CreateOrEditClass.propTypes = {
    open: PropTypes.bool.isRequired,
    examObject: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};