import React from "react";
import {
  Typography,
  Grid,
  Paper,
  useTheme,
  makeStyles,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";

import instructions from "./instructions.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import sample_test_taking_1 from "./sample_test_taking_1.png";
import sample_test_taking_2 from "./sample_test_taking_2.png";

import dashboard from "./studentDashboard.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    overflowX: "hidden",
    backgroundImage: `url(${instructions})`,
  },
  paper: {
    marginTop: "10px",
    justifyContent: "screenRight",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center",
    position: "absolute",
    width: "100%",
    height: "auto",
    backgroundImage: `url(${instructions})`,
    overflowX: "hidden",
  },
  content: {
    fontSize: "18px",
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "100%",
    flexShrink: 2,
    textAlign: "left",
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  mainHeading: {
    fontWeight: "bold",
    textAlign: "center",
  },
  button: {
    height: "2%",
    width: "auto",
  },
  imageContainer: {
    textAlign: "center",
    width: "90%",
    marginTop: "2%",
  },
}));

function Instructions(props) {
  const classes = useStyles();
  let theme = useTheme();
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const back = "Back To Home";
  const [isAGCLogin,setIsAGCLogin]= React.useState(props.location.state.examination === 'agc'?true:false); 
  const heading =isAGCLogin ? 'Online AGC Instructions for the Exam':'Online Prodigy Instructions';
  const [expandedPanel1, setExpandedPanel1] = React.useState(true);
  const [expandedPanel2, setExpandedPanel2] = React.useState(true);
  const [expandedPanel3, setExpandedPanel3] = React.useState(true);
  const [expandedPanel5, setExpandedPanel5] = React.useState(true);
  const [expandedPanel6, setExpandedPanel6] = React.useState(true);
  const [expandedPanel7, setExpandedPanel7] = React.useState(true);
  const [expandedPanel8, setExpandedPanel8] = React.useState(true);



  const cardHeading1 = isAGCLogin ?'Please keep the following READY before the exam:':'About Online SIP National Prodigy 2020:';
  const cardHeading2= isAGCLogin ?'Use of Two devices:':'Pre Prelimnary Round Preparation:';
  const cardHeading3= isAGCLogin?'About Test:':'Just before the Exam:';
  const cardHeading4 = isAGCLogin ? 'Minimum Browser Requirements:':'Use of Two devices:';
  const cardHeading5= isAGCLogin ?'':'About the Test:';
  const cardHeading6= isAGCLogin ?'':'Minimum Browser Requirements:'


  return (
    <div className={classes.root}>
      <Grid container>
        <Paper className={classes.paper}>
          <Grid item xs={12} md={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
              history.push("/student/portal/dashboard", {examination : props.location.state.examination});}}
              className={classes.button}>{back}
            </Button>
          </Grid>
          <Grid
            item container xs={12} md={12} justify="center" style={{ textAlign: "center" }}>
            <Typography variant="h3" className={classes.mainHeading}>
              {heading}
            </Typography>
          </Grid>
          <Grid
            item container xs={12} md={12}
            style={{ padding: "20px", width: "100%" }}>
           
           {isAGCLogin && 
         <Accordion
         style={{ width: "100%" }}
         expanded={expandedPanel8}
         onChange={() => setExpandedPanel8(!expandedPanel8)}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1bh-content"
           id="panel1bh-header">
           <Typography className={classes.heading}>Practice Exam:
           </Typography>
         </AccordionSummary> 
         <AccordionDetails>
           <Grid item container xs={12} md={12}>
             <Grid item xs={12} md={12}>
           <Typography>You may take up to 4 practice exams before the Test. Once you attend the free ‘Math is Fun’ Workshop, 6 more practice exams will be unlocked.</Typography>
           </Grid>
           
           </Grid>
         </AccordionDetails> 
       </Accordion>
       } 
          





           
           
           
           
            <Accordion
              style={{ width: "100%" }}
              expanded={expandedPanel1}
              onChange={() => setExpandedPanel1(!expandedPanel1)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography className={classes.heading}>
                  {cardHeading1}
                </Typography>
              </AccordionSummary>
              
              {isAGCLogin ?
              <AccordionDetails>
                <ul style={{ textAlign: "left" }} className={classes.content}>
                  <li><Typography>
                      Please keep 2 devices ready (Either
                      Laptop/Tablet/Smartphone – Any 2 of these).
                    </Typography> </li>
                  <li> <Typography>Pencil and Paper for rough work.</Typography> </li>
                  <li> <Typography> School Student ID card (Current Year or Previous year) </Typography>
                  </li>
                  {/* <li> <Typography>
                      Hope you received the SMS/email with the Login ID and
                      Password for the Exam. </Typography>  </li>
                  <img
                    src={sample_SMS} alt="" style={{ textAlign: "center", width: smallScreen ? "50%" : "30%",
                      marginLeft: "10%", }}></img> */}
                  <li> <Typography>
                      You will also receive a Zoom Link to join for the Test.
                      During Exam reporting time, you need to log in from both
                      devices by clicking this Zoom link.
                    </Typography> </li>
                  <li> <Typography>
                      Have an Earphone (Headset) for the child during the test.
                      If a parent can also have an earphone, audio disturbance
                      won’t be there.
                    </Typography> </li>
                </ul>
              </AccordionDetails>:
              <AccordionDetails>
                <ul style={{ textAlign: "left" }} className={classes.content}>
                  <li><Typography>There will be 3 rounds - Preliminary, State and National.</Typography></li>
                  <li><Typography>Each round date</Typography>
                  <ul> <li><Typography>Preliminary round: 12 Feb till 25th Feb </Typography></li>
                      <li><Typography>State Rounds: To be decided</Typography></li>
                      <li><Typography>National Round: To be decided</Typography></li></ul>
                  </li>
                  <li><Typography>At each round certain % of top students will move to the next round.</Typography></li>
                  <li><Typography>After the cut-off date, no test or re-test will happen for that round.</Typography></li>
                  <li><Typography>With a few days after the round results will be declared on portal.</Typography></li>
              </ul>
              </AccordionDetails> }
              
            </Accordion>
            <Accordion
              style={{ width: "100%" }} expanded={expandedPanel2}
              onChange={() => setExpandedPanel2(!expandedPanel2)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content" id="panel2bh-header">
                <Typography className={classes.heading}>
                  {cardHeading2}
                </Typography>
              </AccordionSummary>
              {isAGCLogin ? 
              <AccordionDetails>
                <Grid item container xs={12} md={12}>
                  <ul style={{ textAlign: "left" }} className={classes.content}>
                    <li> <Typography>
                        The child can use the device with a larger screen.
                      </Typography> </li>
                    <li> <Typography>
                        Once you login using Zoom, please rename the Zoom
                        display name as "STUDENT's NAME" for the student's
                        device and "CHILD NAME's PARENT" in parent device.
                      </Typography> </li>
                    <li> <Typography>
                        Parent device – Video should be ON and Audio should be
                        muted. Child’s device both video and Audi should be ON.
                      </Typography> </li>
                    <li> <Typography>
                        The camera will be ON in both device and the Parent has
                        to hold his camera towards the child from
                        behind(diagonally) as seen below:
                      </Typography> </li>
                  </ul>
                  <Grid item container md={12} xs={12} justify="center">
                    <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                      <Typography>Child’s device front camera</Typography>
                      <img  src={sample_test_taking_1} alt=""
                        className={classes.imageContainer}></img>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                      <Typography>Parent device back camera</Typography>
                      <img
                        src={sample_test_taking_2} alt=""
                        className={classes.imageContainer}></img>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>:
              <AccordionDetails>
                <ol style={{ textAlign: "left" }} className={classes.content}>
                <li><Typography>You would have received the SMS/email with the Login ID and Password for the Exam.</Typography></li>
                <li><Typography>n case Login ID and Password  have not yet been received, contact your franchisee.</Typography></li>
                <li><Typography>You can use this Login ID and password on www.sipcontest.com for practising.</Typography></li>
                </ol>
                
                
                </AccordionDetails>}
            </Accordion>
            <Accordion
              style={{ width: "100%" }} expanded={expandedPanel3}
              onChange={() => setExpandedPanel3(!expandedPanel3)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content" id="panel3bh-header">
                <Typography className={classes.heading}>{cardHeading3}</Typography>
              </AccordionSummary>
              {isAGCLogin ? 
              <AccordionDetails>
                <ul className={classes.content}>
                  {/* <li> <Typography>
                      The link to start the exam will be provided by the teacher
                      during the Zoom Video call.
                    </Typography> </li>
                  <li> <Typography>
                      This Exam portal link to be opened only from the child’s
                      device, by clicking the link.
                    </Typography> </li>
                  <br />
                  <img
                    src={student_login_page} alt="" style={{ width: smallScreen ? "100%" : "50%",
                      paddingLeft: smallScreen ? "0%" : "5%",}}></img>
                  <br /> */}
                  <li>  <Typography>
                      Once the child enters Login ID and Password, the exam
                      portal’s home page will appear. Child has to click on Start Test to take the test.
                    </Typography>  </li>
                  <br />
                  <img
                    src={dashboard} alt="" style={{  width: smallScreen ? "100%" : "50%",
                      paddingLeft: smallScreen ? "0%" : "5%",}}></img>
                  <br />
                  <li> <Typography>
                      The duration of the Round 1 Test is 10 minutes. The test
                      will end automatically once the 10 minutes’ timer is over.
                    </Typography> </li>
                  <li>  <Typography>
                      In case of any interruption or internet issue, we will
                      reschedule the test for the next available slot.
                    </Typography>  </li>
                </ul>
              </AccordionDetails> :
              <AccordionDetails>
                <ol style={{ textAlign: "left" }} className={classes.content}>
                  <li><Typography>Please keep 2 devices ready (Either Laptop/Tablet/Smartphone – Any 2 of these).</Typography></li>
                  <li><Typography>You will also receive a Zoom Link to join for the Test.</Typography></li>
                  <li><Typography>During Exam reporting time, you need to log in from both devices by clicking this Zoom link.</Typography></li>
                  <li><Typography>Have an Earphone (Headset) for the child during the test.</Typography></li>
                  <li><Typography>If a parent can also have an earphone, audio disturbance won’t be there.</Typography></li>
                </ol>
                
                </AccordionDetails>}
            </Accordion>
            <Accordion
              style={{ width: "100%" }} expanded={expandedPanel5}
              onChange={() => setExpandedPanel5(!expandedPanel5)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header">
                <Typography className={classes.heading}>
                  {cardHeading4}
                </Typography>
              </AccordionSummary>
              {isAGCLogin ? 
              <AccordionDetails>
                <Typography>
                  Internet Explorer 11/Firefox 1 or Google Chrome are required
                  to log into the exam
                </Typography>
              </AccordionDetails> :
               <AccordionDetails>
                 <Grid item container xs={12} md={12}>
                 <ul style={{ textAlign: "left" }} className={classes.content}>
                   <li><Typography>The child can use the device with a larger screen.</Typography></li>
                   <li><Typography>Once you login using Zoom, please rename the Zoom display name as "STUDENT’s NAME" for the student’s device and "STUDENT NAME‘s PARENT" in the parent device.</Typography></li>
                   {/* <li><Typography>During test Child's device mike should be off and Child's parent mike will always be on to avoid noise echo.</Typography></li> */}
                   {/* <li><Typography>Both the Video should be always on, switching off of video will auto disqualify the test.</Typography></li> */}
                   <li><Typography>Parent Device: Video should be ON and  Audio should be muted. Child's device both video and audio should be muted</Typography></li>
                   <li><Typography>In case of internet disruption, which will be verified by exam portal and a retest arranged for one more time maximum.</Typography></li>
                   <li><Typography>The camera will be ON in both devices and the Parent has to hold his camera towards the child from behind(diagonally) as seen below:</Typography></li>
                 </ul>
                 <Grid item container md={12} xs={12} justify="center">
                    <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                      <Typography>Child’s device front camera</Typography>
                      <img  alt="" src={sample_test_taking_1}
                        className={classes.imageContainer}></img>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                      <Typography>Parent device back camera</Typography>
                      <img
                        src={sample_test_taking_2} alt=""
                        className={classes.imageContainer}></img>
                    </Grid>
                  </Grid>
                  </Grid>
               </AccordionDetails> }
            </Accordion>
           {!isAGCLogin && 
            <Accordion
              style={{ width: "100%" }}
              expanded={expandedPanel1}
              onChange={() => setExpandedPanel6(!expandedPanel6)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography className={classes.heading}>
                  {cardHeading5}
                </Typography>
              </AccordionSummary>
              
              <AccordionDetails>
                <ul style={{ textAlign: "left" }} className={classes.content}>
                  <li><Typography>The link to start the exam is the same as you would have used to take the practice exam.</Typography></li>
                  <li><Typography>This Exam portal link to be opened only from the child’s device, by clicking the link. </Typography></li>
                  <li><Typography>Once the child enters Login ID and Password, the exam portal's home page will appear.</Typography></li>
                  <li><Typography>Test will have Section 1 for FL1 and FL2.</Typography></li>
                  <li><Typography>Test will have Section 1 and Section 2 for Level FL3.</Typography></li>
                  <li><Typography>Test will have Section 1, Section 2 and Section 3 from FL4 and above.</Typography></li>
                  <li><Typography>The test time for Section 1 (5 min), Section 2 (3 min) and Section 3 (3 min).</Typography></li>
                  <li><Typography>Kids will wear SIP T-shirts on Prodigy days in State and National levels.</Typography></li>
                  <li><Typography>n Preliminary round too, it is preferable to wear SIP T Shirt, for centre level group clicks on zoom.</Typography></li>
                </ul>
              </AccordionDetails> 
            </Accordion>
         }
         {!isAGCLogin && 
         <Accordion
         style={{ width: "100%" }}
         expanded={expandedPanel1}
         onChange={() => setExpandedPanel7(!expandedPanel7)}>
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1bh-content"
           id="panel1bh-header">
           <Typography className={classes.heading}>
             {cardHeading6}
           </Typography>
         </AccordionSummary> 
         <AccordionDetails>
           <Grid item container xs={12} md={12}>
             <Grid item xs={12} md={12}>
           <Typography>Internet Explorer 11 / Firefox 1 or Google Chrome are required to log into the exam.</Typography>
           </Grid>
           <Grid item xs={12} md={12}>
           <Typography>For any further queries contact your franchisee or write to sipinfo@sipacademyindia.com</Typography>
           </Grid>
           </Grid>
         </AccordionDetails> 
       </Accordion>
       }
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push("/student/portal/dashboard", { examination : props.location.state.examination});}}
              className={classes.button} >
              {back}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}

export default Instructions;
