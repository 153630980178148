import React, { useState, useEffect } from "react";
import {
  useTheme,
  makeStyles,
  Grid,
  Paper,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Button,
  AppBar,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import test from "./images/background2.svg";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import jayanti from "./images/jayanti.jpg"
import manasi from "./images/manasi.jpg"
import swathi from "./images/swathi.jpg"
import prathibha from "./images/prathibha.jfif"
import navenReddy from "./images/navenReddy.jpg"
import MeeraRaman from "./images/MeeraRaman.jpg"
import SURJEET_KHANNA from "./images/SURJEET_KHANNA.jpg";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/sip-academy-logo1.png";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
import mobileBG from "./images/mobileBG.svg";
import kohli from "./images/Kohli.jpeg";


const useStyle = makeStyles((theme) => ({
  root: {
    alignContent: "stretch",
    wrap: "nowrap"
  },
  paper: {
    width: "100%",
    height: '122ch',
    backgroundImage: `url(${test})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"

  },
  cardContainer: {
    borderRadius: "30px",
    padding: "1%",
  },
  cardTitle: {
    fontWeight: "bold",
    color: "#000099",
    fontSize: "40px",
  },
  container: {
    fontSize: theme.spacing(2),
    fontWeight: "bold",
    alignItems: "space-between",
    textAlign: "left",
    color: "black"
  },
  avatar: {
    backgroundColor: red[100],
    height: "100px",
    width: "100px"
  },
  cardHeading: {
    color: "blue",
    fontSize: theme.spacing(2),
    whiteSpace: "pre-line",
    fontWeight: "bold"
  },
  mobileTestimonialsPaper: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${mobileBG})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none",
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center",
  },
  testimonialTitle: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    height:"85%",
    whiteSpace: "pre-line",
    display:"inline-block",
    fontSize:theme.spacing(3),
    padding:theme.spacing(3),
    width:theme.spacing(3)
  },

}));

const Testimonials = () => {
  const classes = useStyle();
  const theme = useTheme();
  const title = "TESTIMONIALS";
  const register ="REGISTER NOW"
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState();
  const [totalNumberOfPages, setTotalNumberOfPages] = useState();
  const [columnSize, setColumnSize] = useState();
  const [testimonialsToBeDisplayed, setTestimonialsToBeDisplayed] = useState([]);
  const [disableBackNavigateButtonStatus, setDisableBackNavigateButtonStatus] = useState(true);
  const [disableForwardNavigateButtonStatus, setDisableForwardNavigateButtonStatus] = useState(false);

  useEffect(() => {
    const testimonials = [
      {
        id: 1,
        cardTitle: "Surjeet Khanna, \n Principal, Delhi Public School, \n Greater Faridabad",
        content: `"How exhilarating it is to watch young students learn and grow with confidence! I thank SIP for giving our school abundant opportunities to enjoy the
        outcome of our student's keen pursuits of Abacus based learning with their competent team of educators. The
        very recent honour of being the Champion School in Arithmetic Genius Contest for North Region is a testimony
        of the spirited manner in which the students are applying themselves to SIP Abacus and documenting personal growth."`,
        image: `${SURJEET_KHANNA}`

      },
      {
        id: 2,
        cardTitle: "Naveen Reddy, Principal, \n Takshasila Public School, \n Hyderabad",
        content: `"AGC is the right platform for children to test their Mathematical ability.I'm a maths teacher, but when I see these children perform sums, it is mind-blowing.
        Even I'm learning from these participants of AGC. this initiative of SIP abacus will surely bring out children from the inhibitions they have regarding math calculations.
        I wish I could be a child and take part in this Contest!"`,
        image: `${navenReddy}`


      },
      {
        id: 3,
        cardTitle: "Mrs. Manasi Hasabnis, Principal, Novel International School, Pune",
        content: `"SIP Arithmetic Genius Contest AGC is one of the most enjoyed competitive exam for our students. They 
        have a very well designed and constructive syllabus to improve the mathematical skills. Our students have been 
        enrolling in it for the past 3 years now. Most of our students have reached the National Level and have exhibited 
        outstanding performance."`,
        image: `${manasi}`

      },
      
      {
        id: 4,
        cardTitle: "Mrs. Swati Aru, Principal, Blossom Public School, Pune",
        content: `"Mathematics is the queen of Sciences. Arithmetic is the queen of Mathematics. SIP Arithmetic Genius
         contest deals with Arithmetic skills, a branch of maths covering all the basic operations. AGC mainly brushes 
         up the skills of young minds. Our students have been participating with tremendous enthusiasm for the past 5 
         years and have achieved various cash awards, prizes and trophies at state and national levels with outstanding 
         performance."`,
        image: `${swathi}`


      },
      
      {
        id: 5,
        cardTitle: "Mrs. Jayanthi Venkatraman, Academic Director, Sister Nivedita School, Hyderabad",
        content: `"It’s a great pleasure to accredit SIP Abacus for this wonderful AGC opportunity. AGC is a platform to hone 
        arithmetic skills of young children. The end result of the contest is our children emerge out with tremendous confidence 
        concreted with communication skills. It’s noteworthy to mention that our students have bagged several state level and 
        national level awards in AGC. Best wishes to SIP Academy for this year’s online AGC."`,
        image: `${jayanti}`

      },
      {
        id: 6,
        cardTitle: "Mrs.  Prathibha V Rao, Vice Principal, Daffodils foundation for Learning, Bangalore",
        content: `"Many students took part in AGC with great 
        interest and enthusiasm. We are proud that many of our students won the contest. The contest was well organized with 
        clear instructions.  The parents and the students received the contest very well. Ample time was given for students to 
        prepare for the same. I would like to thank the organizers of the SIP Academy for giving our students an opportunity to 
        take part in the contest and gaining confidence."`,
        image: `${prathibha}`

      },
      {
        id: 7,
        cardTitle: "Meera Ramanan , Principal \n Arbor International School, Hyderabad ",
        content: `"AGC is an amazing contest which kindles the though process of the children. I encourage all my children to 
        participate in this unique contest to exhibit their calculation skills. AGC helps children to 
        practice math before the actual competition thereby increasing their numerical ability during
        the whole process. Thanks to SIP for doing such events year after year!"`,
        image: `${MeeraRaman}`
      },
      {
        id: 8,
        cardTitle: "Dr (Mrs) M Kohli , Director, \n South Point School, Kolkata ",
        content: `"We have observed that the interest of the children has grown along with their computing skills. 
        At times, we are surprised to see the ease with which they handle the difficult sums. This definitely helps 
        the children to concentrate more and be more confident about themselves."`,
        image: `${kohli}`
      },
    ]


    if (smallScreen) {
      setRecordsPerPage(1);
      setColumnSize(12);
      setTotalNumberOfPages(Math.floor(testimonials.length / 1));
    } else {
      setRecordsPerPage(3);
      setColumnSize(4);
      let noOfPages = Math.ceil(testimonials.length / 3);
      setTotalNumberOfPages(noOfPages);
      if (page >= noOfPages) {
        setPage(noOfPages - 1);
      }
    }

    // PAGINATION LOGIC
    if (page === 0) {
      setDisableBackNavigateButtonStatus(true);
    } else {
      setDisableBackNavigateButtonStatus(false);
    }
    if (page === (totalNumberOfPages - 1)) {
      setDisableForwardNavigateButtonStatus(true);
    } else {
      setDisableForwardNavigateButtonStatus(false);
    }
    setTestimonialsToBeDisplayed(testimonials.slice(page * recordsPerPage, (page + 1) * recordsPerPage));

  }, [recordsPerPage, page, smallScreen, totalNumberOfPages]);



  function DisplayedCards(props) {
    const { testimonial } = props;
    return (
      <React.Fragment>
        <Card elevation={1} className={classes.cardContainer} style={{ height: "auto" }}>
          <CardHeader className={classes.cardHeading}
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <img src={testimonial.image} alt="no img" style={{ height: "100%", width: "100%" }} />
              </Avatar>
            }
            title={testimonial.cardTitle} />
          <CardContent className={classes.cardTitle}>
            <Grid container justify="center" wrap="nowrap">
              <Typography style={{ textAlign: "justify" }}
                className={classes.container}
              >{testimonial.content}
              </Typography>

            </Grid>
          </CardContent>
        </Card>

      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container spacing={2} >
          <Paper className={smallScreen ? classes.mobileTestimonialsPaper : classes.paper} >
          <Grid item container md={12} xs={12} lg={12} sm={12}>
              <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3}  xl={3}></Grid>
                <Grid item md={4} xs={9} sm={9} lg={4} xl={3}>
                  <img
                    src={sipAbacusLogo}
                    alt="logo"
                    height={smallScreen ? "60%" : "50%"}
                    width={smallScreen ? "60%" : "30%"}
                  />
                </Grid>
                <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
              </AppBar>
            </Grid>

            <Grid item container>
              <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
              <Grid
                item
                md={2}
                xs={4}
                sm={4}
                lg={2}
                xl={1}
                className={classes.container}
                justifyContent="flex-start"
              >
                <img
                  src={agcLogo}
                  alt="Logo"
                  style={{ height:smallScreen?"80%": "60%", width: "60%" }}
                />
              </Grid>
              <Grid item md={6} xs={1} sm={1} lg={6} xl={6}></Grid>
             
            </Grid>

            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
              <Grid item md={4} xs={2} lg={4} sm={2} xl={4}>
                
              </Grid>
              <Grid
                item
                md={4}
                xs={8}
                lg={4}
                sm={8}
                xl={4}
                className={classes.testimonialTitle}
                style={{ marginTop: smallScreen ? theme.spacing(2) : 0 }}
              >
                {title}
              </Grid>
              <Grid item md={4} xs={2} lg={4} sm={2} xl={4}></Grid>
            </Grid>

            <br />

            <div style={{ padding: "20px" }}>
              
              <Grid item container spacing={2} xs={12} md={12} lg={12} sm={12} xl={12} justify="center">
                <Grid item container xs={1} md={1} sm={1} lg={1}  xl={1} justify="center" alignItems="center">
                  <IconButton elevation={8} disabled={disableBackNavigateButtonStatus} style={{fontSize:smallScreen?theme.spacing(2):theme.spacing(4)}}
                    onClick={(() => {
                      setPage(prevPage => prevPage - 1);
                    })}>
                    <ChevronLeftIcon elevation={8} fontSize="large" style={{ color: "white" }} />
                  </IconButton>
                </Grid>

                <Grid item container justify="center" spacing={2} xs={10} md={10} lg={10} sm={10} xl={10}>

                  {testimonialsToBeDisplayed.map((testimonial) => (
                    <Grid
                      key={testimonial.id}
                      item
                      container
                      md={columnSize}
                      xs={columnSize}
                      justify="center"
                    >
                      <DisplayedCards testimonial={testimonial} />
                    </Grid>
                  ))}

                </Grid>

                <Grid item container xs={1} md={1}  sm={1} lg={1} xl={1} justify="center" alignItems="center">
                  <IconButton elevation={8} disabled={disableForwardNavigateButtonStatus}
                    onClick={(() => {
                      setPage(prevPage => prevPage + 1);
                    })}>
                    <ChevronRightIcon fontSize="large" style={{ color: "white" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
