import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Portal from './components/Admin/Portal';
import Login from './components/Admin/Login';
import SectionList from './components/Admin/Examination/Section/SectionList';
import SubjectList from './components/Admin/Examination/Subject/SubjectList';
import ExamsList from './components/Admin/Examination/Exam/ExamList';
import ExaminationList from './components/Admin/Examination/Examination/ExaminationList';
import QuestionPaperList from './components/Admin/Examination/QuestionPaper/QuestionPaperList';
import StudentLogin from './components/Students/Login/StudentLogin';
import StudentDashboard from './components/Students/Dashboard/StudentDashboard';
import StudentPortal from './components/Students/Portal/StudentPortal';
import Instructions from './components/Students/Instructions/Instructions';
import ClassList from './components/Admin/Examination/Class';
import StudentList from './components/Admin/Student/StudentList';
import Exam from './components/Students/Exam/Exam';
import ThankYouPage from './components/Common/ThankYouPage';
import ContestLandingPage from './components/Examination/contestLanding';
import Workshops from './components/Admin/Student/Workshop/WorkshopList';
import ScheduleBatchList from './components/Admin/Student/SchedulBatch/ScheduleBatchList';
import AutoLogin from './components/Admin/AutoLogin/AutoLogin';
import Report from './components/Admin/Report/Report';
import Dashboard from './components/Admin/Student/Dashboard/Dashboard';
import Axios from 'axios';
import Round from './components/Admin/Student/Round';
import StudentListProdigy from './components/Admin/Student/Prodigy/StudentListProdigy';
import CSODashboard from './components/Admin/Student/Prodigy/CSODashboard/CSODashboard';
import StudentRegistrationFormNewPage from './components/Examination/contestLanding/studentRegForm';

function App() {

    const [examinationList,setExaminationList] = React.useState([]);
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const studentLogin ='student/login';
    const adminLogin='/admin/portal/';
    const [bodyParameters, setBodyParameters] = useState({
        childName: "",
        branch: {
          name: "",
          branchId: 0,
        },
        schoolName: "",
        classNumber: {
          classId: 0,
        },
        registrationCompleted: true,
        examStatus: "",
        sipAbacusStudent: "",
        examCompletedStatus: "notCompleted",
        examination: {
            examinationId: 0
        },
        examGroupId: 0
      });

    useEffect(() => {
            Axios.get(BASE_URL + "/examinations",
                {
                    params: {
                        body: JSON.stringify({
                            name:"",
                            organization: {
                                'organizationId':1
                            },
                        }),
                    },
    
                    // headers: {
                    //     authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                    // }
                }).then((response) => {
                    setExaminationList(response.data.content);    
                });
    }, [])

    


    /* Main app with router to admin and student portals*/

    return (
        <Router>
            <Switch>
                <Route path="/admin/login">
                    <Login />
                </Route>
                <Route path="/admin/autoLogin">
                    <AutoLogin />
                </Route>

                <Redirect exact from="/student" to="/student/login" />  
                {examinationList.map((examination,index) => (
                <Route exact path ={"/"+examination.examinationURL+"/"+studentLogin} render={(props) => <StudentLogin examination= {examination.examinationURL} {...props} /> } />
                ))}

                 <Redirect exact from="/agc/student/login" to="/student/login" /> 
                 <Route exact path ="/student/login" render={(props) => <StudentLogin examination="agc" {...props} /> } />
 


                <Route path="/agc/thankYou" component={ThankYouPage} />        
                <Route path="/student/portal" >
                    <StudentPortal>
                        <Route path="/student/portal/dashboard" component={StudentDashboard}  />
                        <Route path="/student/portal/instructions" component={Instructions}/> 
                        {/* <Route path="/student/portal/test" component={QuestionsMainComponent}/>    */}
                        <Route path="/student/portal/test2" component={Exam}/>       
                        {/* <Redirect  from="/student/portal" to="/student/portal/dashboard" />                   */}
                    </StudentPortal>
                </Route> 

  



                <Route path="/admin/portal" >
                    <Portal>
                        {/* Add loop here to generate routes dynamically */}
                        <Route exact path="/admin/portal/agc/dashboard" component={ Dashboard } />
                        <Route exact path="/admin/portal/examination" component={ ExaminationList } />
                        <Route exact path="/admin/portal/examination/exam" component={ ExamsList } />
                        <Route exact path="/admin/portal/examination/subject" component={ SubjectList } />
                        <Route exact path="/admin/portal/examination/class" component= { ClassList  } />
                        <Route exact path="/admin/portal/examination/section" component={ SectionList } />
                        <Route exact path="/admin/portal/examination/questionPaper" component={ QuestionPaperList } />
                        <Route exact path="/admin/portal/agc/students/practice" render={(props) => <Round examStatus="practice" examinationId={1} examGroupId={1} currentRound={0} nextRound={'Round 1'} {...props}/>} />
                        <Route exact path="/admin/portal/agc/students/round1" render={(props) => <Round examinationId={1} examGroupId={2} currentRound={1} nextRound={'Round 2'} {...props}/>} />
                        {/* <Route exact path="/admin/portal/students" render={(props) => <StudentList examStatus="notCompleted" examinationId={1} examGroupId={1} {...props} /> } /> */}
                        {/* <Route exact path="/admin/portal/students/examCompleted" render={(props) => <StudentList examStatus="completed"  {...props} /> } /> */}
                        <Route exact path="/admin/portal/agc/students/round2" render={(props) => <Round examinationId={1} examGroupId={3} currentRound={2} nextRound={'Round 3'} {...props}/> } />
                        <Route exact path="/admin/portal/agc/students/round3" render={(props) => <Round examinationId={1} examGroupId={4} currentRound={3} nextRound={'Round 4'} {...props}/> } />
                        <Route exact path="/admin/portal/agc/students/round4" render={(props) => <Round examinationId={1} examGroupId={5} currentRound={4} nextRound={'Round 5'} {...props}/> } />
                        <Route exact path="/admin/portal/agc/workshops" component={ Workshops }  />
    
                        {examinationList.map((examination,index) =>(
                            <Route exact path ={adminLogin+examination.examinationURL+"/scheduleBatches"} render={(props)=><ScheduleBatchList examinationId={examination.examinationId}{...props} />}/>
                        ))}

                        {examinationList.map((examination,index) => (
                          <Route exact path ={adminLogin+examination.examinationURL+"/reports"} render={(props)=> <Report examinationId= {examination.examinationId} {...props} /> } />
                         ))}
                         
                        <Route exact path="/admin/portal/prodigy/students/practice" render={(props) => <Round examStatus="practice" examinationId={2} examGroupId={4} currentRound={0} {...props} nextRound={'Prelim Round'} /> } />
                        <Route exact path="/admin/portal/prodigy/students/prelim" render={(props) => <Round  examinationId={2} examGroupId={5} currentRound={1} {...props}  nextRound={'State Round'}/> } />
                        <Route exact path="/admin/portal/prodigy/students/state" render={(props) => <Round  examinationId={2} examGroupId={6} currentRound={2} {...props}  nextRound={'National Round'}/> } />
                        <Route exact path="/admin/portal/prodigy/students/national" render={(props) => <Round  examinationId={2} examGroupId={7} currentRound={4} {...props}  nextRound={'Winners'}/> } />
                        <Route exact path="/admin/portal/prodigy/students/stateWinners" render={(props) => <Round  examinationId={2} examGroupId={6} currentRound={3} {...props} examStatus="State Winners"  nextRound={"State Winners"}/> } />
                        <Route exact path="/admin/portal/prodigy/students/nationalWinners" render={(props) => <Round  examinationId={2} examGroupId={7} currentRound={3} {...props} examStatus="National Winners"  nextRound={"National Winners"}/> } />
                        <Route exact path="/admin/portal/prodigy/students/CSODashboard" render={(props) => <CSODashboard roleId={JSON.parse(localStorage.getItem("sip-user")).roles[0].roleId} {...props} />} />       

                    </Portal>
                </Route>   
                <Route path="/agc/landing">
                    <ContestLandingPage />    
                </Route>
                <Route path="/agc/studentReg">
                    <StudentRegistrationFormNewPage />    
                </Route>
                <Route path="/agc/studentReg">
                    <StudentRegistrationFormNewPage />    
                </Route>
                <Route path="/agc/landing">
                    <ContestLandingPage />    
                </Route>
                <Redirect exact from="/" to="/agc/landing" /> 
                <Redirect exact from="/admin/" to="/admin/login" />                              
            </Switch>
        </Router>
    );
}

export default App;
