import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Grid,
  makeStyles,
  FormHelperText,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import _ from "lodash/fp";
import { useEffect, useState } from "react";
import axios from "axios";
import TagManager from "react-gtm-module";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    paddingBottom: theme.spacing(1),
    width: "261px",
    textAlign:"center",
    color: 'darkslategrey',
  },
  gridItemSchool: {
    paddingBottom: theme.spacing(0.5),
    width: "261px",
    color: "darkslategrey",
    height:'10%'
    // paddingTop: theme.spacing(1),
  },
  gridItemSelect: {
    paddingBottom: theme.spacing(1),
    width: "261px",
    paddingTop: theme.spacing(0.5),
  },
  formControl: {
    "& .MuiFormLabel-root": {
      color: "darkslategrey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "darkslategrey",
      },
    },
    minWidth: "100%",
    textAlign: "left",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      color: "darkslategrey",
    },
  },mobileNumber: {

    '& .MuiFormLabel-root': {
        color: 'darkslategrey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'darkslategrey',
        },
    },
    minWidth: '100%',
    textAlign: 'left',
    '& .MuiFormHelperText-contained': {
        margin: 0,
        color: 'darkslategrey',
    },
    '& input::-webkit-inner-spin-button': {
        display: 'none',
         margin: 0
      }
},
  invalid: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
    },
    minWidth: "100%",
    textAlign: "left",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      display: "none",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: theme.spacing(0),
    },
    paddingTop: theme.spacing(1),
  },
  error: {
    color: "red",
    fontSize: "11px",
  },
}));

function ChildInfo({
  displayText,
  setStudentRegistrationValidation,
  studentRegistration,
  setStudentRegistration,
  setBackButtonValidation,
  setLoginId,
  state,
  setState,
}) {
  const classes = useStyles();
  const { control, register, errors, getValues } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      childName: studentRegistration.childName,
      gender: studentRegistration.gender,
      schoolName: studentRegistration.schoolName.schoolName,
      schoolArea: studentRegistration.schoolName.schoolArea,
      classNumber: studentRegistration.classNumber.name,
      section: studentRegistration.section,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [value, setValue] = React.useState("");
  const studentId = "studentId";
  const childName = "childName";
  const parentName = 'parentName';
  const whatsAppNumber = 'whatsAppNumber';
  const gender = "gender";
  const schoolName = "schoolName";
  const schoolArea = "schoolArea";
  const classNumber = "classNumber";
  const section = "section";
  const branches = 'branch';
  const fullbranchName='fullbranchName';
  const [classValue, setClassValue] = React.useState("");
  const [schoolNameList, setSchoolNameList] = useState([]);
  const [classNameList, setClassNameList] = useState([]);
  const [stateValue, setStateValue] = React.useState('');
  const states = 'stateOfResidence';
  const [stateList, setStateList] = useState([]);
  const [stateID, setStateID] = useState('');
  const [branchList, setBranchList] = useState([]);
  const [branchValue, setBranchValue] = React.useState('');
  const [selectedSchoolName,setSelectedSchoolName] =React.useState('')
  const [selectedSchoolCode,setSelectedSchoolCode] =React.useState('')
  const [selectedBranchName,setSelectedBranchName] =React.useState('')
  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const username = "admin";
  const password = "admin";

  const [schoolId, setSchoolId] = useState("");

  const loadStateData = () => {
  
    setStateList([{stateName:'Loading...'}]);
    axios.get(BASE_URL + "/states",
        {
            params: {
                body: JSON.stringify({stateName: "",active:true,stateCode:"AP"}),
            },
            headers: {
                authorization: 'Basic ' + window.btoa(username + ":" + password)
            }
        }).then((response) => {
        
            setStateList(response.data.content);

        });
       
}

  const loadSchoolNameData = (value) => {
    //setSchoolNameList([{schoolCode:" ", schoolName: "Loading..." }]);
    axios
      .get(BASE_URL + "/schoolName", {
        params: {
          body: JSON.stringify({schoolCode:value,active:true,approved:true,state:{
            stateId:(stateValue != null && stateValue.stateId != null )? stateValue.stateId:0} }),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setSchoolNameList(response.data.content)
        if(response.data.content.length > 0){
        setSelectedSchoolName(response.data.content[0].schoolName)
        setStudentRegistration({
          ...studentRegistration,
          [schoolName]: {
            schoolName: response.data.content[0].schoolName,
            schoolId: response.data.content[0].schoolId,
            schoolNameDisp:response.data.content[0].schoolNameDisp,
            schoolArea: response.data.content[0].schoolArea,

          },
          schoolNameVerified: response.data.content[0].approved,
        });
        }else{
          setSelectedSchoolName('')
                    
            setStudentRegistration({
              ...studentRegistration,
              [schoolName]: "",
              schoolArea:'',
              schoolNameVerified :false,

              
            });
          
        }
      
      });
  };

  const loadSchoolName =value=>{
    setSelectedSchoolCode(value)
    console.log(selectedSchoolCode);
    loadSchoolNameData(value)
  }

  const schoolNameData = schoolNameList.map((schoolName) => {
    return {
      schoolName: schoolName.schoolName,
      schoolId: schoolName.schoolId,
      schoolArea: schoolName.schoolArea,
      schoolNameDisp :schoolName.schoolNameDisp,
      approved :schoolName.approved,
      schoolCode:schoolName.schoolCode
    };
  });

  const stateData = stateList.map((state) => { 
    return {stateName: state.stateName, stateId: state.stateId}
})

const branchData = branchList.map((branch) => {    
  return {name: branch.name, branchId: branch.branchId , code:branch.code,branchNameDisp:branch.branchNameDisp,organization:branch.organization}
})
const loadBranchData = (value) => {
  
  setBranchList([{branchNameDisp:'Loading...'}]);
  axios.get(BASE_URL + "/branches",
      {
          params: {
              body: JSON.stringify({
                  name: "",
                  branchId: 0,
                  code:value,
                  address: {
                      countryId:1,
                    //  stateId:stateID,
                      //cityId:cityID,
                 }  
              }),
          },
          
      }).then((response) => {
      
          setBranchList(response.data.content);
          if(response.data.content.length > 0){
            setSelectedBranchName(response.data.content[0].name)
            setStudentRegistration({
              ...studentRegistration,
              [branches]: {
                name: response.data.content[0].name,
                branchId: response.data.content[0].branchId,
                code:response.data.content[0].code,
                branchNameDisp:response.data.content[0].branchNameDisp,
                organization:response.data.content[0].organization,
                address:response.data.content[0].address
              }
             
            });
          }else{
            setSelectedBranchName('');
            setStudentRegistration({ ...studentRegistration, [branches]: '' });
          }
        
      });
     
}

  const loadClassData = () => {
    setClassNameList([{ name: "Loading..." }]);
    axios
      .get(BASE_URL + "/classes", {
        params: {
          body: JSON.stringify({ name: "", examination: { examinationId: 1 }, 
          stateId:studentRegistration.stateOfResidence === ""? 1: studentRegistration.stateOfResidence.stateId }),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setClassNameList(response.data.content);
      });
  };

  const classData = classNameList.map((className) => {
    return { name: className.name, classId: className.classId };
  });
  const [alert, setAlert] = React.useState(false);
  const autoSave = () => {
    if (getValues(childName)) {
      studentRegistration.childName = getValues(childName)
        .toLowerCase()
        .replace(/[^A-Za-z0-9.,;'"&()#-]/g, " ")
        .split(" ")
        .reduce((result, word) => result + " " + capitalize(word.toLowerCase()))
        .replace(/^./, function (str) {
          return str.toUpperCase();
        });
    }

    if (studentRegistration.childName.length > 2) {
      studentRegistration.studentId = `${
        studentRegistration.studentId !== 0 ? studentRegistration.studentId : 0
      }`;
      axios
        .post(BASE_URL + "/students", studentRegistration, {
          headers: {
            authorization: "Basic " + window.btoa(username + ":" + password),
          },
        })
        .then(function (response) {
          if (response.data.studentId === 0) {
            setAlert(true);
            setState({
              ...state,
              open: true,
              message: `${response.data.childName} is already registered with this mobile number or email id.`,
              severity: "error",
            });
          } else if (response.data.studentId===-1){
            setAlert(true);
            setState({ ...state, open: true, message: `Two or more students are already registered with this mobile no ${getValues('whatsAppNumber')}.`, severity: "error" ,timeout: 2000});
        
        }else if (response.data.studentId > 0) {
            setStudentRegistration({
              ...studentRegistration,
              [studentId]: response.data.studentId,
            });
            localStorage.setItem('studentId',response.data.studentId);
            setState({ open: false });
            setAlert(false);
          }
        })
        .catch(function (error) {});
    }
  };

  const fieldsLabels = {
    childName: (
      <span>
        Name of Child <span style={{ color: "red" }}>*</span>
      </span>
    ),
   // gender: (
   //   <span>
    //    Gender <span style={{ color: "red" }}>*</span>
  //    </span>
  //  ),
   
    classNumber: (
      <span>
        Class <span style={{ color: "red" }}>*</span>
      </span>
    ),
    section: (
      <span>
        Section/Division 
      </span>
    ), schoolCode: (
      <span>
        School Code <span style={{ color: "red" }}>*</span>
      </span>
    ),
   schoolName: (
     <span>
        School Name 
      </span>
    ),
   //N state: <span>State Of Residence <span style={{ color: "red" }} >*</span></span>,
   parentName: <span>Name of Parent <span style={{ color: "red" }} >*</span></span>,
   whatsAppNumber: <span>WhatsApp No. <span style={{ color: "red" }} >*</span></span>,
   branch: <span>Exam Center Code <span style={{ color: "red" }} >*</span></span>,
   branchName: <span>Exam Center Name </span>
  };

  useEffect(() => {
    
    if (
      classValue === null ||
      value === null ||
      (errors.section && errors.section.type === "maxLength")
    ) {
      setBackButtonValidation(true);
    } else {
      setBackButtonValidation(false);
    }

    if (state.open) {
      setAlert(true);
    } else {
      setAlert(false);
    }

    if (
      !errors.childName &&
      getValues(childName) &&
       !errors.parentName && getValues(parentName)&&
      (!errors.whatsAppNumber && studentRegistration.whatsAppNumber && getValues(whatsAppNumber)) &&
     (studentRegistration.branch.name)   &&
      studentRegistration.schoolName.schoolName &&
      !errors.classNumber &&
      getValues(classNumber) 
    ) {
      setStudentRegistrationValidation(true);
    } else {
      setStudentRegistrationValidation(false);
    }
  });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PageView",
        pagePath: "/registration/childInfo",
        pageTitle: "ChildInfo",
      },
    });
  }, []);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container justify="center" alignItems="stretch">
        <form>
          <Grid item xs={12} className={classes.gridItem}>
            {displayText}
            <TextField
              className={
                (errors.childName && classes.invalid) || classes.formControl
              }
              size="small"
              margin="dense"
              variant="outlined"
              id="childName"
              label={fieldsLabels.childName}
              name="childName"
              helperText={`Please enter the child name`}
              defaultValue=""
           // onChange={autoSave}
           onChange={(() => {
            setStudentRegistration({
                ...studentRegistration, [childName]: getValues(childName).toLowerCase().replace(/[^A-Za-z0-9.,;'"&()#-]/g, ' ').split(' ')
                .reduce((result, word) => result +' '+ capitalize(word.toLowerCase()))
                .replace(/^./, function(str){ return str.toUpperCase();  })
    
        })} 
        )}
              inputRef={register({
                required: true,
                maxLength: 100,
                pattern: /^[A-Z0-9.,;'"&()#-\s]{0,}$/i,
              })}
            />
            {_.get("childName.type", errors) === "required" && (
              <FormHelperText className={classes.error}>
                Child name is required
              </FormHelperText>
            )}
            {_.get("childName.type", errors) === "maxLength" && (
              <FormHelperText className={classes.error}>
                Child name cannot exceed 100 characters
              </FormHelperText>
            )}
            {_.get("childName.type", errors) === "pattern" && (
              <FormHelperText className={classes.error}>
                Please enter valid name
              </FormHelperText>
            )}
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="stretch"
            style={{ marginTop: 10 }}
          >
            <Grid item xs={12} className={classes.gridItem}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    value={studentRegistration.classNumber.name || null}
                    onChange={(event, newValue) => {
                      props.onChange(newValue);
                      if (typeof newValue === "string") {
                        setClassValue({
                          newValue,
                        });
                        if (newValue != null) {
                          setStudentRegistration({
                            ...studentRegistration,
                            [classNumber]: newValue,
                          });
                        }
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input

                        setClassValue({
                          title: newValue.inputValue,
                        });

                        if (newValue != null) {
                          setStudentRegistration({
                            ...studentRegistration,
                            [classNumber]: newValue.inputValue,
                          });
                        }
                      } else if (newValue != null) {
                        setClassValue(newValue);
                        if (newValue) {
                          setStudentRegistration({
                            ...studentRegistration,
                            [classNumber]: newValue,
                          });
                        }
                      } else {
                        setClassValue(newValue);
                        setStudentRegistration({
                          ...studentRegistration,
                          [classNumber]: "",
                        });
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onOpen={loadClassData}
                    options={classData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }

                      return option.name;
                    }}
                    getOptionSelected={(option) =>
                      option.value === classNameList.className
                    }
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={
                          (errors.classNumber && classes.invalid) ||
                          classes.formControl
                        }
                        name="classNumber"
                        size="small"
                        label={fieldsLabels.classNumber}
                        variant="outlined"
                        helperText="Please select the class"
                      />
                    )}
                  />
                )}
                rules={{ required: true }}
                name="classNumber"
                control={control}
                defaultValue={{
                  name: studentRegistration.classNumber.name,
                  classId: studentRegistration.classNumber.classId,
                }}
              />

              {_.get("classNumber.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the class
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          
          <Grid item xs={12} className={classes.gridItem}>
          <TextField
       disabled={false}
        //variant="filled"
        className={classes.formControl}
        size="small"
        margin="dense"
        autoComplete='off'
        variant="outlined"
        id="schoolName"
        label={fieldsLabels.schoolCode}
        name="schoolName"
        onChange={(item)=>item.target.value.length >= 4 ? loadSchoolName(item.target.value) : setSelectedSchoolName('')
        
        
    }
        inputRef={register({
          required: true,
          maxLength: 100,
          pattern:/^[A-Z0-9.,;'"&()#-\s]{0,}$/i
      })}
        />
          {_.get("schoolName.type", errors) === "required" && (
              <FormHelperText className={classes.error}>
                School code is required
              </FormHelperText>
            )}
            {_.get("schoolName.type", errors) === "maxLength" ||
              (studentRegistration.schoolName.schoolName >= 100 && (
                <FormHelperText className={classes.error}>
                  School code cannot exceed 200 characters
                </FormHelperText>
              ))}
     </Grid>


   
          <Grid item xs={12} className={classes.gridItem}>
          <TextField 
       disabled={false}
        //variant="filled"
        className={classes.formControl}
        size="small"
        margin="dense"
        autoComplete='off'
        variant="outlined"
        id="filled-read-only-input"
       // label={fieldsLabels.schoolName}
        name="fullschoolName"
        value={selectedSchoolName}
        multiline='true'
        InputProps={{
          readOnly: true,
        }}
        helperText='Selected school name'
        
       
/>
     </Grid>

          <Grid item xs={12} className={classes.gridItem} >


<TextField
disabled={false}
//variant="filled"
    className={(errors.parentName && classes.invalid) || classes.formControl}
    size="small"
    margin="dense"
    autoComplete='off'
    variant="outlined"
    id="standard-helperText"
    label={fieldsLabels.parentName}
    name="parentName"
    onChange={(() => {
        setStudentRegistration({
            ...studentRegistration, [parentName]: getValues(parentName).toLowerCase().replace(/[^A-Za-z0-9.,;'"&()#-]/g, ' ').split(' ')
            .reduce((result, word) => result +' '+ capitalize(word.toLowerCase()))
            .replace(/^./, function(str){ return str.toUpperCase();  })

    })} 
    )}
    defaultValue=""
    helperText={'Please enter your name'}
    inputRef={register({
        required: true,
        maxLength: 100,
        pattern:/^[A-Z0-9.,;'"&()#-\s]{0,}$/i
    })}
/>
{_.get("parentName.type", errors) === "required" && (
    <FormHelperText className={classes.error}>Name is required</FormHelperText>
)}
{_.get("parentName.type", errors) === "maxLength" && (
    <FormHelperText className={classes.error}>Name cannot exceed 100 characters</FormHelperText>
)}
{_.get("parentName.type", errors) === "pattern" && (
    <FormHelperText className={classes.error}>Please enter valid name</FormHelperText>
)}
</Grid>

<Grid item xs={12} >
                        <TextField
                         disabled={false}
                        // variant="filled"
                            className={classes.mobileNumber}
                            size="small"
                            margin="dense"
                            variant="outlined"
                            id="whatsAppNumber"
                            autoComplete='off'
                            label={fieldsLabels.whatsAppNumber}
                            name="whatsAppNumber"
                            onChange={(() => {
                                setStudentRegistration({ ...studentRegistration, [whatsAppNumber]: getValues(whatsAppNumber) });
                            })}
                            defaultValue=""
                            inputRef={register({
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                            })}
                            helperText={`Please enter your whatsApp number`}
                        />
                         {_.get("whatsAppNumber.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>WhatsApp number is required</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>WhatsApp Number cannot exceed 10 numbers</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "minLength" && (
                            <FormHelperText className={classes.error}>WhatsApp Number cannot be less than 10 numbers</FormHelperText>
                        )}
                        {_.get("whatsAppNumber.type", errors) === "pattern" && (
                            <FormHelperText className={classes.error}>Please enter valid WhatsApp number,Please double check and ensure Mobile number is right as we will send Exam Login ID & Password to this number</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} className={classes.gridItem} >


<TextField
disabled={false}
//variant="filled"
    className={(errors.branch && classes.invalid) || classes.formControl}
    size="small"
    margin="dense"
    autoComplete='off'
    variant="outlined"
    id="standard-helperText"
    label={fieldsLabels.branch}
    name="branch"
    onChange={(item)=>item.target.value.length >= 4 ? loadBranchData(item.target.value) : setSelectedBranchName('')}
    helperText={'Please enter code'}
    inputRef={register({
        required: true,
        maxLength: 100,
        pattern:/^[A-Z0-9.,;'"&()#-\s]{0,}$/i
    })}
/>
{_.get("branch.type", errors) === "required" && (
    <FormHelperText className={classes.error}>code is required</FormHelperText>
)}
{_.get("branch.type", errors) === "maxLength" && (
    <FormHelperText className={classes.error}>Name cannot exceed 100 characters</FormHelperText>
)}
{_.get("branch.type", errors) === "pattern" && (
    <FormHelperText className={classes.error}>Please enter valid code</FormHelperText>
)}
</Grid>  
                   
          <Grid item xs={12} className={classes.gridItem}>
          <TextField 
       disabled={false}
        //variant="filled"
        className={classes.formControl}
        size="small"
        margin="dense"
        autoComplete='off'
        variant="outlined"
        id="filled-read-only-input"
        multiline='true'
        name="fullbranchName"
        value={selectedBranchName != 'undefined' ? selectedBranchName : ""}
        InputProps={{
          readOnly: true,
        }}
        helperText='Selected Branch name'
/>
     </Grid>
        </form>
      </Grid>
    </div>
  );
}

export default ChildInfo;
