import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory } from 'react-router-dom';
import Copyright from '../../Common/Copyright';
import { useForm } from 'react-hook-form';
import { Snackbar, Grid, Paper, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import jwt_decode from "jwt-decode";


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/olav-ahrens-rotne-SiDXdjE-T_k-unsplash.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const Login = () => {

  const classes = useStyles();
  const history = useHistory();
  const [examinationList,setExaminationList] = React.useState([]);
  const { register, handleSubmit, errors } = useForm();
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message:'',
    severity:''
  });
  
const { vertical, horizontal, open, message, severity } = state;
const [isLoginSuccess, setIsLoginSuccess] = useState(false);
const handleClose = () => {
setState({ ...state, open: false });
};

  useEffect(() => {
    if(isLoginSuccess) {
      if(examinationList.includes('agc')){
        history.push('/admin/portal/agc/dashboard');
      }else {
        history.push('/admin/portal/prodigy/students/practice');
      }
    }
  }, [isLoginSuccess]);

  const onSubmit = data => {
    axios.post(BASE_URL + "/authenticate/admin", data)
        .then((response) => {
            localStorage.setItem("sip-authorization", response.data.token);
            var decodedJwt = jwt_decode(response.data.token);
            setExaminationList(decodedJwt.examination);
            return axios.get(BASE_URL + "/organizations/1/branches/" + decodedJwt.brn + "/users/" + decodedJwt.uid, {
                headers: {
                    'Authorization':'Bearer ' + response.data.token
                }
            })
        }).then((response) => {
            setState({ ...state, open: true, message:'Login successful, redirecting to Dashboard!', severity:"success" });
            localStorage.setItem("sip-user", JSON.stringify(response.data));
            setIsLoginSuccess(true);
        })
        .catch((error) => {
            setState({ ...state, open: true, message:'Login failed, Invalid Login ID or Password!', severity:"error" });
        })
  }

  return (
    <Grid container component="main" className={classes.root}  >
      <CssBaseline />
      <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleClose} severity={severity}>
                 {message}
                </Alert>
            </Snackbar>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
           <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="branchCode"
              label="Branch Code"
              name="branchCode"
              autoFocus
              inputRef={register({ required: false})}
              error={errors.branchCode ? true: false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="User ID"
              name="username"
              inputRef={register({ required: true, minLength:5 })}
              error={errors.username ? true: false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              inputRef={register({ required: true, minLength:4 })}
              error={errors.password ? true: false}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
            <Box mt={5}>
              <Copyright companyName={"EaziBiz Technologies."} companyURL={"https://eazibiztech.com/"}/>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;