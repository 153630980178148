import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import axios from 'axios';
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { makeStyles, Grid, Typography, CssBaseline } from '@material-ui/core';
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AutoLogin() {

    const classes = useStyles();
    const query = useQuery();
    const [authStatus, setAuthStatus] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const BASE_URL = `${process.env.REACT_APP_API_URL}`;

    useEffect(() => {
        const authToken = query.get("authToken");
        if (authToken != null && authToken !== "") {
            axios.post(BASE_URL + "/validate/admin", "", {
                headers: {
                    'Authorization': 'Bearer ' + authToken
                }
            }).then(()=>{
                localStorage.setItem("sip-authorization", authToken);
                var decodedJwt = jwt_decode(authToken);
                return axios.get(BASE_URL + "/organizations/1/branches/" + decodedJwt.brn + "/users/" + decodedJwt.uid, {
                  headers: {
                    'Authorization':'Bearer ' + authToken
                  }
                })
            })
            .then((response) => {
                localStorage.setItem("sip-user", JSON.stringify(response.data));
                setAuthStatus(true);
                setLoadingStatus(false);
            }).catch((error) => {
                localStorage.removeItem("sip-authorization");
                setAuthStatus(false);
                setLoadingStatus(false);
            }
            )
        }
    }, []);

    return (
        <div className={classes.root}>
            {loadingStatus ?
                <React.Fragment>
                    <CssBaseline/>
                    <Grid container  direction="column" alignItems="center" justify="center" spacing={10} >
                        <Grid item xs={12}>
                            <Typography variant="h6">Please wait while we authenticate...</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ClimbingBoxLoader
                                size={50}
                                color={"Orange"}
                                loading={true}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment> :
                <React.Fragment>
                    {authStatus ?
                        <Redirect to="/admin/portal" from="/admin/login"/> :
                        <React.Fragment>
                            <Grid container direction="column" alignItems="center" justify="center" spacing={0} style={{ minHeight: "100vh" }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Auto Login Failed...</Typography>
                                    <Typography variant="h5">Please contact your system administrator.</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>}
                </React.Fragment>}
        </div>
    );
}

export default AutoLogin;