import React, { useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
  List,
  ListItemText,
  Button,
  CssBaseline,
  Typography,
  ListItemIcon,
  ListItem,
  AppBar
} from "@material-ui/core";

import backgroundImage from "./images/BG_Page_02.svg";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/sip-academy-logo1.png";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";

import agcDoll from "./images/DOLL_P2.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import mobileBG from "./images/mobileBG.svg";
//import backgroundImage2022 from  "./images/BG_Page_2022.png"
import mobileBG2022 from "./images/Arithmetic_Genius_Contest_Mobile_BG.jpg";
import backgroundImage2022 from "./images/BG_2022_AGC.jpg";
import agc2023mobileLogo from "./images/Bg_2023_mobile.png";
import agc2023WebLogo from "./images/Bg_2023_web.png";
import agc2024mobileLogo from "./images/Bg_2024_mobile.png";
import agc2024WebLogo from "./images/Bg_2024_web.png";

const useStyles = makeStyles(theme => ({
  AGCRoot: {
    flexGrow: 1,
    alignContent: "stretch",
    wrap: "nowrap",
    overflow: "hidden",
    heigh: "100%"
  },
  paper: {
    width: "180%",
    height: "125vh",
    backgroundImage: `url(${agc2024WebLogo})`,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  container: {
    textAlign: "center"
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center"
  },
  titleDeskTop: {
    textAlign: "center",
    fontSize: theme.spacing(3),
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "20px",
    // height:"90%",
    padding: "3px"
    //height:theme.spacing(6)
  },
  titleMobile: {
    textAlign: "center",
    fontSize: theme.spacing(2),
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "20px",
    // height:"90%",
    padding: "3px"
    //height:theme.spacing(6)
  },
  mobilePaper: {
    height: "auto",
    backgroundImage: `url(${agc2024mobileLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none"
  },
  iconColor: {
    color: "yellow",
    fontSize: "75%"
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "##000000"
  },
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    height: "85%",
    whiteSpace: "pre-line",
    display: "inline-block",
    fontSize: theme.spacing(2),
    padding: theme.spacing(3)
  }
}));

const AboutAGC = () => {
  const classes = useStyles();
  let theme = useTheme();
  const title = "Add excitement to Arithmetic";
  const register = "Sample Question Paper";
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function Agc2021Content() {
    if (smallScreen) {
      //code for small screen
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.text}>
                      The ability to make friends with numbers is a crucial
                      skill that is vital for academic and career success.​
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.text}>
                      We are happy to present an opportunity for young children
                      to build a solid foundation in the basics of mathematics
                      and win early recognition for their talents.
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography className={classes.text}>
                      {" "}
                      The{" "}
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        Arithmetic Genius Contest
                      </span>{" "}
                      is a major competitive event with participants from all
                      over the state, and will excite parents and students
                      alike. The contest is designed to focus the spotlight on
                      arithmetic and increase involvement and interest in it.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/*<img src={agcDoll} alt="doll" />*/}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      //code for large screen
      return (
        <React.Fragment>
          <Grid
            item
            container
            direction="row"
            style={{
              marginBottom: theme.spacing(7),
              marginTop: theme.spacing(7)
            }}
          >
            <Grid
              item
              container
              md={3}
              lg={3}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              {/* <img src={agcDoll} alt="doll" height={'200vh'} width={"300vw"} /> */}
            </Grid>

            <Grid item md={6} lg={6}>
              <List>
                <ListItem>
                  <br></br>
                  <br></br>
                  <div>
                    <Typography className={classes.text}>
                      The ability to make friends with numbers is a crucial
                      skill that is vital for academic and career success. ​
                    </Typography>
                  </div>
                </ListItem>
                <div>
                  <Typography className={classes.text}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;We are happy to present an
                    opportunity for young children to build a solid foundation
                    in the basics of mathematics and win early recognition for
                    their talents.
                  </Typography>
                </div>
                <br></br>
                <div>
                  <Typography className={classes.text}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;The{" "}
                    <span style={{ fontWeight: "bold", color: "red" }}>
                      Arithmetic Genius Contest
                    </span>{" "}
                    is a major competitive event with participants from all over
                    the state, and will excite parents and students alike. The
                    contest is designed to focus the spotlight on arithmetic and
                    increase involvement and interest in it.
                  </Typography>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </List>
            </Grid>
            <Grid item md={3} lg={3}></Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Paper className={smallScreen ? classes.mobilePaper : classes.paper}>
          <Grid container item md={12} xs={12}>
            <Grid item container md={12} xs={12} lg={12} sm={12}>
              <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3}></Grid>
                <Grid item md={4} xs={9} sm={9} lg={4}>
                  <img
                    src={sipAbacusLogo}
                    alt="logo"
                    height={smallScreen ? "60%" : "50%"}
                    width={smallScreen ? "60%" : "30%"}
                  />
                </Grid>
                <Grid item md={5} xs={0} sm={0} lg={5}></Grid>
              </AppBar>
            </Grid>

            <Grid item container md={12} xs={12} lg={12} sm={12}>
              <Grid item md={1} xs={1} sm={1} lg={1}></Grid>
              <Grid
                item
                md={2}
                xs={4}
                sm={4}
                lg={2}
                className={classes.container}
                justifyContent="flex-start"
              >
                <img
                  src={agcLogo}
                  alt="Logo"
                  style={{ height: smallScreen ? "80%" : "60%", width: "60%" }}
                />
              </Grid>
              <Grid item md={6} xs={1} sm={1} lg={6}></Grid>

              <Grid item md={3} xs={6} sm={6} lg={3}>
                {smallScreen ? (
                  ""
                ) : (
                  <div>
                    <br></br>
                    <br></br>
                    <br></br> <br></br>
                    <br></br>{" "}
                  </div>
                )}

                <Grid container style={{ marginTop: theme.spacing(3) }}>
                  <Grid
                    item
                    md={3}
                    xs={2}
                    sm={2}
                    lg={3}
                    alignContent="flex-end"
                  ></Grid>

                  <Grid item md={6} xs={8} sm={8} lg={6}>
                    <a
                      href="#sampleQuestionPaper"
                      className={classes.registrationPageLink}
                    >
                      <Button
                        style={{
                          width: "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                      >
                        {register}
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={12}
              xs={12}
              lg={12}
              sm={12}
              style={{ marginTop: -theme.spacing(7) }}
            >
              <Grid item md={4} xs={1} lg={3} sm={1}>
                {" "}
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                lg={1}
                sm={1}
                style={{ alignContent: "flex-end" }}
              >
                <img
                  src={plusLeft}
                  alt="plus"
                  height={smallScreen ? "50%" : "40%"}
                  width={smallScreen ? "50%" : "35%"}
                  style={{
                    marginTop: smallScreen
                      ? theme.spacing(4)
                      : theme.spacing(7),
                    marginLeft: smallScreen
                      ? theme.spacing(0)
                      : theme.spacing(8)
                  }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={8}
                lg={3}
                sm={8}
                className={
                  smallScreen ? classes.titleMobile : classes.titleDeskTop
                }
                style={{ marginTop: smallScreen ? theme.spacing(8) : "5%" }}
              >
                {title}
              </Grid>

              <Grid item md={1} xs={1} lg={1} sm={1}>
                <img
                  src={plusRight}
                  alt="plus"
                  height={smallScreen ? "50%" : "40%"}
                  width={smallScreen ? "50%" : "35%"}
                  style={{
                    marginTop: smallScreen
                      ? theme.spacing(4)
                      : theme.spacing(7),
                    marginLeft: smallScreen
                      ? theme.spacing(0)
                      : theme.spacing(1)
                  }}
                />
              </Grid>
              <Grid item md={3} xs={1} lg={3} sm={1}></Grid>
            </Grid>

            <Grid
              item
              container
              md={12}
              xs={12}
              lg={12}
              sm={12}
              style={{
                marginTop: smallScreen ? theme.spacing(0) : -theme.spacing(0)
              }}
            >
              {/* call the fragment */}
              <Agc2021Content></Agc2021Content>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default AboutAGC;
