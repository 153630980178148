import React from 'react';
import { useForm ,Controller} from 'react-hook-form';
import { TextField, Grid, makeStyles, FormHelperText,  CssBaseline, FormControl, InputLabel, Select, MenuItem
 } from '@material-ui/core';
import _ from "lodash/fp";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useState,useEffect} from "react";
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import Captha from './Captha';
import Alert from '@material-ui/lab/Alert';
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    gridItem: {
        paddingBottom: theme.spacing(2),
        width: '261px',
        textAlign:"center",
        color: 'darkslategrey',
    },
    captcha: {
        paddingBottom: theme.spacing(.4),
        width: '261px',
        textAlign:"center",
        color: 'darkslategrey',
    },
    formControl: {
        '& .MuiFormLabel-root':{
            color: 'darkslategrey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'darkslategrey',
            },
        },
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            color: 'darkslategrey',
        }
    },
    invalid: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
        },
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            display: 'none'

        }

    },
    error: {
        color: "red",
        fontSize: "11px"
    },
}));



function CommunicationInfo({ displayText ,setStudentRegistrationValidation,
     studentRegistration, setStudentRegistration,otherSourceSelected, setOtherSourceSelected,OtherSource,setBackButtonValidation,
     setCaptha,refreshCaptha}) {
    const classes = useStyles();
    const { control, register, errors, getValues } = useForm(
        {
            mode: 'all',
            reValidateMode: 'onSubmit',
            defaultValues: {
                state: {stateName:studentRegistration.state.stateName ,stateId:studentRegistration.state.stateId },
                city: {cityName:studentRegistration.city.cityName ,cityId:studentRegistration.city.cityId },
                branch: {name:studentRegistration.branch.name ,branchId:studentRegistration.branch.branchId },
                source: studentRegistration.source,
                abacusStudent: studentRegistration.abacusStudent,
            },
            
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        }
    );
    const [stateValue, setStateValue] = React.useState('');
    const [cityValue, setCityValue] = React.useState('');
    const [branchValue, setBranchValue] = React.useState('');
    const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    const source = 'source';
    const states = 'state';
    const cities = 'city';
    const branches = 'branch';
    // const [state, setState] = React.useState({
    //     open: false,
    //     // vertical: 'top',
    //     // horizontal: 'center',
    //     message: '',
    //     severity: 'error'
    // });
    //const abacusStudent = 'abacusStudent';

    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const username = 'admin';
    const password = 'admin';

    useEffect(() => {
        if ((stateValue===null) || cityValue===null || branchValue===null || errors.source){
            setBackButtonValidation(true)
        }else{
            setBackButtonValidation(false)
        }

        if ((studentRegistration.state.stateName) && ( studentRegistration.city.cityName)
            && (studentRegistration.branch.name) && (!errors.source && getValues(source))) {
            setStudentRegistrationValidation(true);
                  } else {
            setStudentRegistrationValidation(false)  
        }
    });
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [stateID, setStateID] = useState('');
    const [cityID, setCityID] = useState('');
  
    const loadStateData = () => {
  
        setStateList([{stateName:'Loading...'}]);
        axios.get(BASE_URL + "/states",
            {
                params: {
                    body: JSON.stringify({stateName: "",active:true}),
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((response) => {
            
                setStateList(response.data.content);
  
            });
           
    }

    const loadBranchData = () => {
  
        setBranchList([{name:'Loading...'}]);
        axios.get(BASE_URL + "/branches",
            {
                params: {
                    body: JSON.stringify({
                        name: "",
                        branchId: 0,
                        address: {
                            countryId:1,
                            stateId:stateID,
                            cityId:cityID,
                        }  
                    }),
                },
                
            }).then((response) => {
            
                setBranchList(response.data.content);
  
            });
           
    }

    const loadCityData = () => {

        setCityList([{cityName:'Loading...'}]);
            axios.get(BASE_URL + '/cities',
            {
                params: {
                    body: JSON.stringify({cityName: "",
                                         countryCombo:{
                                          countryId:1,  
                                         },
                                         stateCombo:{                      
                                          stateId:stateID,
                                        }
                                     }),
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((response) => {
                setCityList(response.data.content);
  
            });          
    
    }
    const cityData = cityList.map((city) => {    
        return {cityName: city.cityName, cityId: city.cityId}
    })
    
    const stateData = stateList.map((state) => { 
        return {stateName: state.stateName, stateId: state.stateId}
    })

    const branchData = branchList.map((branch) => {    
        return {name: branch.name, branchId: branch.branchId}
    })

    const fieldsLabels = { 
        state: <span>State <span style={{ color: "red" }} >*</span></span>,
        city: <span>City/Town <span style={{ color: "red" }} >*</span></span>,
        branch: <span>Branch <span style={{ color: "red" }} >*</span></span>,
        source: <span>How did you know about AGC? <span style={{ color: "red" }} >*</span></span>,
        other: <span>Other <span style={{ color: "red" }} >*</span></span>,

    }

    useEffect(()=>{
        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: '/registration/communicationInfo',
                pageTitle: 'CommunicationInfo',
            }
        });
    },[]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <div style={{textAlign:"center",color:"darkslategrey",fontSize:"12px"}}> {displayText}  </div> 

            <Grid container justify="center" alignItems="stretch" style={{ marginTop: 10 }}  >
                <form >

                    <Grid item xs={12} className={classes.gridItem} >

                    <Controller
                            render={props => (
                                <Autocomplete
                                    
                                    value={studentRegistration.state.stateName || null}

                                    onChange={(event, newValue) => {
                                        
                                        props.onChange(newValue)
                                        studentRegistration.city ={cityName:'' ,cityId:1 }
                                        studentRegistration.branch ={name:'' ,branchId:1 }
                                         
                                        if (typeof newValue === 'string') {

                                            setStateValue({
                                                newValue,
                                            });

                                            if(newValue!=null){
                                                setStateID(newValue.stateId)
                                            setStudentRegistration({ ...studentRegistration, [states]: newValue });
                                        }
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            
                                            setStateValue({
                                                title: newValue.inputValue,
                                            });
                                            if(newValue!=null){
                                                setStateID(newValue.stateId)
                                            setStudentRegistration({ ...studentRegistration, [states]: newValue.inputValue });
                                            }

                                        }
                                        else if (newValue!=null) {
                                            setStateValue(newValue)
                                            if(newValue){
                                                setStateID(newValue.stateId)
                                            setStudentRegistration({ ...studentRegistration, [states]: newValue });
                                            }
                                        } else {
                                            setStateValue(newValue)
                                           setStateID(1)
                                            setStudentRegistration({ ...studentRegistration, [states]: '' });
                                        }
                                        setCityList([]);
                                        setBranchList([]);
                                    }}
                                    
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    onOpen ={loadStateData}
                                    options={stateData}
                                    getOptionLabel={(option) => {
                                        
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                      
                                        return option.stateName;
                                    }}
                                     
                                    getOptionSelected={(option) => option.value === stateList.stateName}
                                    renderOption={(option) => option.stateName}


                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className={(errors.state && classes.invalid) || classes.formControl}
                                            name="state"
                                            size='small'
                                            label={fieldsLabels.state}
                                            variant="outlined"
                                            helperText="Please select the state"
                                        />
                                    )}
                                
                                />
                            )}
                           
                            rules={{ required: true }}
                            name="state"
                            control={control}
                            defaultValue={{ name: studentRegistration.state.stateName, schoolId: studentRegistration.state.stateId }}
                        />

                           {_.get("state.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Please select the state</FormHelperText>
                        )}
                       
   
                    </Grid>


                    <Grid item xs={12} className={classes.gridItem}>
                    <Controller
                           render={props => (    
      <Autocomplete
      onChange={(event, newValue) => {
        props.onChange(newValue)
        studentRegistration.branch ={name:'' ,branchId:1 }
        if (typeof newValue === 'string') {
            
            if(newValue!=null){
                setCityValue()({
                    newValue,
                });
                setCityID(newValue.cityId)
            setStudentRegistration({ ...studentRegistration, [cities]: newValue });
        }
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setCityValue({
                title: newValue.inputValue,
            });
            if(newValue!=null){
                setCityID(newValue.cityId)
            setStudentRegistration({ ...studentRegistration, [cities]: newValue.inputValue });
            }

        }
        else if (newValue!=null) {
            setCityValue(newValue);
            if(newValue){
                setCityID(newValue.cityId)
            setStudentRegistration({ ...studentRegistration, [cities]: newValue });
            }
        } else {
            setCityValue(newValue);
           setCityID(1)
            setStudentRegistration({ ...studentRegistration, [cities]: '' });
        }
        setBranchList([]);
    }}

      value={studentRegistration.city.cityName || null}
      onOpen ={loadCityData}
      options={cityData}
      getOptionLabel={(option) => {
        
         if (typeof option === 'string') {
             return option;
         }
      
         return option.cityName;
     }} 
      getOptionSelected ={(option) => option.value === cityList.cityName}
      renderOption={(option) => option.cityName}
      renderInput={(params) => <TextField {...params} 
     
    
    name="city"
      className={(errors.city && classes.invalid) || classes.formControl}
      label={fieldsLabels.city} helperText='Please select the nearest city/town' size='small' variant="outlined" />}
      
    />
                           )}
                           name="city"
                           control={control}
                           rules={{required:true}}
                           defaultValue={{cityName:studentRegistration.city.cityName ,cityId:studentRegistration.city.cityId }}
                       
                           />

                           {_.get("city.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Please select the city</FormHelperText>
                        )}
                      
      
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem} >
                    <Controller
                           render={props => (    
      <Autocomplete

      onChange={(event, newValue) => {
        props.onChange(newValue)
        
        if (typeof newValue === 'string') {
           
            if(newValue!=null){
                setBranchValue({
                    newValue,
                });
            setStudentRegistration({ ...studentRegistration, [branches]: newValue });
        }
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setBranchValue({
                title: newValue.inputValue,
            });
            if(newValue!=null){
               
            setStudentRegistration({ ...studentRegistration, [branches]: newValue.inputValue });
            }

        }
        else if (newValue!=null) {
          
            setBranchValue(newValue);
            if(newValue){
                
            setStudentRegistration({ ...studentRegistration, [branches]: newValue });
            }
        } else {
            setBranchValue(newValue);
            setStudentRegistration({ ...studentRegistration, [branches]: '' });
        }
       
    }}

      value={studentRegistration.branch.name || null}
      onOpen={loadBranchData}
      options={branchData}
      getOptionLabel={(option) => {

         if (typeof option === 'string') {
             return option;
         }
         if (option.inputValue) {
             return option.inputValue;
         }
         return option.name;
     }} 
      getOptionSelected ={(option) => option.value === branchList.name}
   

    
      renderInput={(params) => <TextField {...params} 
     
      className={(errors.branch && classes.invalid) || classes.formControl}
      label={fieldsLabels.branch} helperText='Choose nearest coordinating center from your residence' size='small' variant="outlined" />}
      
    />
                           )}
                           name="branch"
                           control={control}
                           rules={{required:true}}
                           defaultValue={{name:studentRegistration.branch.name ,branchId:studentRegistration.branch.branchId }}
                       
                           />

                           {_.get("branch.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Please select the coordinating center</FormHelperText>
                        )}
                       

                    </Grid>
                    <Grid item xs={12} className={classes.captcha}>

                    <FormControl fullWidth size='small' variant='outlined'
                            className={(errors.source && classes.invalid) || classes.formControl} >
                            <InputLabel id="source">{fieldsLabels.source} </InputLabel>
                            <Controller
                                render={props => (
                                    <Select
                                        value ={( otherSourceSelected && 'Other' )|| studentRegistration.source}
                                        label={fieldsLabels.source}
                                        onBlur={((e) => {
                                            props.onBlur(e)
                                        })}
                                        onChange={((e) => {
                                            props.onChange(e)
                                            if(e.target.value === 'Other'){
                                                setOtherSourceSelected(true);
                                            } else{
                                                setOtherSourceSelected(false);
                                                setStudentRegistration({ ...studentRegistration, [source]: getValues(source) });
                                            }
                                            }
                                            
                                        )}
                                    >
                                        <MenuItem value={'FaceBook'}>Facebook</MenuItem>
                                        <MenuItem value={'Friends'}>Friends</MenuItem>
                                        <MenuItem value={'School'}>School</MenuItem>
                                        <MenuItem value={'Other'}>Other</MenuItem>
                                    </Select>
                                )}
                                name="source"
                                control={control}
                                onChange={OtherSource}
                                rules={{ required: true }}
                            >

                            </Controller>
                        </FormControl>
                        {_.get("source.type", errors) === "required" && (
                            <FormHelperText className={classes.error}>Please select any one</FormHelperText>
                        )}
                        {_.get("source.type", errors) !== "required" && (
                            <FormHelperText style={{color:"darkslategrey"}} >Please select any one</FormHelperText>
                        )}



                    </Grid>

                   { otherSourceSelected ?
                    <Grid item xs={12} className={classes.captcha}>

                        <FormControl
                            className={(errors.source && classes.invalid) || classes.formControl}>
                            <TextField
                            className={(errors.source && classes.invalid) || classes.formControl}
                                name="source"
                                fullWidth
                                size="small"
                                label={fieldsLabels.other}
                                id="source"
                                variant="outlined"
                                helperText={`Please tell us in detail`}
                                onChange={((e) => {
                                    setStudentRegistration({ ...studentRegistration, [source]: getValues(source).toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ')
                                    .reduce((result, word) => result +' '+ capitalize(word.toLowerCase()))
                                    .replace(/^./, function(str){ return str.toUpperCase();  })
                                 });
                                                                })}
                                inputRef={register({
                                    required: true,
                                    maxLength: 100,
                                })}
                            />

                            {_.get("source.type", errors) === "required" && (
                                <FormHelperText className={classes.error}>Please Tell us in detail</FormHelperText>
                            )}
                            {_.get("source.type", errors) === "maxLength" && (
                            <FormHelperText className={classes.error}>Please don't exceed 100 characters</FormHelperText>
                        )}
                        </FormControl>


                    </Grid> : null}

                    <Grid item xs={12} className={classes.captcha}>
                       <Captha setCaptha={setCaptha} refreshCaptha={refreshCaptha} />
                    </Grid>

                </form>
                {/* <Grid  container item xs={12} style={{margin:"5px",textAlign:"center" ,color:"darkslategrey",fontWeight:'bold'}}>
                    By Submitting I agree that SIP Abacus can contact me in the above details for further communications or promotional messages
                </Grid> */}
            </Grid>
        </div>
    );
}

export default CommunicationInfo;