import React, { useEffect } from 'react';
import { MenuList } from '@material-ui/core';
import AppMenuItem from './AppMenuItem';
import Axios from 'axios';


const AppMenu = (props) => {

  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [appMenuItems,setAppMenuItems] = React.useState([]);
  const [examination,setExamination]=React.useState([]);
  const [roleName,setRoleName]= React.useState(JSON.parse(localStorage.getItem("sip-user")).roles[0].name)
  const [roleNameForLclAdmin,setRoleNameForLclAdmin]=React.useState('LCL ADMIN')
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    severity: ''
});

const setExaminationList=(examinationList)=>{
  examinationList.map((data,index)=>{
    examination.push(data.name);
  });
}

  React.useEffect(() => {

    Axios.get(BASE_URL + "/menus", {
      headers: {
          authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
      }
  })
      .then(function (response) {
          if (response && response.data && response.data.length>0) {
            if(roleName === roleNameForLclAdmin){
              response.data[0].items=response.data[0].items.filter((i)=> i.name != "Round 1");
              }
            setAppMenuItems(response.data);
            setExaminationList(response.data);
            }else{
              setAppMenuItems([]);
              setState({ ...state, openAlert: true, message: 'Failed to Load The Menus', severity: "error" });
          } 
      })
      .catch(function (error) {
          setState({ ...state, openAlert: true, message: 'Failed to Load The Menus', severity: "error" });
      });    
  },[])
  
  return (

    <MenuList component="nav" disablePadding>
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index}/>
      ))}
    </MenuList>

  );
};

export default AppMenu;
