import { Box, CssBaseline, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StudentList from '../StudentList'
import StudentListProdigy from '../Prodigy/StudentListProdigy'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function Round(props) {
    const classes = useStyles();
    const { examinationId, examGroupId, currentRound, nextRound } = props;
    const pageLabelMessagesAndHelperTexts = {
        label: {
            TabForExamNotCompletedStudents: 'Students Exam Not Completed',
            TabForExamCompletedStudents: 'Students Exam Completed',
            TabForPromotedStudent: 'Student promoted to',
            StudentTab: 'Practice Round Students',
            WinnersTab: nextRound === 'National Winners' ?'National Round Winners' : 'State Round Winners',

        },
        tabValue: {
            forExamNotCompletedStudent: 0,
            forExamCompletedStudent: 1,
            forPromotedStudent: 2,
        }
    };

    const roleAndDesignationLevel = {
        roles: {
            roleNameForRM: 'REGIONAL MANAGER',
            roleNameForSH: 'STATE HEAD',
            roleNameForAH: 'AREA HEAD',
            roleNameForHO: 'ADMIN',
            roleNameForLCL: 'LCL ADMIN'
        }
    }
    const [examStatus, setExamStatus] = useState('examStatus')
    const [propertiesNeededForStudentComponent, setPropertiesNeededForStudentComponent] = useState(
        {
            examStatus: "notCompleted",
            examinationId: examinationId,
            nextRound: nextRound,
            examGroupId: examGroupId,
            displayActiveTestButton: true,
            displayScheduleWorkShopButton: true,
            displayRegisterForSipAbacus: true,
            displayApproveButton: false,
            displayActiveTestButtonGroup: true,
            screenForStudentsSelectedToNextRound: false,
            currentLevel: currentRound
        }
    );
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        if (newValue === pageLabelMessagesAndHelperTexts.tabValue.forExamNotCompletedStudent) {
            setPropertiesNeededForStudentComponent({
                ...propertiesNeededForStudentComponent,
                examStatus: 'notCompleted',
                displayActiveTestButtonGroup: true,
                displayApproveButton: false,
                screenForStudentsSelectedToNextRound: false
            });
            setExamStatus('notCompleted');
        } else if (newValue === pageLabelMessagesAndHelperTexts.tabValue.forExamCompletedStudent) {
            setPropertiesNeededForStudentComponent({
                ...propertiesNeededForStudentComponent,
                examStatus: 'completed',
                displayActiveTestButtonGroup: true,
                displayApproveButton: false,
                screenForStudentsSelectedToNextRound: false
            });
            setExamStatus('completed');
        } else if (newValue === pageLabelMessagesAndHelperTexts.tabValue.forPromotedStudent) {
            setPropertiesNeededForStudentComponent({
                ...propertiesNeededForStudentComponent,
                examStatus: 'completed',
                displayActiveTestButtonGroup: false,
                displayApproveButton: true,
                screenForStudentsSelectedToNextRound: true
            });
            setExamStatus('completed');
        }
        setValue(newValue);
    };
    const [totalExamNotCompletedStudentCount, setTotalExamNotCompletedStudentCount] = useState(0);
    const [totalExamCompletedStudentCount, setTotalExamCompletedStudentCount] = useState(0);
    const [filteredStudentCount, setFilteredStudentCount] = useState(0);
    const [promotionTabVisibility, setPromotionTabVisibility] = useState(true);
    const roleName = JSON.parse(localStorage.getItem("sip-user")).roles[0].name;
    const [disablePromotionTabProdigy, setDisablePromotionTabProdigy] = React.useState(false);
    const [disableExamCompletedTab, setDisableExamCompletedTab] = React.useState(false);


    useEffect(() => {
        // if (roleName === roleAndDesignationLevel.roles.roleNameForRM ||
        //     roleName == roleAndDesignationLevel.roles.roleNameForSH ||
        //     roleName === roleAndDesignationLevel.roles.roleNameForAH) {
        //     if (nextRound === 'Round 2') {
        //         setPromotionTabVisibility(false);
        //     }

        // }
    }, [roleName])

    useEffect(() => {
        if (examinationId === 2 && currentRound !== 0
            // && (roleName !== roleAndDesignationLevel.roles.roleNameForLCL)
        ) {
            setDisablePromotionTabProdigy(false);
        }
    }, [examinationId, roleName])

    useEffect(() => {
        if ( currentRound === 0) {
            setDisableExamCompletedTab(true);
        } 
        //remove this else if section if promoted to round 3 tab needs to be enabled
        else if (  nextRound === "Round 3" && (roleName===roleAndDesignationLevel.roles.roleNameForLCL || roleName===roleAndDesignationLevel.roles.roleNameForAH)) {
            setDisablePromotionTabProdigy(true);
        }
         //lcl login disable promoted to round 2 tab
         else if (  nextRound === "Round 2" && roleName===roleAndDesignationLevel.roles.roleNameForLCL) {
            setDisablePromotionTabProdigy(true);
        }
        
        else if (  currentRound === 3) {
            setDisableExamCompletedTab(false);
          //  if(roleName===roleAndDesignationLevel.roles.roleNameForLCL || roleName===roleAndDesignationLevel.roles.roleNameForAH)
            setDisablePromotionTabProdigy(true);
            // else
            // setDisablePromotionTabProdigy(false);
        }
    }, [examinationId])

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    scrollButtons='auto'
                    textColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="accessible tabs"
                >
                    {/* {examinationId === 1 && <Tab label={pageLabelMessagesAndHelperTexts.label.StudentTab
                        + " (" + totalExamNotCompletedStudentCount + ")"} {...a11yProps(0)} />}
                    */}
                     <Tab label={((currentRound === 0 && pageLabelMessagesAndHelperTexts.label.StudentTab) ||
                        (currentRound === 3 && pageLabelMessagesAndHelperTexts.label.TabForExamNotCompletedStudents) || pageLabelMessagesAndHelperTexts.label.TabForExamNotCompletedStudents)
                        + " (" + totalExamNotCompletedStudentCount + ")"} {...a11yProps(0)} />

                    {/* {examinationId === 1 && <Tab label={pageLabelMessagesAndHelperTexts.label.TabForExamCompletedStudents
                        + " (" + totalExamCompletedStudentCount + ")"} {...a11yProps(1)} />}
                    */}
                      <Tab label={pageLabelMessagesAndHelperTexts.label.TabForExamCompletedStudents
                        + " (" + totalExamCompletedStudentCount + ")"} {...a11yProps(1)} disabled={disableExamCompletedTab} />
                    {/* {examinationId === 1 && <Tab label={pageLabelMessagesAndHelperTexts.label.TabForPromotedStudent + " " + nextRound} disabled={promotionTabVisibility} {...a11yProps(2)} />} */}
                    
                    <Tab label={pageLabelMessagesAndHelperTexts.label.TabForPromotedStudent + " " + nextRound +" (" + filteredStudentCount + ")"} disabled={disablePromotionTabProdigy} {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={value} index={pageLabelMessagesAndHelperTexts.tabValue.forExamNotCompletedStudent}>
                    <Grid item container justify='center' xs={12} sm={12} md={12} lg={12}>

                    {examinationId === 1 && currentRound === 0 && <StudentList propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='practice'
                            setTotalStudentCount={setTotalExamNotCompletedStudentCount} {...props} />}
                        {examinationId === 1 && currentRound !==0  && <StudentList propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='notCompleted'
                            setTotalStudentCount={setTotalExamNotCompletedStudentCount} {...props} />}
                        {examinationId === 2 && nextRound !== 'State Winners' && nextRound !== 'National Winners' && <StudentListProdigy propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='notCompleted'
                            setTotalStudentCount={setTotalExamNotCompletedStudentCount} {...props} />}
                        {examinationId === 2 && (nextRound === 'State Winners' || nextRound === 'National Winners') && <StudentListProdigy propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='State Winners'
                            setTotalStudentCount={setTotalExamNotCompletedStudentCount} {...props} />}
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={pageLabelMessagesAndHelperTexts.tabValue.forExamCompletedStudent}>
                    <Grid item container justify='center' xs={12} sm={12} md={12} lg={12}>
                        {examinationId === 1 &&  <StudentList propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='completed'
                            setTotalStudentCount={setTotalExamCompletedStudentCount} {...props} />}
                        {examinationId === 2 && <StudentListProdigy propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='completed'
                            setTotalStudentCount={setTotalExamCompletedStudentCount} {...props} />}
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={pageLabelMessagesAndHelperTexts.tabValue.forPromotedStudent}>
                    <Grid item container justify='center' xs={12} sm={12} md={12} lg={12}>
                        {examinationId === 1 && <StudentList propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='completed'
                            setTotalStudentCount={setFilteredStudentCount} {...props} />}
                        {examinationId === 2 && <StudentListProdigy propertiesNeededForStudentComponent={propertiesNeededForStudentComponent} examStatus='completed'
                            setTotalStudentCount={setFilteredStudentCount} {...props} />}
                    </Grid>
                </TabPanel>
            </Paper>
        </React.Fragment>
    );

}

Round.prototype = {
    examGroupId: PropTypes.any.isRequired,
    examinationId: PropTypes.any.isRequired,
    currentRound: PropTypes.any.isRequired,
    nextRound: PropTypes.string.isRequired
}

export default Round;