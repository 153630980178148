import React, { useEffect, useState } from 'react';
import { Pie, Chart } from 'react-chartjs-2';
import { useMediaQuery, useTheme } from '@material-ui/core';
import 'chartjs-plugin-datalabels';

function TotalKidsPieChart(props) {
    const Chart = require('react-chartjs-2').Chart;
    const { isDashboardLoading, dashBoardList } = props;

    const originalPieDraw = Chart.controllers.pie.prototype.draw;
    Chart.helpers.extend(Chart.controllers.pie.prototype, {
        draw() {
            originalPieDraw.apply(this, arguments);

            const chart = this.chart;
            const width = chart.width;
            const height = chart.height;
            const ctx = chart.ctx;

            const fontSize = 1.2;
            ctx.font = `${fontSize}em sans-serif`;
            ctx.textBaseline = 'middle';

            let sum = 0;
            for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
                sum += chart.config.data.datasets[0].data[i];
            }

            const text = `Total:${sum} Students`;
            const textX = 80;
            const textY = 440;

            ctx.fillText(text, textX, textY);
        }
    });


    const pieChartData = {

        labels: [
            'Non SIP Students',
            'SIP Students',
            'Not Updated'
        ],
        datasets: [{
            data: [isDashboardLoading ? dashBoardList.nonSipStudents : 0, isDashboardLoading ? dashBoardList.sipStudents : 0, isDashboardLoading ? dashBoardList.notUpdatedStudents : 0],
            backgroundColor: [
                '#33FF33',
                '#11AAF3',
                '#FF3333'
            ],
            hoverBackgroundColor: [
                '#33FF33',
                '#11AAF3',
                '#FF3333'
            ]
        }]
    };

    const pieChartOptions = {
        maintainAspectRatio: false,
        responsive: false,
        legend: {
            labels: {
                fontColor: 'black',
                fontStyle: "bold",
                fontSize: 14,
            }
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'black',
                font:{
                    weight: "bold",
                    size: 14
                },
                
            }
        },
    }

    let theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div style={{ borderRight: '6px', height: '450px', color: 'black' }}>
            <h2 style={{ textAlign: 'center', marginTop: smallScreen ? "15%" : "4%", marginRight: smallScreen ? "0" : "21%" }}>Total Students</h2>
            <Pie
                data={pieChartData}
                height={500}

                // width={400}
                options={pieChartOptions}
            />
        </div>
    )
}

export default TotalKidsPieChart;

