import React, { useRef } from 'react';
import {
    Button,
    Grid,
    Typography,
    CircularProgress, ButtonGroup, Popper, Grow, Paper, MenuList, MenuItem
} from '@material-ui/core';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/AddRounded';
import axios from 'axios';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import dayjs from 'dayjs';

class WorkshopList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 0,
            recordsPerPage: 10,
            sortKey: "lastUpdatedDt",
            sortDir: "desc",
            count: 0,
            noOfQuestions: [],
            noOfQuestion: 0,
            data: [["Loading Data..."]],
            columns: [
                {
                    name: "workShopName",
                    label: "Name",
                    options: {
                        expandableRows: true
                    }
                },
                {
                    name: "workShopDate",
                    label: "Workshop Date & Time",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta) => {
                            if (value) {
                                var customParseFormat = require('dayjs/plugin/customParseFormat')
                                dayjs.extend(customParseFormat)
                                return   dayjs(value,"DD-MM-YYYY HH:mm").format('DD-MM-YYYY hh:mm a')
                            }
                        }
                    }
                },

                {
                    name: "lastUpdatedDt",
                    label: "Last Updated Date",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "lastUpdatedBy",
                    label: "Last Updated By",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "studentRegistration.length",
                    label: "No of Students",
                    options: {
                        filter: false,
                        sort: false,
                    }
                },
                {
                    name: "createdDt",
                    label: "Created Date",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        display: false,
                    },

                },

            ],
            isLoading: false,
            body: {
                workShopName: "",
                branch: {
                    branchId: JSON.parse(localStorage.getItem("sip-user")).branch.branchId,
                }

            },
            open: false,
            anchorRef: React.createRef(),
            selectedIndex: 0,
            buttonOptions: ['Activate Test', 'Schedule Workshop', 'Register for SIP Abacus'],
            openActiveBatch: false,
            openScheduleWorkshop: false,
            openRegisterStudent: false,

        };

        this.getData = this.getData.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.sort = this.sort.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
        this.closeScheduleWorkShop = this.closeScheduleWorkShop.bind(this);
    }


    componentDidMount() {
        this.getData(this.state.pageNo, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage);
    }

    handleFilterSubmit = (applyFilters) => {
        let filterList = applyFilters();
        let selectedNameFilterValue = "";
        let selectedBranchNameFilterValue = "";
        filterList[0].forEach(element => {
            selectedNameFilterValue = element;
        });
        filterList[1].forEach(element => {
            selectedBranchNameFilterValue = element;
        });

        this.setState({
            body: {
                workShopName: selectedNameFilterValue,
                branch: {
                    name: (selectedBranchNameFilterValue !== "" ? selectedBranchNameFilterValue : "")
                }
            }
        }, () => this.getData(this.state.page, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage));

    }


    async getData(pageNo, sortKey, sortDir, recordsPerPage) {
        this.setState({ isLoading: true });
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        await axios.get(BASE_URL + `/branches/${JSON.parse(localStorage.getItem("sip-user")).branch.branchId}/workshops`,
            {
                params: {
                    body: JSON.stringify(this.state.body),
                    pageNo: pageNo !== undefined ? pageNo : "",
                    sortKey: sortKey !== undefined ? sortKey : "",
                    sortDir: sortDir !== undefined ? sortDir : "",
                    recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                this.setState({ data: response.data.content, count: response.data.totalElements });

            });
        this.setState({ isLoading: false });

    }

    changePage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
        });
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
        this.getData(page, "", "", rowsPerPage);
    }

    sort = (page, sortOrder, rowsPerPage) => {
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
        this.setState({
            pageNo: page,
            sortKey: sortOrder.name,
            sortDir: sortOrder.direction,
            body: {
                workShopName: (searchText != null ? searchText : ""),
                branch: {
                    name: ""
                }
            }
        }, () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage));
    }

    handleClick() {

        console.info(`You clicked ${this.state.buttonOptions[this.state.selectedIndex]}`);


    }

    handleMenuItemClick(event, index) {
        this.setState({
            selectedIndex: index,
            open: false,
            openActiveBatch: (index === 0 ? true : false),
            openScheduleWorkshop: (index === 1 ? true : false),
            openRegisterStudent: (index === 2 ? true : false),
        });

    }

    handleToggle() {
        this.setState({
            open: !this.state.open
        })
    }

    handleClose(event) {

        if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({
            open: false
        })
    }

    closeScheduleWorkShop() {
        this.setState({
            openScheduleWorkshop: false
        });
        this.componentDidMount();
    }

    render() {

        const { count, rowsPerPage, sortOrder } = this.state;

        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: true,
            count: count,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [10, 20, 50, 100],
            sortOrder: sortOrder,
            expandableRows: true,
            expandableRowsHeader: false,
            expandableRowsOnClick: true,
            confirmFilters: true,
            selectableRows: false,
            rowHover: true,
            caseSensitive: true,
            enableNestedDataAccess: '.',
            print: false,
            searchOpen: true,
            onFilterChipClose: () => {
                this.setState(
                    {
                        workShopName: "",
                        branch: {
                            name: ""
                        }
                    }, () => {
                        this.componentDidMount()
                    })
            },
            onFilterDialogClose: () => {
                this.componentDidMount();
            },
            isRowExpandable: (dataIndex, expandedRows) => {
                return true;
            },

            rowsExpanded: [],
            renderExpandableRow: (rowData, rowMeta) => {

                const colSpan = rowData.length + 1;
                if (this.state.data[rowMeta.dataIndex]) {
                    const studentData = this.state.data[rowMeta.dataIndex].studentRegistration;

                    return (
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>

                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Students
              </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Parent Name</TableCell>
                                                <TableCell >Class</TableCell>
                                                <TableCell >School</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >

                                            {studentData && studentData.map((row) => (

                                                <TableRow key={row.studentId}>
                                                    <TableCell component="th" scope="row">
                                                        {row.childName}
                                                    </TableCell>
                                                    <TableCell>{row.parentName}</TableCell>
                                                    <TableCell >{row.classNumber.name}</TableCell>
                                                    <TableCell >{row.schoolName.schoolName}</TableCell>
                                                    {/* <TableCell align="right">
                        {Math.round(row.amount * row.price * 100) / 100}
                      </TableCell> */}
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </Box>

                            </TableCell>
                        </TableRow>

                    );
                }
            },
            onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => console.log(curExpanded, allExpanded, rowsExpanded),

            customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                return (
                    <div style={{ marginTop: '40px' }}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Button color="primary" variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Filter</Button>
                            </Grid>

                        </Grid>
                    </div>
                );
            },

            // callback that gets executed when filters are confirmed
            onFilterConfirm: (filterList) => {
            },

            onFilterDialogOpen: () => {
            },
            searchPlaceholder: 'Search by Name...',
            onTableChange: (action, tableState) => {


                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'sort':
                        this.sort(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changeRecordPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'search':
                        this.handleSearch(tableState.searchText, tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    default:

                }
            }
        };

        const theme = createMuiTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    expandDisabled: {
                        // Soft hide the button.
                        visibility: 'hidden',
                    },
                },
            },
        });

        const components = {
            ExpandButton: function (props) {
                //   if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{width:'24px'}} />;
                return <ExpandButton {...props} />;
            }
        };

        return (
            <div className="container">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MUIDataTable
                            theme={theme}
                            title={<Typography variant="h6">
                                Workshops
                        {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                            </Typography>
                            }
                            data={this.state.data}
                            columns={this.state.columns}
                            options={options}
                            components={components}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default WorkshopList;
