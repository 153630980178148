import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PeopleIcon from '@material-ui/icons/People';
import FaceIcon from '@material-ui/icons/Face';
import SchoolIcon from '@material-ui/icons/School';
import InfoIcon from '@material-ui/icons/Info';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ParentInfo from './ParentInfo';
import ChildInfo from './ChildInfo';
import CommunicationInfo from './CommunicationInfo';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';
import TagManager from "react-gtm-module";


const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);



const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <PeopleIcon />,
        2: <FaceIcon />,
        3: <SchoolIcon />,
        4: <InfoIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiStepLabel-label': {
            color: 'darkslategrey',
        }
    },
    button: {
        marginRight: theme.spacing(1),
        display: "inline-block",
        marginBottom:theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1),
    },
    containerDiv: {
        miHeight: '460px',
        maxHeight: 'auto'
    },
}));

function getSteps() {
    return ['Registration Form'];
}

function getStepContent(step, setStudentRegistrationValidation,
    studentRegistration, setStudentRegistration, setLoginId, otherSourceSelected, setOtherSourceSelected
    , OtherSource, setBackButtonValidation, setCaptha, refreshCaptha,state, setState) {

    switch (step) {
      //  case 0:
       //     return <ParentInfo displayText={'Take a moment to introduce yourself!'}
       //         requestText ={''}
       //         setStudentRegistrationValidation={setStudentRegistrationValidation}
         //       studentRegistration={studentRegistration} setStudentRegistration={setStudentRegistration}
         //       setLoginId={setLoginId} state={state} setState ={setState}/>
        case 0:
            return <ChildInfo 
                setStudentRegistrationValidation={setStudentRegistrationValidation}
                studentRegistration={studentRegistration} setStudentRegistration={setStudentRegistration}
                setBackButtonValidation={setBackButtonValidation} setLoginId={setLoginId} state={state} setState ={setState}/>

       // case 2:
         //   return <CommunicationInfo displayText={'Please select nearest SIP Abacus Centre for all coordination'}
       //         setStudentRegistrationValidation={setStudentRegistrationValidation}
          //      studentRegistration={studentRegistration} setStudentRegistration={setStudentRegistration}
         //       otherSourceSelected={otherSourceSelected} setOtherSourceSelected={setOtherSourceSelected}
         //       OtherSource={OtherSource} setBackButtonValidation={setBackButtonValidation}
          //      setLoginId={setLoginId} setCaptha={setCaptha} refreshCaptha={refreshCaptha} />;
        default:
            return <div>'Unknown step'</div>;
    }
}

export default function RegistrationSteps() {


    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [studentRegistrationValidation, setStudentRegistrationValidation] = React.useState(false);
    const [backButtonValidation, setBackButtonValidation] = React.useState(false);
    const [loginId, setLoginId] = React.useState('');
    const [otherSourceSelected, setOtherSourceSelected] = React.useState(false)
    const OtherSource = (event) => {
        if (event.target.value === 'Other') {
            setOtherSourceSelected(true);
        } else {
            setOtherSourceSelected(false);
        }
    }
    const [captha, setCaptha] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [refreshCaptha, setRefreshCaptha] = React.useState(0);
    const[newRegistration,setNewRegistration]=React.useState(false);
    const [studentRegistration, setStudentRegistration] = React.useState({
        studentId: 0,
        parentName: '',
        mobileNumber1: '',
        mobileNumber2: '',
        whatsAppNumber: '',
        emailId: '',
        childName: '',
        gender: '',
        schoolNameString: '',
        schoolArea: '',
        classNumber: {
            classId: 1,
            name: '',
        },
        section: '',
        state: {
            stateId: 1,
            stateName: '',
        },
        city: {
            cityName: '',
            cityId: 2,
        },
        source: '',
        branch: {
            branchId: 1,
            name: '',
        },
        examination :{
            examinationId :1
        },
        schoolName :{
            schoolId :1,
            schoolName:'',
            schoolArea:''
        },
        registrationCompleted: false,
        regInCompleteNotified:false,
        sipAbacusStudent:'',
        stateOfResidence :{
            stateId:1,
            stateName:""
        }

    });
    const studentId = 'studentId';
    const steps = getSteps();
    const [state, setState] = React.useState({
        open: false,
        message: '',
        severity: 'error',
        timeout: 2000
    });
    const [submitText, setSubmitText] = React.useState(false)
    const { open, message, severity } = state;

    const handleNext = () => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;

        if (activeStep === steps.length -1 ) {
            setSubmitText(true);
        }else{
            setSubmitText(false);
        }

        if (activeStep === steps.length - 1) {
            studentRegistration.registrationCompleted = true;
            localStorage.removeItem('studentId');
            setState({ open: false })
            TagManager.dataLayer({
                dataLayer: {
                    event: 'PageView',
                    pagePath: '/registration/thankyou',
                    pageTitle: 'RegistrationComplete',
                }
            });
        }


        if (activeStep === steps.length - 1) {
            studentRegistration.studentId = `${studentRegistration.studentId !== 0 ? studentRegistration.studentId : 0}`
            axios.post(BASE_URL + "/students", studentRegistration)
                .then(function (response) {
                    if (response.data.studentId === 0) {
                        setAlert(true);
                        setState({
                          ...state,
                          open: true,
                          message: `${response.data.childName} is already registered with this mobile number or email id.`,
                          severity: "error",
                          timeout: 2000
                         
                        });
                        console.log(studentRegistrationValidation)
                        setStudentRegistration({ ...studentRegistration, [studentId]: 0 })
                       
                     
                       
                      } 
                      else if (response.data.studentId===-1){
                        console.log(response.data.studentId===-1);
                        setAlert(true);
                        setState({ ...state, open: true, message: `Two or more students are already registered with this mobile no .${response.data.whatsAppNumber}`, severity: "error", timeout: 2000 });
                        console.log(studentRegistrationValidation)
                        setStudentRegistration({ ...studentRegistration, [studentId]: 0 })
                      
                      
                       
                    }
                   else if (response.data.studentId) {
                    console.log(response.data.studentId);
                    setStudentRegistration({ ...studentRegistration, [studentId]: response.data.studentId })
                    setLoginId(response.data.studentAccount.loginId);
                    localStorage.setItem('studentId',response.data.studentId);
                    setState({ open: false });
                     setAlert(false);
                     setActiveStep((prevActiveStep) => prevActiveStep + 1);
                     setNewRegistration(true);
                    }
                 
               
           
                })
                .catch(function (error) {
                });
         
        } else {
            setRefreshCaptha((prevRefreshCaptha) => prevRefreshCaptha + 1);
            setState({ ...state, open: true, message: `Please Enter the Correct Captcha`, severity: "error" });
        }

    };

    const handleBack = () => {
       setActiveStep((prevActiveStep) => prevActiveStep - 1);
     setSubmitText(false);
        
    };


    const activeStepComp = () => {

        return getStepContent(activeStep, setStudentRegistrationValidation, studentRegistration,
            setStudentRegistration, setLoginId, otherSourceSelected, setOtherSourceSelected, OtherSource,
            setBackButtonValidation, setCaptha, refreshCaptha ,state, setState);
    }
   // const activeStepCompForNewReg = () => {
 //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
 //   }
    return (
        <div className={classes.root} >
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                    <Step key={label} >
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {open ? <div > <Alert severity={severity}>
                {message}
            </Alert>
            </div>
                : null}

            <div>
                {activeStep === steps.length ?(

                    <div>
                        <Typography className={classes.instructions} style={{ color: "darkslategrey" ,textAlign:'center'}}>

                            Thank you for registering! <br /> Your Login ID is <span style={{fontWeight:'bold',color:'black'}}>{loginId}</span>, password has been sent to registered phone.
            </Typography>
            {
                        console.log('entering here ' +activeStep+ ' steps length is ' +steps.length)
                     
                    }
                    </div>
               
                 
                ) : (
                        <div>
                            <div className={classes.containerDiv}>


                                {
                                    activeStepComp()
                                }
                            </div>
                            <div style={{ textAlign: "center" }}>
                                
                                <Button disabled={activeStep === 0 || backButtonValidation} onClick={handleBack} className={classes.button}>
                                    Back
              </Button>
                                <Button
                                    disabled={!studentRegistrationValidation}
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                </Button>
                            </div>
                            <div style={{ color: "black",fontWeight:'bold' ,textAlign:'center',marginBottom:'10px'}}>
                            Please enter all fields marked <span style={{ color: "red"}} >*</span>, to submit
                                </div>
                            
                                {submitText ? 
                            <Grid  container item xs={12} style={{margin:"5px",textAlign:"center" ,color:"darkslategrey",fontWeight:'bold'}}>
                                     By Submitting I agree that SIP Academy can contact me in the above details 
                </Grid> : null}
                        </div>
                    )}
            </div>
        </div>
    );
}