import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Fab } from '@material-ui/core';
import AppHeaderNotifications from './AppHeaderNotifications';
import AppHeaderProfile from './AppHeaderProfile';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginLeft: theme.spacing(1),
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        display: 'flex',
        //  alignItems: 'center',
        //   justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    appBarMenu: {
        marginLeft: 'auto',
        alignItems: 'center',
        display: 'flex',
    }
}));


function AppHeader(props) {
    const classes = useStyles();
    const [branchDetails, setBranchDetails] = useState({branchName:"", branchCode:""});

    useEffect(()=>{
        const sipUser = JSON.parse(localStorage.getItem('sip-user'));
        setBranchDetails({
            branchName: sipUser.branch.name,
            branchCode: sipUser.branch.code,
          })
    },[])

    return (
        <AppBar position="fixed"
            className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="h5" noWrap>{props.appTitle}</Typography>
                <Fab color="secondary" aria-label="Toggle Menu" size="small" onClick={props.handleDrawer} edge="start" className={clsx(classes.menuButton)}>
                    {props.drawerState ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </Fab>
                <div className={classes.appBarMenu}>
                    {/* <AppHeaderNotifications /> */}
                    <Typography variant="h6">{branchDetails.branchName}&nbsp;|</Typography>
                    <Typography variant="h6">&nbsp;{branchDetails.branchCode}</Typography>
                    <AppHeaderProfile />                    
                </div>
            </Toolbar>
        </AppBar>
    );
}

AppHeader.propTypes = {
    handleDrawer: PropTypes.func.isRequired,
    appTitle: PropTypes.string.isRequired,
    drawerState : PropTypes.bool.isRequired,
};

export default AppHeader;