import React from 'react';
import { CssBaseline, Grid, Paper, makeStyles, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import RegistrationSteps from './RegistrationSteps';
import backgroudImage from "../contestLanding/images/background2.svg";
import mobileBG from "../contestLanding/images/mobileBG.svg"
//import backgroudImage2022 from "../contestLanding/images/BG_Page_2022.png";
import backgroundImage2022 from  "../contestLanding/images/BG_2022_AGC.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        wrap: "nowrap",
        paddingRight: "-20%",
        overflow: "hidden"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridItem: {
         padding: "3% 0 2% 0",
        marginTop:theme.spacing(-10)
    },
    registrationTitle:{
        textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    whiteSpace: "pre-line",
    display:"inline-block",
    fontSize:theme.spacing(3),
    padding:theme.spacing(3),
    width:theme.spacing(3)
    }
}));

const styles = {
    paperContainer: {
        backgroundColor: '#ef4237',
        height: '118ch',
        backgroundImage: `url(${backgroundImage2022})`,
        backgroundRepeat:"no-repeat",
        backgroundSize: "cover"

    },
    mobilePaperContainer: {
        backgroundColor: '#ef4237',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        backgroundImage: `url(${mobileBG})`,
      
    }
}



function Registration(props) {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const registrationTitle= "REGISTRATION ";

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.root}>
                <Grid id="registration" container justify="center" alignItems="center" direction="row" style={styles.paperContainer}>
                    <Grid item container xs={12} md={12} style={{marginBottom:'1%'}} >
                        <Grid item md={4} lg={4} xs={2} sm={2}></Grid>
                      
                        <Grid item md={4} lg={4} xs={2} sm={2}></Grid>

                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                        <Paper className={smallScreen ? classes.mobilePaperContainer : classes.paper} >
                            <RegistrationSteps />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}

export default Registration;