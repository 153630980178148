import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, Typography, Card, CardHeader, CardContent, CardActions, Divider, withStyles, Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: "4%",
        minHeight: 275,
        maxHeight: "100vh"
    },
    paperContainer: {
        padding: theme.spacing(3)
    },
    cardHeader: {
        backgroundColor: 'tomato',
        textAlign: 'center',
    },
    buttonOption: {
        width: "100%",
        padding: theme.spacing(0.5),
        border: 0,
    },
    title: {
        height:"10px",
        fontSize: 22,
        backgroundColor: "#ff4154",
        borderCollapse: "collapse",
        width: "100%",
        borderSpacing: 10,
        borderTop: 0,
        textAlign: "center",
        color: "white",
        fontWeight: "bold"
    },
    questionNoText:{
        fontSize: "16px",
        height:"7px"
    },
    question: {
        fontWeight: "bold",
        color: "#000099",
        fontSize: "18px",
        padding:"5px"
    },
    cardContainer: {
        borderRadius: "30px",
    },
    questionContainer: {
        fontSize: '18px',
        fontWeight: '500',
        alignItems: 'space-between',
        textAlign: 'right'
    },
    actionButton: {
        height: "150px",
    },
    fontColor: {
        color: "#000099"
    }
}));

Question2.propTypes = {

};

const StyledToggleButtonGroup = withStyles((theme) => ({
    root: {
        width: "100%",
    },
    grouped: {
        margin: theme.spacing(0.3),
        height: '25%',
        border: '2px solid #40ff00',
        '&:not(:first-child)': {
            borderRadius: theme.spacing(2),
            borderColor: '#40ff00'
        },
        '&:first-child': {
            borderRadius: theme.spacing(2),
            borderColor: '#40ff00'
        },
    },
    groupedVertical: {
        '&:not(:first-child)': {
            borderTop: '2px solid #40ff00',
        }
    }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: "#93DFB8",
        },
        height: "25px",
    },
    selected: {
        backgroundColor: "#00cc66 !important",
    }

}))(ToggleButton);

function Question2(props) {

    let constructTitle = "";
    const classes = useStyles();
    const { question, buttonSize, index, page, recordsPerPage, answers, setAnswers,
        examObject, setAnsweredQuestion, totalNoOfQueInPreSec, examinationType, setPage, totalNumberOfPages } = props;
    const [option, setOption] = React.useState('');
    const [title, setTitle] = React.useState('');

    const handleChange = (event, nextOption) => {

        var existingAns = answers;
        if (nextOption !== null) {
            if (existingAns.length === 0) {
                existingAns = [];
                let answerObject = initializeAnswerObject(nextOption);
                existingAns.push(answerObject);
                setAnswers(existingAns);
            } else {
                var isAnswered = false;
                for (var i = 0; i < existingAns.length; i++) {
                    if (existingAns[i].question.questionId === question.questionId) {
                        existingAns[i].options.optionId = nextOption;
                        isAnswered = true;
                        break;
                    }
                }
                if (!isAnswered) {
                    let answerObject = initializeAnswerObject(nextOption);
                    existingAns.push(answerObject);
                }
                setAnswers(existingAns);
            }
        } else {
            for (let j = 0; j < existingAns.length; j++) {
                if (existingAns[j].question.questionId === question.questionId) {
                    existingAns.splice(j, 1);
                    break;
                }
            }
            setAnswers(existingAns);
        }
        setAnsweredQuestion(existingAns.length);
        setOption(nextOption);
        // if ((page < totalNumberOfPages - 1) && examinationType === 'prodigy') {

        //     setPage(prevPage => prevPage + 1)
        // }
    };

    useEffect(() => {
        var existingAns = answers;

        if (existingAns.length === 0) {
            setOption('');
        } else {
            for (var i = 0; i < existingAns.length; i++) {
                if (existingAns[i].question.questionId === question.questionId) {
                    setOption(existingAns[i].options.optionId);
                    break;
                }
            }
        }

        constructTitle = "Q. No. " + ((recordsPerPage * (page + 1)) + (index + 1) - recordsPerPage + totalNoOfQueInPreSec);
        setTitle(constructTitle);
    }, [question, title]);

    function initializeAnswerObject(selectedOption) {
        let answerObjectToBePushed = {
            'createdBy': examObject.studentName,
            'lastUpdatedBy': examObject.studentName,
            'active': true,
            'question': {
                'questionId': question.questionId
            },
            'options': {
                'optionId': selectedOption
            },
            'student': {
                'studentId': examObject.studentId
            },
            'exam': {
                'examId': examObject.exam.examId
            },
            'batch': {
                'batchId': examObject.batchId
            }
        }
        return answerObjectToBePushed;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Card elevation={8} className={classes.cardContainer}>
                    <CardHeader className={classes.title} title={ <span className={classes.questionNoText}> {title} </span>}/>
                    <CardContent className={classes.question}>
                        <Grid container justify="center">
                            <Typography className={classes.questionContainer}>
                                <div dangerouslySetInnerHTML={{
                                    __html: question.question
                                }} />
                            </Typography>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardContent style={{ padding: "3px", textAlign: 'center' }}>
                        <Typography className={classes.fontColor} variant="caption" color="#000099">
                            Select the correct answer.
                                            </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.actionButton}>
                        <StyledToggleButtonGroup orientation="vertical" value={option} exclusive onChange={handleChange} size="small">
                            {question.options.map((option) => (
                                <StyledToggleButton value={option.optionId} fontSize={buttonSize} className={classes.fontColor}>
                                    {option.option}
                                </StyledToggleButton>
                            ))}
                        </StyledToggleButtonGroup>
                    </CardActions>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default Question2;