import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CustomTheme from './components/Common/CustomTheme';
import { ThemeProvider  } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';

// Initialize GTM for analytics
const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM}`,
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={CustomTheme}>
        <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
