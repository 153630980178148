import React, { useState, useLayoutEffect } from 'react';
import Box from '@material-ui/core/Box';
import {
    TextField, Grid, makeStyles, FormHelperText, CssBaseline, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import _ from "lodash/fp";
import { useForm } from 'react-hook-form';
import { useEffect } from "react";
import Typography from '@material-ui/core/Typography';
import reCaptha from '../contestLanding/images/recaptcha_image.jpg';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        '& .MuiFormLabel-root': {
            color: 'darkslategrey',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'darkslategrey',
            },
        },
        width: 100,
        '& .MuiFormHelperText-contained': {
            margin: 0,
            color: 'darkslategrey',
        }
    },
    question: {
        '& .MuiInputBase-input.Mui-disabled': {
            opacity: 1,
            color: 'black',
            fontWeight:'bold'
        },
        color: "black",
        textAlign: 'right',
        '& .MuiFormLabel-root': {
            color: 'black',
        },
        width: 100,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
                color:'black'
            },
        },

    },

}));


function Captha({ setCaptha, refreshCaptha }) {

    const min = 1;
    const max = 5;
    const [number1, setNumber1] = useState((Math.floor(Math.random() * (max - min + 1)) + min))
    const [number2, setNumber2] = useState((Math.floor(Math.random() * (max - min + 1)) + min))
    // const [correctAnswer, setCorrectAnswer] = useState((number1 + number2))

    useEffect(() => {
        setNumber1((Math.floor(Math.random() * (max - min + 1)) + min));
        setNumber2((Math.floor(Math.random() * (max - min + 1)) + min));
    }, [refreshCaptha]);

    // useEffect(() => {
    //     setCorrectAnswer((number1 + number2));
    // }, [number2]);


    const handleChange = () => {
        if (Number(getValues('answer')) === (number1 + number2)) {
            setCaptha(true)
        } else {
            setCaptha(false)
        }

    }

    const { register, getValues } = useForm({});

    const classes = useStyles();
    //const questions = <span> {number1} + {number2} =</span>
    return (
        <div style={{ color: "darkslategrey" }} >
            {/* <div>
            Please confirm you're not a robot by entering the sum.
            </div> */}
            {/* <div> */}
            {/* </div> */}
            <div>
            <img src = {reCaptha} style={{height: "36px",marginTop:'18px'}} />
              <TextField
          
                className={classes.question}

                size="small"
                disabled
                margin="normal"
                variant="outlined"
                id="question"
                name="question"
                value={`${number1} + ${number2} = `}

            /> <TextField

                className={classes.formControl}
                size="small"
                margin="normal"
                variant="outlined"
                id="answer"
                onChange={handleChange}
                inputRef={register({
                    required: true,
                    min: 1,
                    max: 2,
                })}
                name="answer"
                defaultValue=""
            />
                       <span style={{fontSize:'12px'}}> Please enter the answer for the above question.</span>

</div>
        </div>
    );

}

export default Captha;