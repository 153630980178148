import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button, FormControl, FormHelperText, Grid } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select'
import _ from "lodash/fp";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ResetOutlinedIcon from '@material-ui/icons/LoopOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    error: {
        color: "red",
        fontSize: "14px"
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    titleAlignment: {
        marginBottom: "1px",
    },
    actionButtons: {
        marginRight: theme.spacing(3),
    },
    checkboxSize: {
        width: "18px",
        height: "18px"
    },
    dialogTitleContainer: {
        height: "30px",
    }
}));

export default function CreateOrEditClass(props) {

    const classes = useStyles();
    const { onClose, classObject, open } = props;
    const [title, setTitle] = useState("Create");
    const [ orgId, setOrgId ] = useState(0);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [resetButtonStatus, setRestButtonStatus] = useState(false);
    const [isOrgOptionsLoading, setIsOrgLoadingOptions] = useState(false);
    const [isBranchOptionsLoading, setIsBranchLoadingOptions] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState();
    const [nameFieldLength, setNameFieldLength] = useState(0);
    const { control, register, handleSubmit, errors, watch, reset, setValue, getValues } = useForm({
        defaultValues: {
            name: "",
        }
    });

    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const username = 'admin';
    const password = 'admin';

    const onSubmit = data => {
        data["classId"] = `${classObject.class.classId !== undefined ? classObject.class.classId : 0}`;
        data["lastUpdatedDt"] = `${classObject.class.lastUpdatedDt !== undefined ? classObject.class.lastUpdatedDt : ""}`;
        data["lastUpdatedBy"] = `${classObject.class.lastUpdatedBy !== undefined ? classObject.class.lastUpdatedBy : ""}`;
        data["createdDt"] = `${classObject.class.createdDt !== undefined ? classObject.class.createdDt : ""}`;
        data["createdBy"] = `${classObject.class.createdBy !== undefined ? classObject.class.createdBy : ""}`;
         axios.post(BASE_URL + "/classes", data,
        {
            headers: { 
                authorization: 'Basic ' + window.btoa('admin' + ":" + 'admin') 
            }
        }).then((Response) => {
            handleClose();
        })
        .catch(function (error) {
        });
    }

    useEffect(() => {
        if (classObject.mode === 'create') {
            setTitle("Create");
            setButtonLabel("Save");
        } else {
            setTitle("Edit");
            reset({
                classId: `${classObject.class.classId !== undefined ? classObject.class.classId : 0}`,
                name: `${classObject.class.name !== undefined ? classObject.class.name : ""}`,
                organization: { 
                    value: classObject.class.organization.organizationId, 
                    label: classObject.class.organization.name, 
                    organizationId: classObject.class.organization.organizationId },
                branch: { 
                    value: classObject.class.branch.branchId, 
                    label: classObject.class.branch.name, 
                    branchId: classObject.class.branch.branchId },
                active: classObject.class.active ? true : false
            });
            setNameFieldLength(classObject.class.name.length);
            setButtonLabel("Update");
            setRestButtonStatus(true);
        }
    }, [classObject]);

    const handleClose = () => {
        reset({
            name: "",
            organization: "",
            branch: ""
        });
        setNameFieldLength(0);
        setRestButtonStatus(false);
        onClose();
    }

    const loadOrgOptions = () => {
        setIsOrgLoadingOptions(true);
        setOrganizationList([]);
        axios.get(BASE_URL + "/organizations",
            {
                params: {
                    body: JSON.stringify({name: ""}),
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((response) => {
               setOrganizationList(response.data.content);
            });
            setIsOrgLoadingOptions(false);
    }

    const loadBranchOptions = () => {
        setIsBranchLoadingOptions(true);
        setBranchList([]);

        axios.get(BASE_URL + "/organizations/" + orgId +" /branches",
        {
            params: {
                body: JSON.stringify({name: ""}),
            },
            headers: {
                authorization: 'Basic ' + window.btoa(username + ":" + password)
            }
        }).then((response) => {
            setBranchList(response.data.content);
        });
       
        setIsBranchLoadingOptions(false);
    }

    const orgOptions = organizationList.map((organization) => {
        return {label: organization.name, organizationId: organization.organizationId, value: organization.organizationId}
    });


    const branchOptions = branchList.map((branch) => {
        return {label: branch.name, branchId: branch.branchId, value: branch.branchId}
    });

    return (
        <Dialog fullWidth={true} onClose={handleClose} aria-labelledby="create-or-delete-dialog"
            open={open} disableBackdropClick={true} TransitionComponent={Transition}>
            <DialogTitle className={classes.dialogTitleContainer}>
                {title} Class
            </DialogTitle>
            <DialogContent className={classes.formControl}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth >
                        <p className={classes.titleAlignment}>Name *</p>
                        <TextField
                            variant="outlined"
                            autoFocus
                            name="name"
                            size="small"
                            onChange={(() => {
                                setNameFieldLength(getValues("name").length);
                            })}
                            inputRef={register({
                                required: true,
                                maxLength: 100,
                            })}
                            fullWidth
                            defaultValue=""
                            autoComplete="false"
                        ></TextField>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormHelperText>Max 100 Chars</FormHelperText>
                            </Grid>
                            <Grid container item xs={6} justify="flex-end">
                                <FormHelperText>{nameFieldLength}/100</FormHelperText>
                            </Grid>
                        </Grid>
                        <div className={classes.error}>
                        {_.get("name.type", errors) === "required" && (
                            <p>Name is required</p>
                        )}
                        {_.get("name.type", errors) === "maxLength" && (
                            <p>Name cannot exceed 100 characters</p>
                        )}
                    </div>
                    </FormControl>
                    <FormControl fullWidth >
                        <p className={classes.titleAlignment}>Organization *</p>
                        <Controller
                            name="organization"
                            as={Select}
                            isClearable
                            control={control}
                            options={orgOptions}
                            defaultValue=""
                            onMenuOpen={loadOrgOptions}
                            onMenuClose={(() => {
                                let organization = getValues("organization");
                                if(organization !== "" && organization !== null) {
                                    setOrgId(organization.organizationId);
                                } else {
                                    setOrgId(0);
                                }
                            })}
                            value={selectedOrg}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            isLoading={isOrgOptionsLoading}
                            rules={{ required: true }}
                        />
                        {_.get("organization.type", errors) === "required" && (
                            <p className={classes.error}>Organization is required</p>
                        )}
                    </FormControl>
                   
                    <FormControl fullWidth>
                        <p className={classes.titleAlignment}>Branch *</p>
                        <Controller
                            name="branch"
                            as={Select}
                            control={control}
                            isClearable
                            options={branchOptions}
                            isLoading={isBranchOptionsLoading}
                            onMenuOpen={loadBranchOptions}
                            rules={{ required: true }}
                            defaultValue=""
                            menuPosition="fixed"
                            menuPlacement="auto"
                        />
                        {_.get("branch.type", errors) === "required" && (
                            <p className={classes.error}>Branch is required</p>
                        )}
                    </FormControl>
                    <FormControl>
                        <Grid container justify="flex-start" >
                                <input className={classes.checkboxSize}
                                    ref={register}
                                    name="active"
                                    type="Checkbox"
                                /> Active
                    </Grid>
                    </FormControl>

                </form>
            </DialogContent>
        <DialogActions className={classes.actionButtons}>
            <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}
            endIcon={<CancelOutlinedIcon />}>Cancel</Button>
            <Button variant="outlined" size={"small"} disabled={resetButtonStatus}
             endIcon={<ResetOutlinedIcon />} onClick={() => {
                    reset({
                        name: "",
                        organization: "",
                        branch: ""
                    }
                    );
                }}>Reset</Button>
            <Button variant="outlined" size={"small"} color="primary" onClick={handleSubmit(onSubmit)}
            endIcon={<SaveOutlinedIcon />}>{buttonLabel}</Button>
        </DialogActions>
        </Dialog >
    );
}

CreateOrEditClass.propTypes = {
    open: PropTypes.bool.isRequired,
    classObject: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};