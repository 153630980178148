import React, { useRef } from "react";
import {
    Button,
    Grid,
    Typography,
    CircularProgress,
    ButtonGroup,
    Popper,
    Tooltip,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    FormControl,
    Select,
    Checkbox,
    ListItemText,
    Snackbar,
    createMuiTheme,
    MuiThemeProvider,
    CssBaseline,
    TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axios from "axios";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ActivateTestDialog from "./ActivateTestDialog";
import Workshops from "./workShopDialog";
import Alert from "@material-ui/lab/Alert";
import dayjs from "dayjs";
import { Autocomplete } from "@material-ui/lab";
import RegisterForSIPAbacusList from "./RegisterForSIPAbacus";
import PromotionList from "./Promotion/Promotion";
import EditOutLinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutLinedIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import EditStudent from "./EditStudent/EditStudent";
import ConfirmationDialog from "../../Common/Confirmation";
import { saveAs } from "file-saver";
import PasswordRest from "./PasswordRest";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RepeatIcon from '@material-ui/icons/Repeat';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import logo from "../../Examination/contestLanding/images/favicon.png"
import { roundToNearestMinutes } from "date-fns/esm";
import { red } from "@material-ui/core/colors";
import AssignmentIcon from '@material-ui/icons/Assignment';

const styles = (theme) => ({
    tableRow: {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: "purple",
        "& > .MuiTableCell-root": {
          color: "yellow"
        }
      }
    }
  });

class StudentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLevel: props.propertiesNeededForStudentComponent.currentLevel,
            openEdit: false,
            openConfirmationDialog: false,
            transactionObj: {},
            pageNo: 0,
            recordsPerPage: 100,
            sortKey: "createdDt",
            sortDir: "desc",
            count: 0,
            BASE_URL: `${process.env.REACT_APP_API_URL}`,
            noOfQuestions: [],
            endDateColumnIndex: 13,
            noOfQuestion: 0,
            childNameList: [],
            classNameList: [],
            schoolNameList: [],
            registrationStatusList: [],
            examStatusList: [],
            customColumnValueForRegistrationNotCompleted: "InComplete",
            customColumnValueForRegistrationCompleted: "completed",
            examStatusForStartingTest: "started",
            examStatusForInProgressTest: "in-progress",
            examStatusForCompletedTest: "completed",
            examStatusForAbortingTest: "aborted",
            examStatusForPromotedToR2: "Promoted to R2",
            dataLoadingMessage: "Loading Data...",
            branchList: [],
            sipAbacusStudentYes: "Yes",
            sipAbacusStudentNo: "No",
            schoolNameVerifiedYes:"Yes",
            schoolNameVerifiedNo :"No",
            isExamCompleted:
                props.examStatus === "completed" ? "completed" : "notCompleted",
            sendEmailButtonHeading:
                props.examStatus === "completed"
                    ? "Send Results & Certificate"
                    : "Send LoginId & Password",
            confirmationType: "",
            data: [["loading..."]],
            examStatus :props.examStatus,
            columns: [
                {
                    name: "action",
                    label: "Actions",
                    options: {
                        filter: false,
                        setCellHeaderProps: value => ({ style: { paddingRight: '65px' } }),

                        sort: false,
                        empty: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Grid container justify="flex-start">
                                    <Tooltip title="Edit" aria-label="edit">
                                        <IconButton
                                            onClick={() => {
                                                this.setState({
                                                    openEdit: true,
                                                    studentObj: {
                                                        mode: "edit",
                                                        student: this.state.data[dataIndex],
                                                    },
                                                });
                                            }}
                                        >
                                            <EditOutLinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title="Delete"
                                        style={{
                                            display: this.state.studentDeletePermission
                                                ? "block"
                                                : "none",
                                        }}
                                        aria-label="delete"
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                let itemName = this.state.data[dataIndex].childName;
                                                this.setState({
                                                    openConfirmationDialog: true,
                                                    transactionObj: this.state.data[dataIndex],
                                                    confirmationType: "delete",
                                                    message: `Are You sure?, You want to Delete "${itemName}" ?`,
                                                });
                                            }}
                                        >
                                            <DeleteOutLinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Reset Password" aria-label="reset-password">
                                        <IconButton
                                            onClick={async () => {
                                                let student = await this.state.data[dataIndex];
                                                this.setState({
                                                    openPasswordResetDialog: true,
                                                    
                                                    transactionObj: { 'studentAccountId': await student.studentAccountId,'password': await student.password, 'loginId':await student.loginId},
                                                    studentAccountId: await student.studentAccountId,
                                                    studentName: await student.childName,
                                                });
                                            }}
                                        >
                                            <LockOpenIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={this.state.sendEmailButtonHeading}
                                        style={{
                                            display:
                                                ( (!this.state.isLoading && this.state.data.length > 0) &&
                                                    ( ( this.state.data[dataIndex] !== undefined) && 
                                                    (this.state.data[dataIndex] !== undefined && this.state.data[dataIndex].examStatus === 'completed' && this.state.data[dataIndex].certificateSent !== "Yes")
                                                    
                                                        ))
                                                    ? "none"
                                                    : "block",
                                        }}
                                        
                                        aria-label="resend email"
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={async () => {
                                                let studentName = await this.state.data[dataIndex]
                                                    .childName;
                                                this.setState({
                                                    openConfirmationDialog: true,
                                                    transactionObj: await this.state.data[dataIndex],
                                                    confirmationType: "resend",
                                                    message:
                                                        this.state.isExamCompleted === "completed"
                                                            ? `Are You sure?, You want to re-send Result and Certificate to "${studentName}" ?`
                                                            : `Are You sure?, You want to re-send Login Id and Password to "${studentName}" ?`,
                                                });
                                            }}
                                        >
                                            <EmailOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title="Download Certificate"
                                        style={{
                                            display:
                                                ((!this.state.isLoading && this.state.data.length > 0) &&
                                                    ((this.state.isExamCompleted === "completed" && this.state.data[dataIndex] !== undefined) &&
                                                        this.state.data[dataIndex].certificateSent === "Yes"))
                                                    ? "block"
                                                    : "none",
                                        }}
                                        aria-label="download certificate"
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={async () => {
                                                let studentId = await this.state.data[dataIndex].studentId;
                                                let examGroupId = await this.state.examGroupId;
                                                this.handleCertificateDownload(studentId,examGroupId);
                                            }}
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title="Download Answer Sheet"
                                        style={{
                                            display:
                                                ((!this.state.isLoading && this.state.data.length > 0) &&
                                                    ((this.state.isExamCompleted === "completed" && this.state.data[dataIndex] !== undefined) &&
                                                        this.state.data[dataIndex].examStatus === "completed")
                                                         //remove this condition when the download  answer sheet for round 2 is avialiable
                                                        && this.state.examGroupId === 2)
                                                        
                                                    ? "block"
                                                    : "none",
                                        }}
                                        aria-label="download answer sheet"
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={async () => {
                                                let studentId = await this.state.data[dataIndex].studentId;
                                                let childName= await this.state.data[dataIndex].childName;
                                                this.handleAnswerSheetDownload(studentId,childName);
                                            }}
                                        >
                                            <AssignmentIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title="Re-schedule Exam"
                                        style={{
                                            display:
                                                    (!this.state.isLoading && this.state.data.length > 0) &&
                                                    (this.state.data[dataIndex] !== undefined && this.state.data[dataIndex].examStatus === "completed" &&
                                                       (( this.state.data[dataIndex].workshop === null && this.state.currentLevel === 1 ) || this.state.currentLevel >1)
                                                         &&
                                                        this.state.isExamCompleted === "completed" && this.state.reActivateTestPermission && (this.state.roleName !== this.state.roleNameForLclAdmin))
                                                    ? "block"
                                                    : "none",
                                        }}
                                        aria-label="reschedule exam"
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={async () => {
                                                let studentName = await this.state.data[dataIndex].childName;
                                                this.setState({
                                                    openConfirmationDialog: true,
                                                    transactionObj: await this.state.data[dataIndex],
                                                    confirmationType: "reSchedule",
                                                    message: `Are You sure?, You want to re-schedule exam to "${studentName}" ?`,
                                                });
                                            }}
                                        >
                                            <RepeatIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Paid and registered for SIP abacus" aria-label="sip-registration"
                                        style={{
                                            display:
                                                (!this.state.isLoading && this.state.data.length > 0) &&

                                                    ((this.state.data[dataIndex] !== undefined 
                                                       // && (this.state.data[dataIndex].examStatus === "completed") 
                                                       // && !this.state.data[dataIndex].sipRegistration) 
                                                       // && this.state.isExamCompleted === "completed") 
                                                       // && !this.state.screenForStudentsSelectedToNextRound )
                                                        ))

                                                    ? "block"
                                                    : "none"
                                        }}>
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                this.sipRegistration(this.state.data[dataIndex])
                                            }}
                                        >
                                            <img src={logo} style={{ height: '25px', width: '25px' }} />
                                        </IconButton>
                                    </Tooltip>


                                </Grid>
                            );
                        },
                    },
                },



                {
                    name: "childName",
                    label: "Child Name",
                    options: {
                        filter: true,
                        filterType: "custom",
                        setCellHeaderProps: value => ({ style: { paddingRight: '20px' } }),

                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.childName),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                return (
                                    <FormControl>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-child-name"
                                            disableClearable
                                            onOpen={() => {
                                                this.getStudentsList("", "");
                                            }}
                                            options={this.state.childNameList}
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.childName;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.studentId === value.studentId
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Child Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={async (ev) => {
                                                        if (
                                                            ev.target.value !== "" ||
                                                            ev.target.value !== null
                                                        ) {
                                                            this.getStudentsList(ev.target.value, "");
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: "search",
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {this.state.childNameLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },
                {
                    name: "classNumber.name",
                    label: "Class",
                    options: {
                        filter: true,
                        filterType: "custom",

                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.name),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                return (
                                    <FormControl>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-class-name"
                                            disableClearable
                                            onOpen={async () => {
                                                let optionValues = await this.getClassNumberList();
                                                this.setState({
                                                    classNameList: optionValues.data.content,
                                                });
                                            }}
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.name;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.classId === value.classId
                                            }
                                            options={this.state.classNameList}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Class"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },

                },
                {
                    name: "section",
                    label: "Section",
                    style:{background:red},
                    options: {
                        filter: false,
                        display: false,

                    },
                },
                {
                    name: "schoolName",
                    label: "School",
                    options: {
                        filter: true,
                        // setCellHeaderProps: value => ({ style: { width:'60px',textDecoration: 'underline' } }),
                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.schoolName),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                return (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-school-name"
                                            disableClearable
                                            onOpen={async () => {
                                                this.getStudentsList("", "");
                                            }}
                                            onClose={() => {
                                                this.setState({
                                                    schoolNameList: [],
                                                });
                                            }}
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.schoolName;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.studentId === value.studentId
                                            }
                                            options={this.state.schoolNameList}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="School"
                                                    margin="normal"
                                                    variant="outlined"
                                                    onChange={(ev) => {
                                                        if (
                                                            ev.target.value !== "" ||
                                                            ev.target.value !== null
                                                        ) {
                                                            this.getStudentsList("", ev.target.value);
                                                        }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: "search",
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {this.state.schoolNameLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },
                {
                    name: "schoolArea",
                    label: "School Area",
                    options: {
                        filter: false,
                        // setCellHeaderProps: value => ({ style: { width:'50px',textDecoration: 'underline' } }),

                    },
                },
                {
                    name: "branchName",
                    label: "LCL - Name",
                    options: {
                        filter: true,
                        display: false,
                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) =>
                                v.map((l) => {
                                    return `${l.name} - ${l.code}`;
                                }),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                return (
                                    <FormControl>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-branch"
                                            disableClearable
                                            onOpen={async () => {
                                                let options = await this.loadBranchData();
                                                this.setState({
                                                    branchList: options.data.content,
                                                });
                                            }}
                                            options={this.state.branchList}
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return `${option.name} - ${option.code}`;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.branchId === value.branchId
                                            }
                                            renderOption={(option) => {
                                                return `${option.name} - ${option.code}`;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Branch"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },
                {
                    name: "branchCode",
                    label: "LCL - Code",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "parentName",
                    label: "Parent Name",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "loginId",
                    label: "Login Id",
                    options: {
                        filter: false,
                        display: true,
                    },
                },
                {
                    name: "password",
                    label: "Password",
                    options: {
                        filter: false,
                        display: true,
                    },
                },
                {
                    name: "registrationCompletedDisp",
                    label: "Registration Status",
                    options: {
                        filter: (props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound ? false : true),
                        display: false,
                        customBodyRender: (value, tableMeta) => {
                            if (value) {
                                return (
                                    <div>
                                        <span style={{ display: value ? "block" : "none" }}>

                                            {
                                                this.state.customColumnValueForRegistrationCompleted
                                            }
                                        </span>
                                        <span style={{ display: value ? "none" : "block" }}>

                                            {
                                                this.state.customColumnValueForRegistrationNotCompleted
                                            }
                                        </span>
                                    </div>
                                );
                            }
                        },
                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.name),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                let optionsForRegistrationFlag = [
                                    {
                                        name: this.state.customColumnValueForRegistrationCompleted,
                                        value: true,
                                    },
                                    {
                                        name: this.state
                                            .customColumnValueForRegistrationNotCompleted,
                                        value: false,
                                    },
                                ];
                                return (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-registration-flag"
                                            disableClearable
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            options={optionsForRegistrationFlag}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.name;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.name === value.name
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Registration Status"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },
                {
                    name: "startDate",
                    label: "Batch Date(Start & End Time)",
                    options: {
                        filter: false,
                        sort: true,

                        customBodyRender: (value, tableMeta) => {
                            let endTimeFromEndDate =
                              (tableMeta.rowData[this.state.endDateColumnIndex] !== null && tableMeta.rowData[this.state.endDateColumnIndex]);
                            let slicedEndTimeFromEndDate =
                              (tableMeta.rowData[this.state.endDateColumnIndex] !== null && endTimeFromEndDate !== undefined && endTimeFromEndDate !== 0 && endTimeFromEndDate !== null)
                                ? endTimeFromEndDate.slice(11, 16)
                                : "";
                            if (value !== undefined && tableMeta.rowData[this.state.endDateColumnIndex] !== null) {
                              var customParseFormat = require("dayjs/plugin/customParseFormat");
                              dayjs.extend(customParseFormat);
              
                              return (
                                props.examStatus === "practice" ? dayjs(value, "DD-MM-YYYY HH:mm").format(  //For practice removing batch end time
                                  "DD-MM-YYYY hh:mm a"
                                ) :
              
                                  dayjs(value, "DD-MM-YYYY HH:mm").format(
                                    "DD-MM-YYYY hh:mm a"
                                  ) +
                                  " - " +
                                  dayjs(slicedEndTimeFromEndDate, "HH:mm").format("hh:mm a")
                              );
                            }
                          },
                    },
                },
                {
                    name: "endDate",
                    label: "Batch End Date and time",
                    options: {
                        filter: false,
                        sort: false,
                        display: "excluded",
                    },
                },
                {
                    name: "examStatus",
                    label: "Exam Status",
                    options: {
                       // filter: (props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound ? false : true),
                        filter :true,
                        sort: false,
                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) => v.map((l) => l),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                let optionsForExamStatusFlag = [
                                    this.state.examStatusForStartingTest,
                                    this.state.examStatusForInProgressTest,
                                    this.state.examStatusForCompletedTest,
                                    this.state.examStatusForAbortingTest,
                                    this.state.examStatusForPromotedToR2
                                ];
                                return (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-exam-status-flag"
                                            disableClearable
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            options={optionsForExamStatusFlag}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                            }}
                                            getOptionSelected={(option, value) => option === value}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Exam Status"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                        display: true
                    },

                },
                {
                    name: "totalMarks",
                    label: props.examStatus === 'practice'?"Last Test Marks": "Total Marks",
                    options: {
                        filter: false,
                        sort: false,
                        display: (props.examStatus === "completed" || this.props.examStatus === 'practice') ?  true : false
                    },
                },

                {
                    name: "mobileNumber1",
                    label: "Mobile Number",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "whatsAppNumber",
                    label: "WhatsApp Number",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "emailId",
                    label: "Email Id",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "source",
                    label: "Source",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "lastUpdatedDt",
                    label: "Last Updated Date",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "lastUpdatedBy",
                    label: "Last Updated By",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "registrationDt",
                    label: "Registration Date",
                    options: {
                        filter: false,
                    },
                },
                {
                    name: "sipAbacusStudent",
                    label: "SIP Abacus Student",
                    options: {
                        filter: true,

                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.name),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            display: (filterList, onChange, index, column) => {
                                let optionsForSipAbacusStudent = [
                                    { name: this.state.sipAbacusStudentYes, value: "Yes" },
                                    { name: this.state.sipAbacusStudentNo, value: "No" },
                                ];
                                return (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-sip-abacus-student"
                                            disableClearable
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            options={optionsForSipAbacusStudent}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.name;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.name === value.name
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="SIP Abacus Student"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "certificateSent",
                    label: "Certificate Sent",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "certificateSentDate",
                    label: "Certificate Sent Date",
                    options: {
                        filter: false,
                        display: false,
                    },
                },
                {
                    name: "workshop.workshopAttendedDate",
                    label: "workshop Attended Date",
                    options: {
                        filter: false,
                        display: false,
                        customBodyRender: (value, tableMeta) => {
                            if (value !== undefined && value !== null) {
                                var customParseFormat = require("dayjs/plugin/customParseFormat");
                                dayjs.extend(customParseFormat);
                                return dayjs(value, "YYYY-MM-DD").format("DD-MM-YYYY");
                            }
                        },
                    },
                },
                {
                    name: "sipRegistration",
                    label: "SIP Abacus Registration",
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta) => {
                            if (value) {
                                return (
                                    <div>
                                        <span style={{ display: value ? "block" : "none" }}>
                                            {
                                                this.state.customColumnValueForRegistrationCompleted
                                            }
                                        </span>
                                        <span style={{ display: value ? "none" : "block" }}>

                                            {
                                                this.state.customColumnValueForRegistrationNotCompleted
                                            }
                                        </span>
                                    </div>
                                );
                            }
                        },
                        display: props.examStatus === "completed" ? true : false
                    },

                },
                {
                    name: "promotedFrom",
                    label: "Promoted From",
                    options: {
                        filter: false,
                        display: props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound,
                    },
                },
                {
                    name: "examCompletedDt",
                    label: "Last Test Completed Date",
                    options: {
                        filter: false,
                        display: props.examStatus === 'practice' ? true:false,
                    },
                },

                {
                    name: "schoolNameVerifiedDisp",
                    label: "School Name Verified",
                    options: {
                        display:false,
                        filter: true,
                        filterType: "custom",
                        customFilterListOptions: {
                            render: (v) => v.map((l) => l.name),
                            update: (filterList, filterPos, index) => {
                                filterList[index].splice(filterPos, 1);
                                return filterList;
                            },
                        },
                        filterOptions: {
                            logic: (location, filters, row) => {
                                if (filters.length) return !filters.includes(location);
                                return false;
                            },
                            
                            display: (filterList, onChange, index, column) => {
                                let optionsForSchoolNameVerified = [
                                    { name: this.state.schoolNameVerifiedYes, value: "Yes" },
                                    { name: this.state.schoolNameVerifiedNo, value: "No" },
                                ];
                                return (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            id="dropdown-for-sip-abacus-student"
                                            disableClearable
                                            value={filterList[index]}
                                            onChange={(event, newValue) => {
                                                if (
                                                    filterList[index].length >= 1 &&
                                                    newValue.length > 0
                                                ) {
                                                    newValue.splice(0, 1);
                                                    filterList[index].splice(
                                                        0,
                                                        1,
                                                        newValue.splice(0, 1)[0]
                                                    );
                                                } else {
                                                    filterList[index] = newValue;
                                                }
                                                onChange(filterList[index], index, column);
                                            }}
                                            loadingText="Loading..."
                                            options={optionsForSchoolNameVerified}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return option;
                                                }

                                                return option.name;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.name === value.name
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="School Name Verified"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: "search" }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                );
                            },
                        },
                    },
                },

                {
                    name: "noOfAttempts",
                    label: "Number Of Attempts",
                    options: {
                        filter: false,
                        display:props.examStatus === 'practice' ? true:false,
                    },
                },


            ],
            isLoading: false,
            body: {
                childName: "",
                branchId:0,
                schoolName: "",
                classNumber: {
                    classId: 0,
                },
                
                examStatus: "",
                sipAbacusStudent: "",
                examCompletedStatus: props.examStatus,
                examinationId: props.propertiesNeededForStudentComponent.examinationId,
                groupId: props.propertiesNeededForStudentComponent.examGroupId,
                //schoolNameVerifiedDisp: ""
            },
            studentObj: {
                mode: "Edit",
                student: {},
            },
            open: false,
            anchorRef: React.createRef(),
            selectedIndex: 0,
          //  buttonOptions:
            //    props.examStatus === "completed"
           //         ? ["Schedule Workshop", "Paid & registered for SIP abacus",( this.state.roleName !== this.state.roleNameForLclAdmin )? `Promotion for ${props.propertiesNeededForStudentComponent.nextRound}`:'']
            //        : ["Activate Test"],
            openActiveTest: false,
            openScheduleWorkshop: false,
            openRegisterStudent: false,
            openPromotion: false,
            selectedRowsIndex: [],
            selectedStudentFromTable: [],
            openAlert: false,
            branchId: JSON.parse(localStorage.getItem("sip-user")).branch.branchId,
            orgId: JSON.parse(localStorage.getItem("sip-user")).organization
                .organizationId,
            alertMessage: "",
            alertSeverity: "",
            currentDateAndTimeFromServer: "",
            errorMessageForCsvFileDownload: "Failed to download the File!",
            studentEditPermissionFlag: false,
            studentDeletePermission: false,
            reActivateTestPermission: false,
            roleModuleAccessList: JSON.parse(localStorage.getItem("sip-user"))
                .roles[0].roleModuleAccess,
            studentEditPermission: () => {
                let editPermissionFlag = false;
                {
                    JSON.parse(
                        localStorage.getItem("sip-user")
                    ).roles[0].roleModuleAccess.map((item) => {
                        if (item.module.name === "Student Registration" && item.editFlag) {
                            editPermissionFlag = true;
                            this.setState({
                                studentEditPermissionFlag: true,
                            });
                        }
                        if (
                            item.module.name === "Student Registration" &&
                            item.deleteFlag
                        ) {
                            this.setState({
                                studentDeletePermission: true,
                            });
                        }
                        if (item.module.name === "Re Activate Test" && item.deleteFlag) {
                            this.setState({
                                reActivateTestPermission: true
                            })
                        }

                    });
                }
                return editPermissionFlag;
            },
            childNameLoading: false,
            schoolNameLoading: false,
            openPasswordResetDialog: false,
            studentAccountId: 0,
            studentName: "",
            examinationId: props.propertiesNeededForStudentComponent.examinationId,
            examGroupId: props.propertiesNeededForStudentComponent.examGroupId,
            setTotalStudentCount: props.setTotalStudentCount,
            displayPromotionApproveButton: props.propertiesNeededForStudentComponent.displayApproveButton,
            displayActiveTestButtonGroup: props.propertiesNeededForStudentComponent.displayActiveTestButtonGroup,
            nextRound: props.propertiesNeededForStudentComponent.nextRound,
            roleName: JSON.parse(localStorage.getItem("sip-user")).roles[0].name,
            roleNameForLclAdmin: 'LCL ADMIN',
            roleNameForAhAdmin: 'AREA HEAD',
            screenForStudentsSelectedToNextRound: props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound,
            promotionButtonLabel: `Promotion for ${props.propertiesNeededForStudentComponent.nextRound}`,
            buttonOptions:props.examStatus === "completed"
                ? ["Schedule Workshop","Paid & registered for SIP abacus",props.propertiesNeededForStudentComponent.nextRound ==='Round 4' ? 'Promotion' : `Promotion for ${props.propertiesNeededForStudentComponent.nextRound}`]
                :props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound?
                []
                : 
                (props.propertiesNeededForStudentComponent.nextRound ==="Round 4" && (JSON.parse(localStorage.getItem("sip-user")).roles[0].name==='LCL ADMIN') ||(JSON.parse(localStorage.getItem("sip-user")).roles[0].name==='AREA HEAD')) ?
                ["Schedule Workshop","Paid & registered for SIP abacus"]
                :["Activate Test","Schedule Workshop","Paid & registered for SIP abacus"],  
        };
        this.getData = this.getData.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.sort = this.sort.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
        this.closeActiveTest = this.closeActiveTest.bind(this);
        this.closeScheduleWorkShop = this.closeScheduleWorkShop.bind(this);
        this.closeRegisterAbacus = this.closeRegisterAbacus.bind(this);
        this.closePromotion = this.closePromotion.bind(this);
        this.setSelectedRow = this.setSelectedRow.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.getCurrentDateAndTimeFromServer = this.getCurrentDateAndTimeFromServer.bind(
            this
        );
        this.getStudentsList = this.getStudentsList.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.showDownloadPopUp = this.showDownloadPopUp.bind(this);
        this.closePasswordRestDialog = this.closePasswordRestDialog.bind(this);
        this.getSelectedStudentList = this.getSelectedStudentList.bind(this);
    }


    componentDidMount() {
        this.getData(
            this.state.pageNo,
            this.state.sortKey,
            this.state.sortDir,
            this.state.recordsPerPage
        );
        this.getCurrentDateAndTimeFromServer();
        let perm = this.state.studentEditPermission();

    }


    handleFilterSubmit = (applyFilters) => {
        let filterList = applyFilters();
        let selectedNameFilterValue = "";
        let selectedBranchNameFilterValue = "";
        let selectedBranchId = 0;
        let selectedClassId = 0;
        let selectedSchoolName = "";
        let registrationCompleted = true;
        let examStatus = "";
        let selectedSIPAbacusStudent = "";
        let schoolNameVerified =null;

        filterList[1].forEach((element) => {
            selectedNameFilterValue = element.childName;
        });
        filterList[6].forEach((element) => {
            selectedBranchNameFilterValue = element.name;
            selectedBranchId = element.branchId;
        });
        filterList[2].forEach((element) => {
            selectedClassId = element.classId;
        });
        filterList[4].forEach((element) => {
            selectedSchoolName = element.schoolName;
        });
        filterList[11].forEach((element) => {
            registrationCompleted = element.value;
        });
        filterList[14].forEach((element) => {
            examStatus = element;
        });
        filterList[23].forEach((element) => {
            selectedSIPAbacusStudent = element.value;
        });

        filterList[31].forEach((element) => {
            if(element.value === 'Yes'  || element.value === 'No'){
            schoolNameVerified = element.value;
            }
        });

        this.setState(
            {
                body: {
                    childName: selectedNameFilterValue,
                    // branch: {
                        branchName: selectedBranchNameFilterValue,
                        branchId: selectedBranchId,
                    //}
                    classNumber: {
                        classId: selectedClassId,
                    },
                    schoolName: selectedSchoolName,
                    registrationCompleted: registrationCompleted,
                    examStatus: examStatus,
                    sipAbacusStudent: selectedSIPAbacusStudent,
                    examCompletedStatus: this.state.isExamCompleted,
                    //examination: {
                        examinationId: this.state.examinationId,
                   // },
                    groupId: this.state.examGroupId,
                    schoolNameVerifiedDisp : schoolNameVerified
                },
            },
            () =>
                this.getData(
                    this.state.page,
                    this.state.sortKey,
                    this.state.sortDir,
                    this.state.recordsPerPage
                )
        );
    };

    async getData(pageNo, sortKey, sortDir, recordsPerPage) {
        this.setState({ isLoading: true, });
        await axios.get(
            (this.state.screenForStudentsSelectedToNextRound ? 
                this.state.BASE_URL + "/SIPStudents/promoted" : 
                this.state.BASE_URL + "/studentInfo"),
            {
                params: {
                    body: JSON.stringify(this.state.body),
                    pageNo: pageNo !== undefined ? pageNo : "",
                    sortKey: sortKey !== undefined ? sortKey : "",
                    sortDir: sortDir !== undefined ? sortDir : "",
                    recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
                },
                headers: {
                    authorization:
                        "Bearer " + localStorage.getItem("sip-authorization"),
                },
            }
        )
            .then((response) => {               
                this.setState({
                    data: response.data.content,
                    count: response.data.totalElements,
                });
                this.state.setTotalStudentCount(response.data.totalElements);

            });
        this.setState({ isLoading: false });
    }

    async getStudentsList(searchedChildName, searchedSchoolName) {
        this.setState({
            childNameList: [],
            schoolNameList: [],
        });
        if (searchedChildName !== null && searchedChildName !== "") {
            this.setState({
                childNameLoading: true,
            });
        } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
            this.setState({
                schoolNameLoading: true,
            });
        }
        await axios
            .get(
                (this.state.screenForStudentsSelectedToNextRound ?
                    this.state.BASE_URL + "/SIPStudents/promoted" : 
                    this.state.BASE_URL + "/studentInfo"),
                {
                    params: {
                        body: {
                            childName: searchedChildName,
                           // branch: {
                                branchName: "",
                                branchId: 0,
                           // },
                            schoolName: searchedSchoolName,
                            classNumber: {
                                classId: 0,
                            },
                            registrationCompleted: true,
                            examStatus: "",
                            sipAbacusStudent: "",
                            examCompletedStatus: this.state.isExamCompleted,
                           // examination: {
                                examinationId: this.state.examinationId,
                           // },
                            groupId: this.state.examGroupId,
                          //  schoolNameVerifiedDisp :""
                        },
                        pageNo: 0,
                        sortKey: "childName",
                        sortDir: "asc",
                        recordsPerPage: 100,
                    },
                    headers: {
                        authorization:
                            "Bearer " + localStorage.getItem("sip-authorization"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    childNameList: response.data.content,
                    schoolNameList: response.data.content,
                });
                if (searchedChildName !== null && searchedChildName !== "") {
                    this.setState({
                        childNameLoading: false,
                    });
                } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
                    this.setState({
                        schoolNameLoading: false,
                    });
                }
            });
    }

    async getSchoolNameList() {
        let responseToBeReturned = await axios
            .get(this.state.BASE_URL + `/schoolName`, {
                params: {
                    body: {
                        schoolName: "",
                        approved :true,
                        active:true,
                        state:{
                            stateId:0
                        }
                    },
                    sortKey: "schoolName",
                    sortDir: "asc",
                    recordsPerPage: 500,
                },
                headers: {
                    authorization: "Bearer " + localStorage.getItem("sip-authorization"),
                },
            })
            .then((response) => {
                return response;
            });
        return responseToBeReturned;
    }

    async getClassNumberList() {
        let responseToBeReturned = await axios
            .get(
                this.state.BASE_URL +
                `/organizations/${this.state.orgId}/branches/${this.state.branchId}/classes`,
                {
                    params: {
                        body: {
                            name: "",
                            examination:{
                                examinationId:1
                            }
                        },
                        sortKey: "name",
                        sortDir: "asc",
                    },
                    headers: {
                        authorization:
                            "Bearer " + localStorage.getItem("sip-authorization"),
                    },
                }
            )
            .then((response) => {
                return response;
            });
        return responseToBeReturned;
    }
    changePage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            recordsPerPage: rowsPerPage,
            pageNo: page,
            sortKey:
                sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
            sortDir:
                sortOrder.direction === undefined
                    ? this.state.sortDir
                    : sortOrder.direction,
        });
        this.setSelectedRow([]);
        this.getData(
            page,
            sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
            sortOrder.direction === undefined
                ? this.state.sortDir
                : sortOrder.direction,
            rowsPerPage
        );
    };

    changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({ recordsPerPage: rowsPerPage });
        this.getData(page, "", "", rowsPerPage);
    };

    sort = (page, sortOrder, rowsPerPage) => {
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    };

    handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
        this.setState(
            {
                pageNo: page,
                sortKey: sortOrder.name,
                sortDir: sortOrder.direction,
                body: {
                    childName: searchText != null ? searchText : "",
                    // branch: {
                        branchName: "",
                        branchId: 0,
                   // },
                    schoolName: "",
                    classNumber: {
                        classId: 0,
                    },
                    registrationCompleted: true,
                    sipAbacusStudent: "",
                    examCompletedStatus: this.state.examStatus,
                    // examination: {
                        examinationId: this.state.examinationId,
                  //  },
                    groupId: this.state.examGroupId,
                },
            },
            () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage)
        );
    };

    handleClick() {
       
        if (this.state.selectedRowsIndex.length > 0) {
            let selectedStudentFromTable = this.getSelectedStudentList();
            

            if (this.state.isExamCompleted === "completed") {
                this.setState({
                    openActiveTest: false,
                    openScheduleWorkshop: this.state.selectedIndex === 0 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 1 ? true : false,
                    openPromotion: this.state.selectedIndex === 2 ? true : false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            } 
            else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound === "Round 4" && (this.state.roleNameForLclAdmin===this.state.roleName||this.state.roleNameForAhAdmin===this.state.roleName) ) {
                this.setState({
                    openActiveTest:  false,
                    openScheduleWorkshop: this.state.selectedIndex === 0 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 1 ? true : false,
                    openPromotion:  false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            }
            else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound === "Round 4" && (this.state.roleNameForLclAdmin!==this.state.roleName && this.state.roleNameForAhAdmin !== this.state.roleName) ) {
                this.setState({
                    openActiveTest:  this.state.selectedIndex === 0 ? true : false,
                    openScheduleWorkshop: this.state.selectedIndex === 1 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 2 ? true : false,
                    openPromotion:  false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            }
            
            else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound === "Round 3" && this.state.roleNameForLclAdmin===this.state.roleName) {
                this.setState({
                    openActiveTest:  true,
                    openScheduleWorkshop: this.state.selectedIndex === 1 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 2? true : false,
                    openPromotion:  false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            }
            else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound === "Round 3" && this.state.roleNameForLclAdmin!==this.state.roleName) {
                this.setState({
                    openActiveTest:  this.state.selectedIndex === 0 ? true : false,
                    openScheduleWorkshop: this.state.selectedIndex === 1 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 2 ? true : false,
                    openPromotion:  false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            }  else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound === "Round 5" && (this.state.roleNameForLclAdmin!==this.state.roleName || this.state.roleNameForAhAdmin==this.state.roleName)) {
                this.setState({
                    openActiveTest:  this.state.selectedIndex === 0 ? true : false,
                    openScheduleWorkshop: this.state.selectedIndex === 1 ? true : false,
                    openRegisterStudent: this.state.selectedIndex === 2 ? true : false,
                    openPromotion:  false,
                    selectedStudentFromTable: selectedStudentFromTable,
                    openAlert: false,
                });
            }
          //  else if(this.state.isExamCompleted === "notCompleted" && this.state.nextRound==="Round 3") {
            //    this.setState({
          //          openActiveTest:  this.state.selectedIndex === 0 ? true : false,
            //        openScheduleWorkshop: this.state.selectedIndex === 1 ? true : false,
            //        openRegisterStudent: this.state.selectedIndex === 2 ? true : false,
             //       openPromotion: false,
              //      selectedStudentFromTable: selectedStudentFromTable,
              //      openAlert: false,
               // });
           // }
           
        } else {
            this.setState({
                openAlert: true,
                alertMessage: "Please select at least one student",
                alertSeverity: "error",
            });
        }
    }

    getSelectedStudentList() {
        let selectedStudentFromTable = [];
        for (
            let index = 0;
            index < this.state.selectedRowsIndex.length;
            index++
        ) {
            selectedStudentFromTable.push(
                this.state.data[this.state.selectedRowsIndex[index]]
            );
        }
        return selectedStudentFromTable;
    }

    sipRegistration(studentObj) {
        let selectedStudentFromTable = [];
        selectedStudentFromTable.push(studentObj);
        this.setState({
            openRegisterStudent: true,
            selectedStudentFromTable: selectedStudentFromTable
        })


    }


    handleMenuItemClick(event, index) {
        this.setSelectedRow([]);
        this.componentDidMount();
        this.setState({
            selectedIndex: index,
            open: false,
            selectedRowsIndex: [],
            openAlert: false,
        });
    }

    handleToggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    handleClose(event) {
        if (
            this.state.anchorRef.current &&
            this.state.anchorRef.current.contains(event.target)
        ) {
            return;
        }

        this.setState({
            open: false,
        });
    }

    closeScheduleWorkShop(isClose) {
        if (isClose) {
            this.setState({
                openScheduleWorkshop: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
                openAlert: true,
                alertMessage: "Workshop scheduled successfully",
                alertSeverity: "success",
            });
        } else {
            this.setState({
                openScheduleWorkshop: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
            });
        }
        this.componentDidMount();
    }

    closeRegisterAbacus(isClose) {
        if (isClose) {
            this.setState({
                openRegisterStudent: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
                openAlert: true,
                alertMessage: "Registration completed Successfully",
                alertSeverity: "success",
            });
        } else {
            this.setState({
                openRegisterStudent: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
            });
        }

        this.componentDidMount();
    }

    closePromotion(isClose) {
        if (isClose) {
            this.setState({
                openPromotion: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
                openAlert: true,
                alertMessage: `Promoted For ${this.state.nextRound} Successfully`,
                alertSeverity: "success",
            });
            this.componentDidMount();
        } else {
            this.setState({
                openPromotion: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
            });
            this.componentDidMount();
        }
    }

    closeDialog(isClosed) {
        if (isClosed) {
            this.setState({
                openEdit: false,
                openAlert: true,
                alertMessage: "Updated Successfully",
                alertSeverity: "success",
            });
            this.setSelectedRow([]);
        } else {
            this.setState({ openEdit: false });
        }

        this.componentDidMount();
    }

    closeConfirmationDialog(confirmed) {
        if (confirmed) {
            if (this.state.confirmationType === "delete") {
                const BASE_URL = `${process.env.REACT_APP_API_URL}`;
                axios
                    .post(
                        BASE_URL +
                        "/disableStudents/" +
                        this.state.transactionObj.studentId,
                        {},
                        {
                            headers: {
                                authorization:
                                    "Bearer " + localStorage.getItem("sip-authorization"),
                            },
                        }
                    )
                    .then((Response) => {
                        this.setState({
                            openConfirmationDialog: false,
                            openAlert: true,
                            alertMessage: "Deleted successfully",
                            alertSeverity: "success",
                        });
                        this.componentDidMount();
                    });
            } else if (this.state.confirmationType === "resend") {
                var self = this;
                const BASE_URL = `${process.env.REACT_APP_API_URL}`;
                axios
                    .get(
                        BASE_URL +
                        "/student/" +
                        this.state.transactionObj.studentId +
                        "/email",
                        {
                            params: {
                                emailType:
                                    this.state.isExamCompleted === "completed"
                                        ? "result"
                                        : "registration",
                                 examGroupId: this.state.examGroupId,
                            },
                            headers: {
                                authorization:
                                    "Bearer " + localStorage.getItem("sip-authorization"),
                            },
                        }
                    )
                    .then((response) => {
                        this.setState({
                            openConfirmationDialog: false,
                            openAlert: true,
                            alertMessage: "Resent Email Successfully",
                            alertSeverity: "success",
                        });
                        this.componentDidMount();
                    })
                    .catch(function (error) {
                        self.setState({
                            openConfirmationDialog: false,
                            openAlert: true,
                            alertMessage: "Failed to send mail",
                            alertSeverity: "error",
                        });
                        self.componentDidMount();
                    });
            } else if (this.state.confirmationType === 'reSchedule') {

                var self = this;
                const BASE_URL = `${process.env.REACT_APP_API_URL}`;
                axios
                    .get(
                        BASE_URL +
                        "/student/" +
                        this.state.transactionObj.studentId +
                        "/exam",
                        {
                            params: {
                                 examGroupId: this.state.examGroupId,
                            },
                            headers: {
                                authorization:
                                    "Bearer " + localStorage.getItem("sip-authorization"),
                            },
                        }
                    )
                    .then((response) => {
                        this.setState({
                            openConfirmationDialog: false,
                            openAlert: true,
                            alertMessage: "Re-scheduled Exam Successfully",
                            alertSeverity: "success",
                        });
                        this.componentDidMount();
                    })
                    .catch(function (error) {
                        self.setState({
                            openConfirmationDialog: false,
                            openAlert: true,
                            alertMessage: "Failed re-schedule exam",
                            alertSeverity: "error",
                        });
                        self.componentDidMount();
                    });

            }
        } else {
            this.setState({
                openConfirmationDialog: false,
            });
        }
    }

    closeActiveTest(isClose) {
        if (isClose) {
            this.setState({
                openActiveTest: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
                openAlert: true,
                alertMessage: "Batch scheduled successfully",
                alertSeverity: "success",
            });
        } else {
            this.setState({
                openActiveTest: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
            });
        }
        this.componentDidMount();
    }

    closeEditStudent(isClose) {
        if (isClose) {
            this.setState({
                openEdit: false,
                openAlert: true,
                alertMessage: "Records successfully",
                alertSeverity: "success",
            });
        } else {
            this.setState({
                openEdit: false,
            });
        }
        this.componentDidMount();
    }

    setSelectedRow(rowsSelected) {
        this.setState({
            selectedRowsIndex: rowsSelected,
        });
    }

    handleAlertClose() {
        this.setState({ openAlert: false });
    }

    getCurrentDateAndTimeFromServer() {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        axios
            .get(BASE_URL + "/SIPCustomApi/serverCurrentDateAndTime", {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("sip-authorization"),
                },
            })
            .then((response) => {
                this.setState({
                    currentDateAndTimeFromServer: response.data,
                });
            })
            .catch(function (error) { });
    }

    handleDownload = (filename) => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        this.setState({
            openAlert: true,
            alertMessage: "Requested for CSV file download",
            alertSeverity: "success",
        });

        axios
            .post(
                BASE_URL + "/students/report",
                {
                    childName: "",
                    branch: {
                        branchId: 0,
                    },
                    schoolNameString: "",
                    schoolArea: "",
                    classNumber: {
                        classId: 0,
                    },
                    schoolName :{
                        schoolId :0,
                        schoolName:""
                    },
                    registrationCompleted: true,
                    examCompletedStatus: this.state.examStatus,
                    examStatus: "",
                    examination: {
                        examinationId: this.state.examinationId,
                    },
                    examGroupId: this.state.examGroupId,
                    isPromotionTab:this.state.screenForStudentsSelectedToNextRound ? true:false
                },
                {
                    headers: {
                        authorization:
                            "Bearer " + localStorage.getItem("sip-authorization"),
                    },
                }
            )
            .then((response) => {
                if (response.data.sucess) {
                  //  this.showDownloadPopUp(response.data.fileUrl);
                	  this.setState({
                          openAlert: true,
                          alertMessage : response.data.messageKey,
                          alertSeverity: "success",
                        });
                } else {
                    this.setState({
                        openAlert: true,
                        alertMessage: this.state.errorMessageForCsvFileDownload,
                        alertSeverity: "error",
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    openAlert: true,
                    alertMessage: this.state.errorMessageForCsvFileDownload,
                    alertSeverity: "error",
                });
            });
    };

    handleAnswerSheetDownload = (studentId,childName) => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        this.setState({
            openAlert: true,
            alertMessage: "Requested for Answer Sheet Download",
            alertSeverity: "success",
        });

        axios
            .post(
                BASE_URL + "/students/"+studentId+"/questionPapers/",
                {
                    studentName: childName,
                },
                {
                    headers: {
                        authorization:
                            "Bearer " + localStorage.getItem("sip-authorization"),
                    },
                }
            )
            .then((response) => {
                if (response.data.sucess) {
                    this.showDownloadPopUp(response.data.fileUrl);
                } else {
                    this.setState({
                        openAlert: true,
                        alertMessage: this.state.errorMessageForCsvFileDownload,
                        alertSeverity: "error",
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    openAlert: true,
                    alertMessage: this.state.errorMessageForCsvFileDownload,
                    alertSeverity: "error",
                });
            });
    };

    handleCertificateDownload = (studentId,examGroupId) => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        this.setState({
            openAlert: true,
            alertMessage: "Requested for Certificate Download",
            alertSeverity: "success",
        });

        axios
            .post(
                BASE_URL + "/SIPCustomApi/downloadCertificate",
                {
                    studentId: studentId,examGroupId: examGroupId,
                },
                {
                    headers: {
                        authorization:
                            "Bearer " + localStorage.getItem("sip-authorization"),
                    },
                }
            )
            .then((response) => {
                if (response.data.sucess) {
                    this.showDownloadPopUp(response.data.fileUrl);
                } else {
                    this.setState({
                        openAlert: true,
                        alertMessage: this.state.errorMessageForCsvFileDownload,
                        alertSeverity: "error",
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    openAlert: true,
                    alertMessage: this.state.errorMessageForCsvFileDownload,
                    alertSeverity: "error",
                });
            });
    };

    showDownloadPopUp = (filePath) => {

        const fileName = filePath.split("/")[filePath.split("/").length - 1].trim();
        fetch(
            `${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePath}`,
            {
                method: "GET",
                headers: {
                    authorization: "Bearer " + localStorage.getItem("sip-authorization"),
                },
            }
        )
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                this.setState({
                    openAlert: true,
                    alertMessage: "File downloaded successfully",
                    alertSeverity: "success",
                });
                saveAs(blob, fileName);
            })
            .catch((error) => {
                this.setState({
                    openAlert: true,
                    alertMessage: this.state.errorMessageForCsvFileDownload,
                    alertSeverity: "error",
                });
            });
    };

    async loadBranchData() {
        let branchListToBeReturned = axios
            .get(this.state.BASE_URL + "/branches", {
                params: {
                    body: JSON.stringify({
                        name: "",
                        branchId: 0,
                        address: {
                            countryId: 1,
                            stateId: 0,
                            cityId: 0,
                        },
                    }),
                },

                headers: {
                    authorization: "Bearer " + localStorage.getItem("sip-authorization"),
                },
            })
            .then((response) => {
                return response;
            });
        return branchListToBeReturned;
    }

    closePasswordRestDialog(isClose) {
        if (isClose) {
            this.setState({
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
                openAlert: true,
                alertMessage: "Password updated successfully",
                alertSeverity: "success",
                openPasswordResetDialog: false,
            });
            this.componentDidMount();
        } else {
            this.setState({
                openPasswordResetDialog: false,
                selectedStudentFromTable: [],
                selectedRowsIndex: [],
            });
            this.componentDidMount();
        }

    }



    render() {
        
        const { count, rowsPerPage, sortOrder } = this.state;

        const options = {

            // customRowRender: (data, dataIndex, rowIndex) => {
            //     let style = {};
            //     if (data[rowIndex].sipAbacusStudent === "") {
            //       style.backgroundColor = "green";
            //     }},
            filter: true,
            filterType: "dropdown",
            // responsive: "standard",
            vertical: true,
            fixedHeader: true,
            fixedSelectColumn: true,
            tableBodyHeight: (window.screen.height - 130),
            denseTable: true,
            serverSide: true,
            setTableProps: (index) => {
                return {
                    padding: '1px',
                    horizontal: true,
                    scrollEnabled: false,
                    textAlign: 'left'
                };
            },
            downloadOptions: {
                filename: "StudentTable.csv",
                //   new API change added here
                customCSVdata: this.state.data,
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                this.handleDownload(); //this makes a REST call to the server and downloads the data
                return false;
            },
            count: count,
            rowsPerPage: this.state.recordsPerPage,
            rowsPerPageOptions: [100, 200, 500],
            sortOrder: sortOrder,
            confirmFilters: true,
            rowHover: true,
            caseSensitive: false,
            enableNestedDataAccess: ".",
            expandableRowsOnClick: true,
            selectableRowsHeader: true,
            selectToolbarPlacement: "none",
            selectableRows: "multiple",
            searchOpen: true,
            print: false,
            responsive: 'response',
            //   setRowProps: (row) => {
            //           return {
            //               style:{ visibility: row[11] !== 'completed' && this.state.buttonOptions[this.state.selectedIndex] === 'Schedule Workshop' ? 'hidden' : 'visible'},
            //           }
            //   },

            setRowProps: (row) => {
                return {
                 //   style:{ backgroundColor :(row[23] === 'Yes' || row[23] === 'No' ) ?'white':'#ff9999'},
                }
            },
            onFilterChipClose: (column, filterList, type) => {
                var newFilters = () => type;
                this.handleFilterSubmit(newFilters);
            },
            onFilterDialogClose: () => {
                this.componentDidMount();
            },
            isRowSelectable: (dataIndex) => {
                if(this.state.data[dataIndex] && this.state.data[dataIndex].examStatus === "completed" && this.state.screenForStudentsSelectedToNextRound)
                return true;

                if (this.state.data[dataIndex] ) {
                    return (
                        (!this.state.data[dataIndex].registrationCompleted &&
                        !this.state.data[dataIndex].sipAbacusStudent === null &&
                        this.state.buttonOptions[this.state.selectedIndex] ===
                        "Activate Test") ||
                        ((this.state.data[dataIndex].sipAbacusStudent === null ||
                            !this.state.data[dataIndex].sipAbacusStudent === "") &&
                            this.state.buttonOptions[this.state.selectedIndex] ===
                            "Activate Test") ||
                         (this.state.data[dataIndex].examStatus === "completed" &&
                             this.state.buttonOptions[this.state.selectedIndex] ===
                             "Activate Test") ||
                            
                       
                            (this.state.data[dataIndex].examStatus===this.state.examStatusForPromotedToR2 &&
                            this.state.buttonOptions[this.state.selectedIndex] ===
                            "Promotion for Round 2") ||
                             
                       (!this.state.data[dataIndex].active &&
                           this.state.buttonOptions[this.state.selectedIndex] ===
                           "Schedule Workshop") 
                       
                         //   (this.state.data[dataIndex].sipRegistration &&
                          //  this.state.buttonOptions[this.state.selectedIndex] ===
                          //  "Paid & registered for SIP abacus") 
                            // ||
                            // (this.state.data[dataIndex].workshop === null
                            //     && this.state.buttonOptions[this.state.selectedIndex] ===
                            //     "Paid & registered for SIP abacus")
                        
                       //  ||
                       // (this.state.data[dataIndex].sipRegistration &&
                       //     this.state.buttonOptions[this.state.selectedIndex] ===
                       //     "Schedule Workshop" && ! this.state.screenForStudentsSelectedToNextRound) 
                    )  
                       // ((this.state.data[dataIndex].examStatus !== "completed") &&
                       //     this.state.buttonOptions[this.state.selectedIndex] ===
                       //     this.state.promotionButtonLabel)
                        
                        ? false
                        : true;
                } else {
                    return true;
                }
            },
            onRowSelectionChange: (
                currentRowsSelected,
                allRowsSelected,
                rowsSelected
            ) => {
                this.setSelectedRow(rowsSelected);
            },
            onRowClick: (rowData, rowState) => { },
            customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                return (
                    <div style={{ marginTop: "40px" }}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.handleFilterSubmit(applyNewFilters)}
                                >
                                    Filter
                </Button>
                            </Grid>
                        </Grid>
                    </div>
                );
            },

            //   callback that gets executed when filters are confirmed
            onFilterConfirm: (filterList) => { },

            onFilterDialogOpen: () => { },
            searchPlaceholder: "Search by Child Name...",
            onTableChange: (action, tableState) => {
                switch (action) {
                    case "changePage":
                        this.changePage(
                            tableState.page,
                            tableState.sortOrder,
                            tableState.rowsPerPage
                        );
                        break;
                    case "sort":
                        this.sort(
                            tableState.page,
                            tableState.sortOrder,
                            tableState.rowsPerPage
                        );
                        break;
                    case "changeRowsPerPage":
                        this.changeRecordPerPage(
                            tableState.page,
                            tableState.sortOrder,
                            tableState.rowsPerPage
                        );
                        break;
                    case "search":
                        this.handleSearch(
                            tableState.searchText,
                            tableState.page,
                            tableState.sortOrder,
                            tableState.rowsPerPage
                        );
                        break;
                    default:
                }
            },
        };

        return (
            <div className="container">
                <CssBaseline />
                <Grid container spacing={1} style={{ overflow: 'hidden' }}>
                    <Grid
                        container
                        xs={12}
                        md={12}
                        lg={12}
                        sm={12}
                        xl={12}
                        justify="flex-end"
                    >

                        { this.state.displayPromotionApproveButton && 
                        !(this.state.roleName == this.state.roleNameForLclAdmin || (this.state.nextRound==='Round 3' && this.state.roleName === this.state.roleNameForAhAdmin))&& 
                        (
                            <Button variant='contained' color='primary' onClick={(() => {
                               // console.log(this.state.buttonOptions[this.state.selectedIndex]);
                                if (this.state.selectedRowsIndex.length > 0) {
                                    this.setState({
                                        openPromotion: true,
                                        selectedStudentFromTable: this.getSelectedStudentList(),
                                        openAlert: false,
                                    })
                                } else {
                                    this.setState({
                                        openAlert: true,
                                        alertMessage: "Please select at least one student",
                                        alertSeverity: "error",
                                    });
                                }

                            })}>
                                Approve
                            </Button>
                        )}

                        {this.state.currentLevel !==0 && this.state.displayActiveTestButtonGroup && (<ButtonGroup
                            variant="contained"
                            color="primary"
                            ref={this.state.anchorRef}
                            aria-label="split button"
                        >
                            
                            <Button onClick={this.handleClick}>
                                {this.state.buttonOptions[this.state.selectedIndex]}
                            </Button>

                            <Button
                                color="primary"
                                size="small"
                                aria-controls={
                                    this.state.open ? "split-button-menu" : undefined
                                }
                                aria-expanded={this.state.open ? "true" : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={this.handleToggle}
                            >
                                <ArrowDropDownIcon />
                            </Button> 
                        </ButtonGroup>)}

                        <Popper
                            style={{ zIndex: 1000 }}
                            open={this.state.open}
                            anchorEl={this.state.anchorRef.current}
                            role={undefined}
                            transition="false"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper elevation={10}>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <MenuList id="split-button-menu" variant="menu">
                                                {this.state.buttonOptions.map((option, index) => (
                                                    <MenuItem
                                                        key={option}
                                                        selected={index === this.state.selectedIndex}
                                                        disabled={
                                                            
                                                            // (this.state.buttonOptions[index] === "Activate Test" && this.state.roleName === this.state.roleNameForLclAdmin && this.state.nextRound==="Round 4")
                                                              
                                                    
                                                           // ||
                                                            (this.state.buttonOptions[index] === "Promotion for Round 3" && this.state.roleName === this.state.roleNameForLclAdmin )
                                                            || (this.state.buttonOptions[index] === "Promotion for Round 4" 
                                                            //&& (this.state.roleName === this.state.roleNameForLclAdmin || this.state.roleName === //this.state.roleNameForAhAdmin)
                                                            )
                                                        
                                                             
                                                        }

                                                        onClick={(event) =>
                                                            this.handleMenuItemClick(event, index)
                                                        }
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>

                        <RegisterForSIPAbacusList
                            open={this.state.openRegisterStudent}
                            onClose={this.closeRegisterAbacus}
                            selectedStudentFromTable={this.state.selectedStudentFromTable}
                            currentDateAndTimeFromServer={
                                this.state.currentDateAndTimeFromServer
                            }
                        />

                        <PromotionList
                            open={this.state.openPromotion}
                            onClose={this.closePromotion}
                            selectedStudentFromTable={this.state.selectedStudentFromTable}
                            currentDateAndTimeFromServer={
                                this.state.currentDateAndTimeFromServer
                            }
                            nextExamGroupId={this.state.examGroupId + 1}
                        />

                        <ActivateTestDialog
                            open={this.state.openActiveTest}
                            onClose={this.closeActiveTest}
                            selectedStudentFromTable={this.state.selectedStudentFromTable}
                            currentDateAndTimeFromServer={
                                this.state.currentDateAndTimeFromServer
                            }
                            examGroupId={this.state.examGroupId}
                            examinationId={this.state.examinationId}
                        />

                        <Workshops
                            open={this.state.openScheduleWorkshop}
                            onClose={this.closeScheduleWorkShop}
                            selectedStudentFromTable={this.state.selectedStudentFromTable}
                            currentDateAndTimeFromServer={
                                this.state.currentDateAndTimeFromServer
                            }
                        />

                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            open={this.state.openAlert}
                            autoHideDuration={5000}
                            onClose={this.handleAlertClose}
                            key="top center"
                        >
                            <Alert
                                onClose={this.handleAlertClose}
                                severity={this.state.alertSeverity}
                            >
                                {this.state.alertMessage}
                            </Alert>
                        </Snackbar>
                        <EditStudent
                            onClose={this.closeDialog}
                            studentObject={this.state.studentObj}
                            open={this.state.openEdit}
                            studentEditPermissionFlag={this.state.studentEditPermissionFlag}
                        />
                        <ConfirmationDialog
                            onClose={this.closeConfirmationDialog}
                            message={this.state.message}
                            open={this.state.openConfirmationDialog}
                        ></ConfirmationDialog>
                        <PasswordRest
                            onClose={this.closePasswordRestDialog}
                            studentAccountId={this.state.studentAccountId}
                            studentName={this.state.studentName}
                            transactionObj={this.state.transactionObj}
                            open={this.state.openPasswordResetDialog}
                        ></PasswordRest>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sm={12} xl={12} >
                        <MUIDataTable showsHorizontalScrollIndicator={false} style={{ overflowX: 'hidden' }}
                            title={
                                <Typography variant="h6">
                                    Students
                  {this.state.isLoading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ marginLeft: 15, position: "relative", top: 4 }}
                                        />
                                    )}
                                </Typography>
                            }
                            data={this.state.data}
                            columns={this.state.columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default StudentList;
