import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";



const Copyright = (props) => {
    const version = `${process.env.REACT_APP_VERSION}`;
    return (
        
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {` |  ${version} | `}
            <Link color="inherit" to={{ pathname: props.companyURL }} target="_blank">
                {props.companyName}
            </Link>
        </Typography>

    );
}

Copyright.propTypes = {
    companyName : PropTypes.string.isRequired,
    companyURL : PropTypes.string.isRequired,
};

export default Copyright;