import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';


const CustomTheme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: blue,
  },
});

export default CustomTheme;