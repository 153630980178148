import React from "react";
import {
  Grid,
  makeStyles,
  CssBaseline,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import StudentLoginBackground from "./student-login.svg";
import Copyright from "../../Common/Copyright";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../Examination/contestLanding/images/sip_abacus_logo.png";
import sipArithmeticLogo from "../../Examination/contestLanding/images/logo.jpg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import prodigyLogo from "./prodigyLogo.jpg";
import sipAGClogo2022 from "../../Examination/contestLanding/images/SIP_AGC_Logo_2022.png";
import sipAGC2023LOGO from "../../Examination/contestLanding/images/SIP_AGC_LOGO_2023.png";
import sipAGC2024LOGO from "../../Examination/contestLanding/images/SIP_AGC_LOGO_2024.png";

StudentLogin.propTypes = {};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  image: {
    height: "100vh",
    backgroundImage: `url(${StudentLoginBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      //alignment with respect to image on laptops
      paddingLeft: theme.spacing(8)
    },
    [theme.breakpoints.down("sm")]: {
      //alignment with respect to image on laptops
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      opacity: 0.95
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background:
      "linear-gradient(to right, #cb2d3e, #ef473a)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    opacity: 1
  }
}));

function StudentLogin(props) {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors, getValues } = useForm();
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [examName, setExamName] = React.useState(props.examination);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: ""
  });

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { vertical, horizontal, open, message, severity } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const onSubmit = data => {
    axios
      .post(BASE_URL + "/authenticate/student", data)
      .then(response => {
        setState({
          ...state,
          open: true,
          message: "Login successful, redirecting to Dashboard!",
          severity: "success"
        });
        localStorage.setItem("authorization", response.data.token);
        var decodedJwt = jwt_decode(response.data.token);
        if (props.examination === "agc" && decodedJwt.examination === "agc") {
          history.push("/student/portal/dashboard", {
            studentID: decodedJwt.uid,
            studentName: decodedJwt.dn,
            studentLoginId: decodedJwt.sub,
            examination: props.examination,
            examCompleted: decodedJwt.examCompleted,
            noOfAttempts: decodedJwt.noOfAttempts
          });
        } else if (
          props.examination === "prodigy" &&
          decodedJwt.examination === "prodigy"
        ) {
          history.push("/student/portal/dashboard", {
            studentID: decodedJwt.uid,
            studentName: decodedJwt.dn,
            studentLoginId: decodedJwt.sub,
            examination: props.examination,
            examCompleted: decodedJwt.examCompleted,
            noOfAttempts: decodedJwt.noOfAttempts
          });
        } else {
          setState({
            ...state,
            open: true,
            message: "Login failed, Invalid Login ID or Password!",
            severity: "error"
          });
        }
      })
      .catch(error => {
        setState({
          ...state,
          open: true,
          message: "Login failed, Invalid Login ID or Password!",
          severity: "error"
        });
      });
  };

  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container className={classes.image} alignItems="center">
        <Grid item xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}> */}
            {/* <LockOutlinedIcon /> */}
            <img
              src={sipAGC2024LOGO}
              alt=""
              style={{
                height: "35%",
                width: "55%",
                display: examName === "agc" ? "block" : "none"
              }}
            />
            {/*<img src={prodigyLogo} alt="" style={{ height: "35%", width: "55%",display:examName === 'prodigy'?'block':'none'  }} />*/}

            {/* </Avatar> */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Login ID"
                name="username"
                autoComplete="username"
                autoFocus
                inputRef={register({ required: true, minLength: 1 })}
                error={errors.username ? true : false}
                onInput={toInputUppercase}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                autoComplete="password"
                inputRef={register({ required: true, minLength: 3 })}
                error={errors.password ? true : false}
                onInput={toInputUppercase}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              {/* <Grid item container justify="center"> */}
              <Typography style={{ textAlign: "center" }}>
                Powered By{" "}
              </Typography>{" "}
              <img
                src={logo}
                style={{ height: "20%", width: "40%", display: "inline-block" }}
              />
              {/* </Grid> */}
              {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                     </Link>
                                </Grid>
                            </Grid> */}
              <Box mt={5}>
                <Copyright
                  companyName={"EaziBiz Technologies."}
                  companyURL={"https://eazibiztech.com/"}
                />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default StudentLogin;
