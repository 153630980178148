import React from "react";

import {
  makeStyles,
  useTheme,
  Grid,
  Paper,
  useMediaQuery,
  Button,
  ListItemText,
  List,
  ListItem,
  Typography,
  AppBar,
  CssBaseline
} from "@material-ui/core";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/sip-academy-logo1.png";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
import agcDoll from "./images/DOLL_P4.svg";
import backgroudImage from "./images/BG_Page_04.svg";
import mobileBG from "./images/mobileBG.svg";
//import backgroudImage2022 from "./images/BG_Page_2022.png";
import mobileBG2022 from "./images/Arithmetic_Genius_Contest_Mobile_BG.jpg";
import backgroudImage2022 from "./images/BG_2022_AGC.jpg";
import agc2023mobileLogo from "./images/Bg_2023_mobile.png";
import agc2023WebLogo from "./images/Bg_2023_web.png";
import agc2024mobileLogo from "./images/Bg_2024_mobile.png";
import agc2024WebLogo from "./images/Bg_2024_web.png";

const useStyles = makeStyles(theme => ({
  aboutAGCRoot: {
    backgroundColor: "white",
    alignContent: "stretch",
    flexWrap: "nowrap",
    height: "100%",
    width: "100%"
  },
  aboutAGCPaper: {
    width: "180%",
    height: "125vh",
    backgroundImage: `url(${agc2024WebLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  aboutAGCTitle: {
    textAlign: "center",
    fontSize: theme.spacing(4),
    color: "white",
    fontWeight: "bold",
    marginTop: "80px"
  },
  aboutAGCContent: {
    textAlign: "left",
    fontSize: theme.spacing(3),
    color: "#fafafa",
    marginTop: theme.spacing(2),
    whiteSpace: "pre-line"
  },
  aboutAGCBox: {
    border: "4px solid #E62127",
    borderRadius: "4px",
    padding: "3px 5px",
    textAlign: "center",
    shadowColor: "#F5E740",
    shadowOffset: {
      width: 0,
      height: 12
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,
    whiteSpace: "pre-line",
    //color: "black",
    fontWeight: "bold",
    fontSize: "16px"
  },
  secondTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  mobileWorkFlowPaper: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${agc2024WebLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none"
  },

  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center"
  },
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    height: "85%",
    whiteSpace: "pre-line",
    display: "inline-block",
    fontSize: theme.spacing(2),
    padding: theme.spacing(3)
  },
  agcText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000000"
  }
}));

const AGCWorkFlow = () => {
  const classes = useStyles();
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const title = "About Arithmetic Genius Contest - 2024";
  const register = "SAMPLE QUESTION PAPER";

  function Content() {
    if (smallScreen) {
      return (
        <React.Fragment>
          <Grid item container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} style={{ textAlign: "left" }}>
              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>
                  Preliminary Round{" "}
                </Typography>
              </div>
              <List style={{ textAlign: "justify" }}>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      The contest is intended for the students of Classes 1 to 5
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      <b> After Round 1 Top 30% </b> of Students from each class
                      qualify for Round 2.
                      <br />
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      The Preliminary school level contests will take place
                      between June – August, 2024.
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>

              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>
                  State / Regional Level
                </Typography>
              </div>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      From Round 2, Top 5 students from each class (25 students
                      from each school) will be selected to the State Round
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      The State Finals will be held in the month of
                      <b> October, 2024.</b>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>

              <div className={classes.aboutAGCBox}>
                <Typography className={classes.agcText}>
                  National Level{" "}
                </Typography>
              </div>
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      The State Toppers from each class qualifies to National
                      Finals.
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.agcText}>
                      The National Finals will be held in the month of
                      <b> December, 2024. </b>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid
              container
              xs={12}
              sm={12}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/*<img src={agcDoll} alt="doll" />*/}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid container>
            <Grid item md={3} lg={3}>
              {/* <img src={agcDoll} alt="doll"  style={{ marginTop:theme.spacing(23),backgroundSize: "cover"}}/> */}
            </Grid>
            <Grid item md={6} lg={6}>
              <Grid item container justify="center" spacing={4}>
                <Grid item md={4} lg={4}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>
                      Preliminary Round{" "}
                    </Typography>
                  </div>
                  <List>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          The contest is intended for the students of
                          <b>&nbsp;classes 1 to 5. </b>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          <b> After Round 1 </b> Top 30% of Students from each
                          class qualify for Round 2.
                          <br />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          The Preliminary school level contests will take place
                          between June – August, 2024.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item md={4} lg={4} style={{ textAlign: "left" }}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>
                      State / Regional Level
                    </Typography>
                  </div>
                  <List>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          From Round 2, Top 5 students from each class (25
                          students from each school) will be selected to the
                          State Round
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          The State Finals will be held in the month of
                          <b> October, 2024</b>.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>

                <Grid item md={4} lg={4} style={{ textAlign: "left" }}>
                  <div className={classes.aboutAGCBox}>
                    <Typography className={classes.agcText}>
                      National Level
                    </Typography>
                  </div>
                  <List>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          The State Toppers from each class qualifies to
                          National Finals.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        <Typography className={classes.agcText}>
                          The National Finals will be held in the month of
                          <b> December, 2024</b>.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} lg={3}></Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container justifyContent="center" alignItems="center">
        <Paper
          className={
            smallScreen ? classes.mobileWorkFlowPaper : classes.aboutAGCPaper
          }
        >
          <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
            <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
              <Grid item md={3} xs={3} lg={3} sm={3} xl={3}></Grid>
              <Grid item md={4} xs={9} sm={9} lg={4} xl={4}>
                <img
                  src={sipAbacusLogo}
                  alt="logo"
                  height={smallScreen ? "60%" : "50%"}
                  width={smallScreen ? "60%" : "30%"}
                />
              </Grid>
              <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
            </AppBar>
          </Grid>

          <Grid item container>
            <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
            <Grid
              item
              md={2}
              xs={4}
              sm={4}
              lg={2}
              className={classes.container}
              justifyContent="flex-start"
            >
              <img
                src={agcLogo}
                alt="Logo"
                style={{ height: smallScreen ? "80%" : "60%", width: "60%" }}
              />
            </Grid>
            <Grid item md={6} xs={1} sm={1} lg={6} xl={6}></Grid>

            <Grid item md={3} xs={6} sm={6} lg={3} xl={6}>
              {smallScreen ? (
                ""
              ) : (
                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              )}
              <Grid item container style={{ marginTop: theme.spacing(3) }}>
                <Grid item container style={{ marginTop: theme.spacing(3) }}>
                  <Grid
                    item
                    md={3}
                    xs={2}
                    sm={2}
                    lg={3}
                    xl={3}
                    alignContent="flex-end"
                  >
                    <img
                      src={plusLeft}
                      alt="plus"
                      height="50%"
                      width="50%"
                      style={{
                        marginLeft: smallScreen
                          ? theme.spacing(1)
                          : theme.spacing(5)
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={8} sm={8} lg={6} xl={6}>
                    <a
                      href="#sampleQuestionPaper"
                      className={classes.registrationPageLink}
                    >
                      <Button
                        style={{
                          width: "100%",
                          fontSize: smallScreen ? "10px" : "14px"
                        }}
                        variant="contained"
                        className={classes.button}
                      >
                        {register}
                      </Button>
                    </a>
                  </Grid>
                  <Grid item md={3} sm={2} lg={3} xs={2} xl={3}>
                    <img
                      src={plusRight}
                      alt="plus"
                      height="50%"
                      width="50%"
                      style={{}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            md={12}
            xs={12}
            lg={12}
            sm={12}
            xl={12}
            style={{
              marginTop: smallScreen ? theme.spacing(0) : -theme.spacing(7)
            }}
          >
            <Grid item md={3} xs={1} lg={3} sm={1} xl={3}></Grid>
            <Grid
              item
              md={6}
              xs={10}
              lg={6}
              sm={10}
              className={classes.title}
              style={{ marginTop: smallScreen ? theme.spacing(3) : 0 }}
            >
              {title}
            </Grid>
            <Grid item md={3} xs={1} lg={3} sm={1} xl={3}></Grid>
          </Grid>

          <br />
          <br />
          <Content></Content>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default AGCWorkFlow;
