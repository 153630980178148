import React, { Fragment, useState } from "react";
import clsx from "clsx";
import HeaderImage from "./sample-background.jpg";
import InstructionsImage from "./instructions.svg";
import MockTestImage from "./mock-test.svg";
import ActualTestImage from "./actual-test.svg";
import {
  makeStyles,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Snackbar,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ConfirmDialogBox from "../Exam/testConfirmationDialog";
import axios from "axios";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import HistoryIcon from "@material-ui/icons/History";
import PracticeExamHistory from "../PracticeExamHistory";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { SaveAltSharp } from "@material-ui/icons";
import { saveAs } from "file-saver";

//import { Alert } from 'react-alert'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(5, 5, 1, 5)
  },
  paperBackground: {
    padding: theme.spacing(3),
    backgroundImage: `url(${HeaderImage})`
  },
  card: {},
  media: {
    height: 300
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  }
}));

function StudentDashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const dialogContent = {
    content: (
      <Fragment>
        <span>You are about to begin the test.</span>
        <br />
        <span> We wish you All The Best! </span>
        <br />
        <span>
          Click <strong> 'Start'</strong> to begin.
        </span>
      </Fragment>
    )
  };
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;

  let [open, setOpen] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState("");

  const [state, setState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
    alertMessage: ""
  });
  const { vertical, horizontal, openAlert, message, severity } = state;
  const handleAlertClose = () => {
    setState({ ...state, openAlert: false });
  };

  const onInstructionsClick = () => {
    history.push("/student/portal/instructions", {
      examination: props.location.state.examination
    });
  };

  const onTestClick = batchId => {
    setOpen(!open);
    setSelectedValue(dialogContent.content);
  };

  const handleClose = (value, testTakenOnce) => {
    setOpen(!open);
    if (testTakenOnce) {
      setState({
        ...state,
        openAlert: true,
        message: "Test is started already!",
        severity: "error"
      });
    }
    setSelectedValue(value);
  };

  const viewHistory = () => {
    setOpenViewHistory(true);
  };

  function closeViewHistory() {
    setOpenViewHistory(false);
  }

  const [loaded, setLoaded] = useState(true);
  let theme = useTheme();
  const [mockTestFlag, setMockTestFlag] = useState(false);
  const [examFlag, setExamFlag] = useState(false);
  const [mockObject, setMockObject] = useState([]);
  const [testObject, setTestObject] = useState("");
  const [isExam, setIsExam] = useState();
  const [studentInfo] = React.useState(
    jwt_decode(localStorage.getItem("authorization"))
  );
  const [childName] = React.useState(studentInfo.dn);
  const [studentId] = React.useState(studentInfo.uid);
  const [examinationName, setExaminationName] = React.useState(
    props.location.state.examination
  );
  const [examName, setExamName] = React.useState("");
  const [noOfAttempts, setNoOfAttempts] = React.useState(
    studentInfo.noOfAttempts
  );
  const [mockTestHeading, setMockTestHeading] = React.useState(
    props.location.state.examination === "agc" &&
      studentInfo.examination === "agc"
      ? "Practice Exam"
      : "Practice Test"
  );
  const examStatus = props.location.state.examCompleted;
  const [mainTestHeading, setMainTestHeading] = React.useState(
    props.location.state.examination === "agc" &&
      studentInfo.examination === "agc"
      ? "Main Exam"
      : ""
  );

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [groupId, setGroupId] = React.useState(0);

  //need to load from backend
  const [viewHistoryAllowed, setViewHistoryAllowed] = React.useState(false);

  const [openViewHistory, setOpenViewHistory] = React.useState(false);
  useEffect(() => {
    if (loaded === true) {
      setLoaded(false);
      axios
        .get(BASE_URL + `/student/${studentId}/batches`, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization")
          }
        })
        .then(response => {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].exam.mockExam) {
              setMockTestFlag(true);
              setMockTestHeading(response.data[i].exam.name);
              setExamName(response.data[i].exam.name);
              // setNoOfAttempts(response.data[i].noOfAttempts)

              setMockObject({
                batchId: response.data[i].batch.batchId,
                studentBatchScheduleId: response.data[i].studentBatchScheduleId,
                examParticipationId: 0,
                exam: {
                  examId: response.data[i].exam.examId,
                  name: response.data[i].exam.name,
                  duration: response.data[i].exam.duration,
                  viewPracticeScores: response.data[i].exam.viewPracticeScores,
                  recordPracticeScores:
                    response.data[i].exam.recordPracticeScores,
                  allowMultipleTestAttempts:
                    response.data[i].exam.allowMultipleTestAttempts,
                  groupId: response.data[i].exam.groupId
                },
                studentId: studentId,
                studentName: childName,
                isMokeTest: response.data[i].exam.mockExam,
                examination: props.location.state.examination
              });
              setViewHistoryAllowed(response.data[i].exam.viewPracticeScores);
              setGroupId(response.data[i].exam.groupId);
            }

            if (!response.data[i].exam.mockExam) {
              setExamFlag(true);
              setExamName(response.data[i].exam.name);
              setMainTestHeading(response.data[i].exam.name);
              // setNoOfAttempts(response.data[i].noOfAttempts)
              setTestObject({
                batchId: response.data[i].batch.batchId,
                studentBatchScheduleId: response.data[i].studentBatchScheduleId,
                examParticipationId: 0,
                exam: {
                  examId: response.data[i].exam.examId,
                  name: response.data[i].exam.name,
                  duration: response.data[i].exam.duration,
                  viewPracticeScores: response.data[i].exam.viewPracticeScores,
                  recordPracticeScores:
                    response.data[i].exam.recordPracticeScores,
                  allowMultipleTestAttempts:
                    response.data[i].allowMultipleTestAttempts,
                  groupId: response.data[i].exam.groupId
                },
                studentId: studentId,
                studentName: childName,
                isMokeTest: response.data[i].exam.mockExam,
                examination: props.location.state.examination
              });
              setViewHistoryAllowed(response.data[i].exam.viewPracticeScores);
              setGroupId(response.data[i].exam.groupId);
            }
          }
        });
    }
  });

  useEffect(() => {
    axios
      .get(BASE_URL + `/students/${studentId}/noOfExams`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("authorization")
        }
      })
      .then(response => {
        setNoOfAttempts(response.data);
        // refreshPage();
      });
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleAlertClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container direction="row">
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{ marginTop: "5px" }}
          spacing={smallScreen ? 1 : 3}
        >
          <Grid item xs={0} md={7}></Grid>

          <Grid item container xs={12} md={5} justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              size={"small"}
              onClick={viewHistory}
              endIcon={<HistoryIcon />}
              style={{
                marginRight: "1%",
                display: examinationName === "prodigy" ? "block" : "none"
              }}
            >
              View Practice Exam Scores
            </Button>

            <PracticeExamHistory
              onClose={() => closeViewHistory()}
              studentId={studentId}
              groupId={groupId}
              studentName={childName}
              open={openViewHistory}
              examName={examName}
            ></PracticeExamHistory>

            <Button
              color="primary"
              variant="contained"
              size={"small"}
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
              endIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>

        <Grid item className={classes.paper} xs={12} md={12}>
          <Paper className={clsx(classes.paperBackground)}>
            <Typography variant="h4">
              {childName} -{" "}
              {examinationName === "agc" &&
                studentInfo.examination === "agc" &&
                "Welcome to SIP Online AGC 2024 "}
              {examinationName === "prodigy" &&
                studentInfo.examination === "prodigy" &&
                "Welcome to SIP  Prodigy 2024 "}
            </Typography>
          </Paper>
        </Grid>
        <Grid container spacing={10} className={classes.paper} justify="center">
          <Grid item xs={12} md={4}>
            <Card className={classes.card} elevation={6}>
              <CardActionArea
                disabled={false}
                style={{ opacity: !mockTestFlag ? "0.5" : "1" }}
                onClick={() => {
                  setIsExam(false);
                  onTestClick(mockObject);
                }}
              >
                <CardMedia
                  className={classes.media}
                  image={MockTestImage}
                  component="img"
                  title="Mock Test"
                />
                <CardContent
                  style={{ height: smallScreen ? "190px" : "114px" }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    {mockTestHeading}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {mockTestFlag
                      ? "Click here to take the practice exam ."
                      : " "}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card} elevation={6}>
              <CardActionArea
                disabled={!examFlag}
                style={{ opacity: !examFlag ? "0.5" : "1" }}
                onClick={() => {
                  axios
                    .get(BASE_URL + `/student/${studentId}/batches`, {
                      headers: {
                        authorization:
                          "Bearer " + localStorage.getItem("authorization")
                      }
                    })
                    .then(response => {
                      for (var i = 0; i < response.data.length; i++) {
                        if (
                          response.data.length === 1 &&
                          response.data[i].exam.mockExam
                        ) {
                          setState({
                            ...state,
                            openAlert: true,
                            message: "Test is started already!",
                            severity: "error"
                          });
                          setIsExam(false);
                        } else {
                          setIsExam(true);
                          onTestClick(testObject);
                        }
                      }
                    });
                }}
              >
                <CardMedia
                  className={classes.media}
                  image={examinationName === "agc" ? ActualTestImage : ""}
                  component="img"
                  title=""
                />
                <CardContent
                  style={{ height: smallScreen ? "190px" : "114px" }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    {examFlag ? "Start Test" : mainTestHeading}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {examFlag
                      ? "Are you ready for the test? Click here to begin!"
                      : ""}
                  </Typography>
                </CardContent>
                <ConfirmDialogBox
                  selectedValue={selectedValue}
                  examinationType={props.location.state.examination}
                  examObject={isExam ? testObject : mockObject}
                  open={open}
                  onClose={handleClose}
                  style={{ textAlign: "center" }}
                  md={4}
                  sm={12}
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default StudentDashboard;
