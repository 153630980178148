import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Snackbar, CircularProgress, withStyles, TableCell } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import axios from 'axios';
import { Alert } from '@material-ui/lab';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#FFA500",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 'auto',
        stickyHeader:'true'
    },
    dialogBoxHeight: {
        '& .MuiDialog-paperFullWidth': {
            height: 'auto',
            width:'auto',
            overFlowY:'hidden'
        }
    },
    tableBody:{
       '& .MuiTableBody-root': {
            display: 'tableRowGroup',
            height:'auto',
            minWidth:'auto',
            textAlign:'center',
            maxHeight:440
        }
    },
});



export default function PracticeExamHistory(props) {

    const { onClose, open, studentId,studentName,groupId , examName } = props;
    const classes = useStyles();
    const handleClose = () => {
        setResultList([{'createdDt':'Loading......'}])
        setDataLoading(true)
        onClose(false);
    }
    const [dataLoading, setDataLoading] = React.useState(true);
    const [resultList,setResultList] = React.useState([{'createdDt':'Loading......'}]);
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

        const handleEntry=()=>{
            setResultList([{'createdDt':'Loading......'}])
             getResults();
        }

        const getResults =()=>{
            setDataLoading(true);
            axios.get(BASE_URL + "/student/"+ studentId +"/results", {
                params:{
                    groupId:examName =='agc'? 1 : 4
                },
                headers: {
                    authorization: "Bearer " + localStorage.getItem("authorization"),
                  },
            })
                .then(function (response) {
                    if (response && response.data && response.data.length>0) {
                        setResultList(response.data);
                        setDataLoading(false)
                    }else{
                        setDataLoading(false);
                        setResultList([]);
                    }  
                })
                .catch(function (error) {
                    setState({ ...state, openAlert: true, message: 'Failed to Load The Results', severity: "error" });
                }); 
        }

    const [maxWidth] = React.useState('lg');
    const heading = examName; 
    

    const columns=[
        {id:'S.No',minWidth:7,align:'center',fontSize:'14px',width:7},
        {id:'Test Attended Date',minWidth:20,align:'center',fontSize:'14px',width:10},
        {id:'Total Marks',minWidth:20,align:'center',fontSize:'14px',width:80},
        // {id:'Module Name',minWidth:80,align:'center',fontSize:'14px',width:80},
        {id:'Total Questions',minWidth:20,align:'center',fontSize:'14px',width:20},
        {id:'Attended Questions',minWidth:20,align:'center',fontSize:'14px',width:20},
        {id:'Correct Answered Questions',minWidth:20,align:'center',fontSize:'14px',width:20}
    ]



    return (

        <Dialog fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} open={open}
            onClose={handleClose} aria-labelledby="form-dialog-title"  onEntering={handleEntry}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleAlertClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleAlertClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <DialogTitle id="form-dialog-title">{heading} - {studentName} <span style={{fontSize:"75%"}}>{" (Last test result will be updated after 5 minutes)"}</span>
            { dataLoading && <CircularProgress size={24} />} 

            </DialogTitle>
            <DialogContent style={{ padding: '0 3%', width:"auto" }}  >
                <TableContainer component={Paper}>
                    <Table className={classes.table} size='small' style={{border:'2px solid orange'}} stickyHeader='true' aria-label="sticky table">
                        <TableHead>
                        <TableRow width='2px'>
                            {columns.map((column,index)=>(
                                <StyledTableCell  key={column.id} align={column.align} style={{minWidth:column.minWidth, fontSize:column.fontSize}} width={column.width}>
                                   {column.id}
                                </StyledTableCell>
                            ))}

                            
                        </TableRow>
                        </TableHead>
                        {resultList.length>0 ?
                        <TableBody>
                        {resultList.map((row,index) => (
                            <StyledTableRow key={index}>
                            <StyledTableCell component="th" >{++index}</StyledTableCell>
                            <StyledTableCell align="center" >{row.testAttendedDate}</StyledTableCell>
                            <StyledTableCell align="center" >{row.marksScored}</StyledTableCell>

                           
                           {/* //module name */}
                           {/* <StyledTableCell align="center">
                           { row.practiceHistoryTO && row.practiceHistoryTO.map((row0)=>(
                               <TableRow align='center' style={{color:'inherit'}} key={row0.moduleName}>
                               <StyledTableCell style={{color:'inherit'}} align="center"> 
                                          {row0.moduleName}
                               </StyledTableCell>
                               </TableRow>
                           ))}</StyledTableCell> */}
                            
                            {/* total attended  */}
                            <StyledTableCell align="center">
                            {row.practiceHistoryTO && row.practiceHistoryTO.map((row1,index)=>(
                                <TableRow align="center" style={{color:'inherit'}} key={row1.totalQuestions}>
                                    <StyledTableCell align="center">{row1.totalQuestions}</StyledTableCell>
                                </TableRow>
                            ))}
                            </StyledTableCell>

                            <StyledTableCell align="center" >
                            {row.practiceHistoryTO && row.practiceHistoryTO.map((row2,index) =>(
                                <TableRow align="center" style={{color:'inherit'}} key={row2.attendedQuestions}>
                            <StyledTableCell align="center">{row2.attendedQuestions}</StyledTableCell>
                            </TableRow>
                            ))}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                            {row.practiceHistoryTO && row.practiceHistoryTO.map((row3,index) =>(
                                <TableRow align="center" style={{color:'inherit'}} key={row3.correctAnsweredQuestions}>
                            <StyledTableCell align="center">{row3.correctAnsweredQuestions}</StyledTableCell>
                            </TableRow>
                            ))}
                            </StyledTableCell>

                            </StyledTableRow>
                        ))}
                        </TableBody> :
                        <TableBody>
                            <StyledTableCell> </StyledTableCell>
                            <StyledTableCell align="right">No Records </StyledTableCell>
                            <StyledTableCell align="left">Available</StyledTableCell>
                            <StyledTableCell> </StyledTableCell>
                            <StyledTableCell> </StyledTableCell>

                        </TableBody>
                      }
                    </Table>
                    </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                    endIcon={<CancelOutlinedIcon />}>Close</Button>
            </DialogActions>

        </Dialog>
    );
}