import { Button, CircularProgress, FormControl, FormHelperText, Grid, makeStyles, Paper, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _ from "lodash/fp";
import axios from 'axios';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ResetOutlinedIcon from '@material-ui/icons/LoopOutlined';
import IconButton from '@material-ui/core/IconButton';
import MUIDataTable from 'mui-datatables';
import saveAs from 'file-saver';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Icon } from '@iconify/react';
import fileExcel from '@iconify-icons/mdi/file-excel';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paper_padding: {
        padding: theme.spacing(2)
    },
    error: {
        color: "red",
    },
}));

function Report(props) {
    const classes = useStyles();
    const { register, handleSubmit, errors, reset, control } = useForm({
        mode: "all",
    });
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [serverDateString, setServerDateString] = useState('');
    const areaHead = 'Area Head';
    const stateHead = 'State Head';
    const regionalManager = "Regional Manager";
    const hO = "HO";
    const reportTypes = {
        reportType1:'Student Report',
        reportType2:'Student Report - Registration Incomplete',
        reportType3: 'LCL wise- Effectiveness tracker',
        reportType4: 'Delay tracker - Leads and scheduled R1',
        reportType5: 'Delay tracker - Workshop',
        reportType6: 'Source analysis',
        reportType7: 'School Tracker Report'
    };
    const roleName = JSON.parse(localStorage.getItem("sip-user")).roles[0].name;
    const roleAndDesignationLevel= {
        roles: {
            roleNameForRM: 'REGIONAL MANAGER',
            roleNameForSH: 'STATE HEAD',
            roleNameForAH: 'AREA HEAD',
            roleNameForHO: 'ADMIN',
            roleNameForLCL:'LCL ADMIN'
        },
        designationLevel: {
            designationLevelForLCL: 5,
            designationLevelForHO: 4,
            designationLevelForRM: 3,
            designationLevelForSH: 2,
            designationLevelForAH: 1
        }
    }
    const reportTypeListAGC = [
        { id: 1, name: `${reportTypes.reportType1}`, designationLevel: 10 },
        { id: 2, name: `${areaHead} - ${reportTypes.reportType3}`, designationLevel: 1 },
        { id: 3, name: `${areaHead} - ${reportTypes.reportType4}`, designationLevel: 1 },
        { id: 4, name: `${areaHead} - ${reportTypes.reportType5}`, designationLevel: 1 },
        { id: 5, name: `${areaHead} - ${reportTypes.reportType6}`, designationLevel: 1 },
        { id: 22, name: `${areaHead} - ${reportTypes.reportType7}`, designationLevel: 1 },
        
        { id: 7, name: `${stateHead} - ${reportTypes.reportType3}`, designationLevel: 2 },
        { id: 8, name: `${stateHead} - ${reportTypes.reportType4}`, designationLevel: 2 },
        { id: 9, name: `${stateHead} - ${reportTypes.reportType5}`, designationLevel: 2 },
        { id: 10, name: `${stateHead} - ${reportTypes.reportType6}`, designationLevel: 2 },
        { id: 23, name: `${stateHead} - ${reportTypes.reportType7}`, designationLevel: 2 },
       
        { id: 12, name: `${regionalManager} - ${reportTypes.reportType3}`, designationLevel: 3 },
        { id: 13, name: `${regionalManager} - ${reportTypes.reportType4}`, designationLevel: 3 },
        { id: 14, name: `${regionalManager} - ${reportTypes.reportType5}`, designationLevel: 3 },
        { id: 15, name: `${regionalManager} - ${reportTypes.reportType6}`, designationLevel: 3 },
        { id: 24, name: `${regionalManager} - ${reportTypes.reportType7}`, designationLevel: 3 },
        
        { id: 17, name: `${reportTypes.reportType2}`, designationLevel: 4 },
        { id: 18, name: `${hO} - ${reportTypes.reportType3}`, designationLevel: 4 },
        { id: 19, name: `${hO} - ${reportTypes.reportType4}`, designationLevel: 4 },
        { id: 20, name: `${hO} - ${reportTypes.reportType5}`, designationLevel: 4 },
        { id: 21, name: `${hO} - ${reportTypes.reportType6}`, designationLevel: 4 },
        { id: 25, name: `${hO} - ${reportTypes.reportType7}`, designationLevel: 4 },
        
    ];

    const reportTypeForProdigy =[
        {id:0, name:'Student Report - Practice Exam'}
    ]

    const [reportTypeListAGCBasedOnRole, setReportTypeListBasedOnRole] = useState([]);
    const [reportTypeProdigy,setReportTypeProdigy]= React.useState(reportTypeForProdigy);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [stateID, setStateID] = useState('');
    const [cityID, setCityID] = useState('');
    const [stateValue, setStateValue] = React.useState('');
    const [cityValue, setCityValue] = React.useState('');
    const [branchValue, setBranchValue] = React.useState('');
    const [reportType, setReportType] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [isSubmitButton, disableSubmitButton] = useState(false);
    const [LCLLogin, setLCLLogin] = useState(false);

    const [serverDate] = useState(() => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        let currentDateAndTimeFromServer;
        let currentDateMinus1;
        axios.get(BASE_URL + "/SIPCustomApi/serverCurrentDateAndTime",
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                currentDateAndTimeFromServer = new Date(response.data);
                currentDateMinus1 = currentDateAndTimeFromServer;
                currentDateMinus1.setDate(currentDateAndTimeFromServer.getDate() - 1);
                setServerDateString(response.data);
                //setFromDate(currentDateMinus1);
            })
            .catch(function (error) {
            });
        return currentDateAndTimeFromServer;
    });
    const [toDate, setToDate] = useState(serverDate);
    
    const onSubmit = data => {
        data["reportType"] = data.reportType.name;
        data["userId"] = userId;
        data["state"] = data.state === null ? { id: 0, stateName: "" } : data.state;
        data["city"] = data.city === null ? { cityName: "", cityId: 0 } : data.city;
        data["branch"] = data.branch === null ? { branchId: 0, name: '' } : data.branch;
        data["user"] = { userId: userId };

        if(data.reportType === 'Student Report - Registration Incomplete'){
            data["registrationCompleted"]=false;
        }else{
            data["registrationCompleted"]=true;

        }
        //need to add as filter or need to read from props
        data["examinationId"] = props.examinationId;
        if (props.examinationId === 1) {
          data["examGroupId"] = 2;
        } else if (props.examinationId === 2) {
          data["examGroupId"] = 4;
        }
        axios.post(BASE_URL + "/branches/report", data,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                if (response.data.sucess) {
                    setState({ ...state, openAlert: true, message: response.data.messageKey, severity: "success" });
                    setIsLoading(!isLoading);
                    resetReportPage();

                } else {
                    setState({ ...state, openAlert: true, message: 'Request failed!...', severity: "error" });
                    resetReportPage();

                }
            })
            .catch((error) => {
                setState({ ...state, openAlert: true, message: 'Request failed!...', severity: "error" });
                resetReportPage();

            });
    }

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [recordsPerPage, setRecordPerPage] = useState(10);
    const [page, setPageNo] = useState(0);
    const [sortByCol, setSortByCol] = useState('requestDt');
    const [sortDir, setSortDir] = useState('desc');
    const [count, setCount] = useState(0);
    const userId = JSON.parse(localStorage.getItem("sip-user")).userId;
    const [requestBody, setRequestBody] = useState({
        usrId: JSON.parse(localStorage.getItem("sip-user")).userId,
        programName: '',
        examinationId:props.examinationId
    });
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };
    const fieldLabelsAndName = {
        labelsAndHelperText: {
            reportType: 'Report Type',
            state: 'State',
            city: 'City',
            branch: 'Branch',
            fromDate: 'From Date',
            toDate: 'To Date',
            helperTextForSelection: `Please select the`,
            helperTextForEntering: `Please enter the`,
            resetButtonLabel: `Reset`,
            submitButtonLabel: `Submit`
        },
        name: {
            reportType: 'reportType',
            state: 'state',
            city: 'city',
            branch: 'branch',
            fromDate: 'fromDate',
            toDate: 'toDate'
        }

    }

    const columns = [

        {
            name: "requestNo",
            label: "Request No",
            options: {
                filter: false,
                display: true
            }
        }, {
            name: "programName",
            label: "Report Name",
            options: {
                filter: false,
                display: true
            }
        },
        {
            name: "requestStatusForDisplay",
            label: "Request Status",
            options: {
                filter: false,
                display: true,
            }
        },
        {
            name: "requestedBy",
            label: "Requested By",
            options: {
                filter: false,
                display: true
            }
        },
        {
            name: "requestDt",
            label: "Requested Date",
            options: {
                filter: false,
                display: true
            }
        },
        {
            name: "requestEndDt",
            label: "Requested End Date",
            options: {
                filter: false,
                display: false
            }
        },
        {
            name: "errorLog",
            label: "Error Message",
            options: {
                filter: false,
                display: false
            }
        },
        {
            name: "action",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Grid container justify="flex-start">
                            <Tooltip title="Download" aria-label="download"
                                style={{ display: tableData[dataIndex].requestStatus === 1 ? 'block' : 'none' }}>
                                <IconButton elevation={4} color="secondary" onClick={() => {
                                    showDownloadPopUp(tableData[dataIndex].serverFilePath);
                                }}>
                                    <Icon icon={fileExcel} color="#2979ff" />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                    );
                }
            }
        }
    ]

    // const { count,  rowsPerPage, sortOrder } = state;

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: true,
        count: count,
        rowsPerPage: recordsPerPage,
        rowsPerPageOptions: [10, 20, 50, 100, 200, 500],
        sortOrder: {
            name: sortByCol,
            direction: sortDir
        },
        selectableRows: "none",
        rowHover: true,
        caseSensitive: true,
        enableNestedDataAccess: '.',
        print: false,
        download: false,
        customToolbar: () => {
            return (
                <Tooltip title={"Refresh"}>
                    <IconButton onClick={(() => {
                        setIsLoading(!isLoading);
                    })}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            );
        },
        searchOpen: false, searchPlaceholder: 'Search by Report Name...',
        onTableChange: (action, tableState) => {


            switch (action) {
                case 'changePage':
                    changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    break;
                case 'sort':
                    sort(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    break;
                case 'changeRowsPerPage':
                    changeRecordPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    break;
                case 'search':
                    handleSearch(tableState.searchText, tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    break;
                default:

            }
        }
    }

    const loadStateData = () => {

        setStateList([{ stateName: 'Loading...' }]);
        axios.get(BASE_URL + "/states",
            {
                params: {
                    // this active flag is set to false because to load only the states used by SIP
                    body: JSON.stringify({ stateName: "",active:false }),
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {

                setStateList(response.data.content);

            });

    }

    const loadBranchData = () => {

        setBranchList([{ name: 'Loading...' }]);
        axios.get(BASE_URL + "/branches",
            {
                params: {
                    body: JSON.stringify({
                        name: "",
                        branchId: 0,
                        address: {
                            countryId: 1,
                            stateId: stateID,
                            cityId: cityID,
                        }
                    }),
                },

                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }

            }).then((response) => {

                setBranchList(response.data.content);

            });

    }

    const loadCityData = () => {

        setCityList([{ cityName: 'Loading...' }]);
        axios.get(BASE_URL + '/cities',
            {
                params: {
                    body: JSON.stringify({
                        cityName: "",
                        countryCombo: {
                            countryId: 1,
                        },
                        stateCombo: {
                            stateId: stateID,
                        }
                    }),
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setCityList(response.data.content);

            });

    }
    const cityData = cityList.map((city) => {
        return { cityName: city.cityName, cityId: city.cityId }
    })

    const stateData = stateList.map((state) => {
        return { stateName: state.stateName, stateId: state.stateId }
    })

    const branchData = branchList.map((branch) => {
        return { name: branch.name, branchId: branch.branchId }
    })

    const loadReportTypeBasedOnRole = () => {
        if(props.examinationId === 1){
        let filteredReportTypeList = [];
        reportTypeListAGC.map((report) => {
            if(report.id ===1){
                filteredReportTypeList.push({name:report.name,id:report.id})
            }
            if (roleName === roleAndDesignationLevel.roles.roleNameForRM && 
                report.designationLevel <= roleAndDesignationLevel.designationLevel.designationLevelForRM) {
                filteredReportTypeList.push({ name: report.name, id: report.id, 
                    designationLevel: roleAndDesignationLevel.designationLevel.designationLevelForRM });
            } else if (roleName === roleAndDesignationLevel.roles.roleNameForSH && 
                report.designationLevel <= roleAndDesignationLevel.designationLevel.designationLevelForSH) {
                filteredReportTypeList.push({ name: report.name, id: report.id, 
                    designationLevel: roleAndDesignationLevel.designationLevel.designationLevelForSH });
            } else if (roleName === roleAndDesignationLevel.roles.roleNameForAH && 
                report.designationLevel <= roleAndDesignationLevel.designationLevel.designationLevelForAH) {
                filteredReportTypeList.push({ name: report.name, id: report.id, 
                    designationLevel: roleAndDesignationLevel.designationLevel.designationLevelForAH });
            } else if (roleName === roleAndDesignationLevel.roles.roleNameForHO && 
                report.designationLevel === roleAndDesignationLevel.designationLevel.designationLevelForHO) {
                filteredReportTypeList.push({ name: report.name, id: report.id, 
                    designationLevel: roleAndDesignationLevel.designationLevel.designationLevelForHO });
            }
        });
        setReportTypeListBasedOnRole(filteredReportTypeList);
    }else if(props.examinationId ===2){
        setReportTypeListBasedOnRole(reportTypeProdigy)
    }
    }

    useEffect(() => {
        if (fromDate > toDate) {
            disableSubmitButton(true);
        } else {
            disableSubmitButton(false);
        }
    }, [toDate, fromDate, reportType]);

    useEffect(() => {
        axios.get(BASE_URL + "/jobList",
            {
                params: {
                    body: JSON.stringify(requestBody),
                    pageNo: page !== undefined ? page : "",
                    sortKey: sortByCol !== undefined ? sortByCol : "",
                    sortDir: sortDir !== undefined ? sortDir : "",
                    recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
                    userId: userId,
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setTableData(response.data.content);
                setIsLoading(false)
                setCount(response.data.totalElements);
            });
    }, [recordsPerPage, page, sortByCol, sortDir, requestBody, isLoading]);

    const changePage = (page, sortOrder, rowsPerPage) => {
        // setIsLoading(true);
        setRecordPerPage(rowsPerPage);
        setPageNo(page);
        setSortByCol(sortOrder.name);
        setSortDir(sortOrder.direction);

    }

    const changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
        setRecordPerPage(rowsPerPage);
        setPageNo(page);
    }

    const sort = (page, sortOrder, rowsPerPage) => {
        let sortKey = sortOrder.name;
        setRecordPerPage(rowsPerPage);
        setSortByCol(sortOrder.name);
        setPageNo(page);
        setSortDir(sortOrder.direction)
    }

    const handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
        setRequestBody({
            usrId: JSON.parse(localStorage.getItem("sip-user")).userId,
            programName: (searchText != null ? searchText : "")
        });
        setRecordPerPage(rowsPerPage);
        setPageNo(page);
        setSortByCol(sortOrder.name);
        setSortDir(sortOrder.direction);
    }

    const showDownloadPopUp = (filePath) => {
        const fileName = filePath.split('/')[filePath.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePath}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setState({ ...state, openAlert: true, message: 'File downloaded successfully', severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setState({ ...state, openAlert: true, message: 'Failed to download the file!', severity: "error" });
            })

    }

    useEffect(() => {
        if(roleName === roleAndDesignationLevel.roles.roleNameForLCL){
            setLCLLogin(true)
        }
    }, [roleName])


    const resetReportPage = () => {
        let currentDateAndTimeFromServer = new Date(serverDateString);
        let formattedFromDate = `${currentDateAndTimeFromServer.getDate()}-${currentDateAndTimeFromServer.getMonth()}-${currentDateAndTimeFromServer.getYear()}`;
        let currentDateMinus1 = currentDateAndTimeFromServer;
        currentDateMinus1.setDate(currentDateAndTimeFromServer.getDate() - 1);
        // let formattedToDate = `${currentDateMinus1.getDate()}-${currentDateMinus1.getMonth()}-${currentDateMinus1.getYear()}`;
        setToDate(new Date(serverDateString));
        setFromDate(null);
        reset({
            reportType: null,
            fromDate: null,
            toDate: formattedFromDate,
            state: null,
            city: null,
            branch:null,
        });
        setReportType('');
        setStateValue([]);
        setCityValue([]);
        setBranchValue([]);
        setStateID(0);
        setCityID(0);
        setFromDate(null);
    }

    return (
        <div>
            <Paper className={classes.paper}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openAlert}
                    onClose={handleAlertClose}
                    key={vertical + horizontal}
                    autoHideDuration={6000}
                >
                    <Alert onClose={handleAlertClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.paper_padding}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={props => (
                                        <Autocomplete
                                            
                                            onChange={(event, newValue) => {
                                                props.onChange(newValue)

                                                if (typeof newValue === 'string') {

                                                    if (newValue != null) {
                                                        setReportType({
                                                            newValue,
                                                        });
                                                    }
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setReportType({
                                                        name: newValue.inputValue,
                                                    });

                                                }
                                                else if (newValue != null) {

                                                    setReportType(newValue);
                                                } else {
                                                    setReportType(newValue);
                                                }
                                            }}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            value={reportType !== null ? reportType : null}
                                            onOpen={loadReportTypeBasedOnRole}
                                            options={reportTypeListAGCBasedOnRole}
                                            autoHighlight
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }

                                                return option.name;
                                            }}
                                            renderOption={(option) => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    autoFocus

                                                    className={(errors.reportType && classes.invalid) || classes.formControl}
                                                    name={fieldLabelsAndName.name.reportType}
                                                    size='small'
                                                    label={fieldLabelsAndName.labelsAndHelperText.reportType + " *"}
                                                    variant="outlined"
                                                />
                                            )}

                                        />
                                    )}

                                    rules={{ required: true }}
                                    name={fieldLabelsAndName.name.reportType}
                                    control={control}
                                    defaultValue={null}
                                />
                                {_.get(`${fieldLabelsAndName.name.reportType}.type`, errors) === "required" && reportType === null && (
                                    <FormHelperText className={classes.error}>{fieldLabelsAndName.labelsAndHelperText.helperTextForSelection} {fieldLabelsAndName.labelsAndHelperText.reportType}</FormHelperText>
                                )}
                                {reportType === '' && (
                                    <FormHelperText className={classes.error}>{fieldLabelsAndName.labelsAndHelperText.helperTextForSelection} {fieldLabelsAndName.labelsAndHelperText.reportType}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

{roleName !== roleAndDesignationLevel.roles.roleNameForLCL ?
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={props => (
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                props.onChange(newValue)

                                                if (typeof newValue === 'string') {
                                                    setStateValue({
                                                        newValue,
                                                    });

                                                    if (newValue != null) {
                                                        setStateID(newValue.stateId)
                                                    }
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setStateValue({
                                                        title: newValue.inputValue,
                                                    });
                                                    if (newValue != null) {
                                                        setStateID(newValue.stateId)
                                                    }

                                                }
                                                else if (newValue != null) {
                                                    setStateValue(newValue)
                                                    if (newValue) {
                                                        setStateID(newValue.stateId)
                                                    }
                                                } else if (newValue === null) {
                                                    setStateValue('')
                                                    setStateID('')
                                                    setCityID('')
                                                } else {
                                                    setStateValue(newValue)
                                                    setStateID(1)
                                                }
                                                setCityList([]);
                                                setBranchList([]);

                                                setBranchValue([]);
                                                setCityValue([]);
                                            }}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            value={stateValue.stateName || null}
                                            autoHighlight
                                            onOpen={loadStateData}
                                            options={stateData}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }

                                                return option.stateName;
                                            }}
                                            getOptionSelected={(option, value) => option.stateName === value.stateName}
                                            renderOption={(option) => option.stateName}


                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    name={fieldLabelsAndName.name.state}
                                                    size='small'
                                                    label={fieldLabelsAndName.labelsAndHelperText.state}
                                                    variant="outlined"
                                                />
                                            )}

                                        />
                                    )}

                                    rules={{ required: false }}
                                    name={fieldLabelsAndName.name.state}
                                    control={control}
                                    defaultValue={{ id: 0, stateName: '' }}
                                />

                            </FormControl>
                        </Grid>:null}


                        {roleName !== roleAndDesignationLevel.roles.roleNameForLCL ?
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={props => (
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                props.onChange(newValue)

                                                if (typeof newValue === 'string') {

                                                    if (newValue != null) {
                                                        setCityValue()({
                                                            newValue,
                                                        });
                                                        setCityID(newValue.cityId)
                                                    }
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setCityValue({
                                                        title: newValue.inputValue,
                                                    });
                                                    if (newValue != null) {
                                                        setCityID(newValue.cityId)
                                                    }

                                                }
                                                else if (newValue != null) {
                                                    setCityValue(newValue);
                                                    if (newValue) {
                                                        setCityID(newValue.cityId)
                                                    }
                                                } else if (newValue === null) {
                                                    setCityValue('')
                                                    setCityID('')
                                                } else {
                                                    setCityValue(newValue);
                                                    setCityID(1)
                                                }
                                                setBranchValue([]);
                                            }}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            value={cityValue.cityName || null}
                                            onOpen={loadCityData}
                                            options={cityData}
                                            autoHighlight
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }

                                                return option.cityName;
                                            }}
                                            renderOption={(option) => option.cityName}
                                            getOptionSelected={(option, value) => option.cityName === value.cityName}

                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    className={(errors.reportType && classes.invalid) || classes.formControl}
                                                    name={fieldLabelsAndName.name.city}
                                                    size='small'
                                                    label={fieldLabelsAndName.labelsAndHelperText.city}
                                                    variant="outlined"
                                                />
                                            )}

                                        />
                                    )}

                                    rules={{ required: false }}
                                    name={fieldLabelsAndName.name.city}
                                    control={control}
                                    defaultValue={{ cityName: '', cityId: 0 }}
                                />
                            </FormControl>
                        </Grid>:null}

                        {roleName !== roleAndDesignationLevel.roles.roleNameForLCL ?
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={props => (
                                        <Autocomplete
                                            
                                            onChange={(event, newValue) => {
                                                props.onChange(newValue)

                                                if (typeof newValue === 'string') {

                                                    if (newValue != null) {
                                                        setBranchValue({
                                                            newValue,
                                                        });
                                                    }
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setBranchValue({
                                                        title: newValue.inputValue,
                                                    });

                                                }
                                                else if (newValue !== null) {

                                                    setBranchValue(newValue);
                                                } else if (newValue === null) {
                                                    setBranchValue('')
                                                } else {
                                                    setBranchValue(newValue);
                                                }
                                            }}
                                            selectOnFocus
                                            handleHomeEndKeys
                                            value={branchValue.name || null}
                                            autoHighlight
                                            onOpen={loadBranchData}
                                            options={branchData}
                                            getOptionLabel={(option) => {

                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                return option.name;
                                            }}
                                            getOptionSelected={(option, value) => option.name === value.name}

                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    name={fieldLabelsAndName.name.branch}
                                                    
                                                    size='small'
                                                    label={fieldLabelsAndName.labelsAndHelperText.branch}
                                                    variant="outlined"
                                                />
                                            )}

                                        />
                                    )}

                                    rules={{ required: false }}
                                    name={fieldLabelsAndName.name.branch}
                                    control={control}
                                    defaultValue={{ branchId: 0, name: '' }}
                                />
                            </FormControl>
                        </Grid>:null}

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            size="small"
                                            id="start-date"
                                            label={fieldLabelsAndName.labelsAndHelperText.fromDate}
                                            format="dd-MM-yyyy"
                                            inputVariant="outlined"
                                            inputRef={register({
                                                required: false,
                                            })}
                                            value={fromDate}
                                            maxDate={new Date(serverDateString)}
                                            invalidDateMessage={`Please select valid date.`}
                                            minDateMessage={`Date cannot be less than today.`}
                                            onChange={((date) => {
                                                if (date === null || date === undefined) {
                                                    setFromDate();
                                                } else {
                                                    setFromDate(date);
                                                }
                                            })}
                                            InputLabelProps={{ shrink: true }}
                                            name={fieldLabelsAndName.name.fromDate}
                                        />
                                    </FormControl>
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            size="small"
                                            id="to-date"
                                            label={fieldLabelsAndName.labelsAndHelperText.toDate}
                                            format="dd-MM-yyyy"
                                            inputVariant="outlined"
                                            inputRef={register({
                                                required: false,
                                            })}
                                            value={toDate}
                                            minDate={fromDate}
                                            maxDate={new Date(serverDateString)}
                                            invalidDateMessage={`Please select valid date.`}
                                            minDateMessage={`${fieldLabelsAndName.labelsAndHelperText.toDate} is less than ${fieldLabelsAndName.labelsAndHelperText.fromDate}.`}
                                            onChange={((date) => {
                                                if (date === null || date === undefined) {
                                                    setToDate();
                                                } else {
                                                    setToDate(date);
                                                }
                                            })}
                                            InputLabelProps={{ shrink: true }}
                                            name={fieldLabelsAndName.name.toDate}
                                        />
                                    </FormControl>
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid container justify="flex-end" alignItems="flex-end" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container justify="flex-end" alignItems="flex-end" item xs={6} sm={6} md={1} lg={1}>
                                <Button variant="outlined" size={"small"} spacing={2} color="secondary"
                                    endIcon={<ResetOutlinedIcon />}
                                    onClick={resetReportPage}
                                >{fieldLabelsAndName.labelsAndHelperText.resetButtonLabel}</Button>
                            </Grid>
                            <Grid container justify="flex-end" alignItems="flex-end" item xs={6} sm={6} md={1} lg={1}>
                                <Button variant="outlined" size={"small"} color="primary" disabled={isSubmitButton}
                                    onClick={handleSubmit(onSubmit)}
                                    endIcon={<SaveOutlinedIcon />}>{fieldLabelsAndName.labelsAndHelperText.submitButtonLabel}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MUIDataTable  
                        title={<Typography variant="h6">
                            Download Reports Here
                        {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        </Typography>
                        }
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </div >
    );
}

export default Report;