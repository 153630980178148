import React, { useEffect } from 'react'
import clsx from 'clsx'

import { Link, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'

import IconArrowDropDown from '@material-ui/icons/ArrowDropDown'
import IconProfile from '@material-ui/icons/AccountBox'
import IconLogout from '@material-ui/icons/ExitToApp'
import IconVpnKey from '@material-ui/icons/VpnKey';


const AppHeaderProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState({displayName:""});
  
  useEffect(()=>{
      const sipUser = JSON.parse(localStorage.getItem('sip-user'));
      setUser({
          displayName:sipUser.userName,
        })
  },[])

  if (!user) {
    return <div className={clsx('headerProfile', classes.headerProfile)} />
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    localStorage.removeItem('sip-authorization');
    localStorage.removeItem('sip-user');
    history.push('/admin/login');
  }

  return (
    <div className={clsx('headerProfile', classes.headerProfile)}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="Search"
        className={classes.profileButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          className={classes.profileAvatar}
          alt={user.displayName}
          src="http://tinygraphs.com/labs/isogrids/hexa/tinygraphs?theme=daisygarden&numcolors=4&size=220&fmt=svg"
        />
        <span className={classes.profileName}>{user.displayName}</span>
        <IconArrowDropDown />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.profileMenu,
        }}
      >
        <MenuItem onClick={handleClose} component={Link} to="/user/profile" disabled={true}>
          <ListItemIcon className={classes.profileMenuItemIcon}>
            <IconProfile />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="user/changePassword" disabled={true}>
          <ListItemIcon className={classes.profileMenuItemIcon}>
            <IconVpnKey />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.profileMenuItemIcon}>
            <IconLogout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  headerProfile: {
    display: 'inline-flex',
    paddingLeft: theme.spacing(2),
  },
  profileButton: {
    borderRadius: 30,
    fontSize: '1.2rem',
    padding: 8,
  },
  profileAvatar: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
  profileName: {
    fontWeight: 500,
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  profileMenu: {
    marginLeft: '-16px',
  },
  profileMenuItemIcon: {
    color: theme.palette.primary.main,
  },
}))

export default AppHeaderProfile